import React from 'react';
import sliderImage from '../../../assets/disclaimer/disclaimer-slide-image.svg';
import mWorksLogo from '../../../assets/cardSymbols/mworks.svg';

export const Slide1 = () => {
  return (
    <div className="disclaimer-carousel-card">
      <h1>Välkommen till mWorks - Ditt stöd tillbaka till arbete.</h1>
      <p className="padding-top">Här kommer en kort introduktion.</p>
    </div>
  );
};

export const Slide2 = () => {
  return (
    <div className="disclaimer-carousel-card">
      <p className="padding-top">
        mWorks är en process som du kan gå igenom i din egen takt. mWorks ringar
        in vad du vill och kan, hur du mår och vad som kan hjälpa dig tillbaka
        till arbete.
      </p>
    </div>
  );
};

export const Slide3 = () => {
  return (
    <div className="disclaimer-carousel-card">
      <p>
        Forskning visar att mWorks process ger dig ett ett bra stöd både på väg
        tillbaka till arbete och när du väl börjat jobba igen.
      </p>
      <p className="font-weight-bold">
        <br />
        OBS!
        <br />
        Om du mår akut dåligt behöver du söka hjälp. Kontakta din vårdgivare
        eller ring 112.
      </p>
    </div>
  );
};

export const Slide4 = () => {
  return (
    <div className="disclaimer-carousel-card">
      <p>
        Mitt mWorks är själva startpunkten för mWorks. Här får du berätta om vad
        du har gått igenom och fundera på vad som är viktigt för dig.
      </p>
      <div className="disclaimer-mworks-logo">
        <p className="title">Mitt mWorks</p>
        <img src={mWorksLogo} className="logo" alt="" />
      </div>
    </div>
  );
};

export const Slide5 = () => {
  return (
    <div className="disclaimer-carousel-card">
      <p>
        De <i>5 stegen</i> tar dig genom mWorks process. Stegen bygger på
        varandra och bildar en helhet. Därför rekommenderar vi att du tar stegen
        i tur och ordning.
      </p>
      <br />
      <img src={sliderImage} alt="" />
    </div>
  );
};

export const Slide6 = () => {
  return (
    <div className="disclaimer-carousel-card">
      <p>
        När du använder mWorks kommer du att få fundera på din situation och
        skriva ner dina tankar och känslor. Du bestämmer själv om du vill visa
        det för någon annan. Med tiden kommer mWork fyllas på och du kommer ta
        bort och lägga till saker i texten. Så att det alltid känns som ett
        aktuellt stöd för dig. mWorks växer med dig.
      </p>
    </div>
  );
};

export const Slide7 = () => {
  return (
    <div className="disclaimer-carousel-card">
      <p>
        Första gången du använder mWorks kommer du mötas av ett chat-fönster.
        Det är vår chat-bot ”Mott” som med några korta meddelanden ger en
        introduktion till varje steg. Du kan när du vill stänga chat-fönstret
        genom att klicka på mWorks-ikonen nedanför.
      </p>
    </div>
  );
};
