import React, { useState } from 'react';
import BinIcon from '../../../assets/red-trash.svg';

const CustomEditableList = ({ listKey, addBtnText, listHeading, viewData, isEditingThisBox, updateDbByKey }) => {
  const list = viewData[listKey] || []

  const AddListItemBtnRow = () => {
    const [showInput, setShowInput] = useState(false)

    const addListItem = () => {
      setShowInput(true)
    }

    const AddListItemInput = () => {
      const [inputValue, setInputValue] = useState(null)

      const inputChangeHandler = e => {
        setInputValue(e.target.value.trim())
      }

      const inputBlurHandler = e => {
        setInputValue(e.target.value.trim())
        validateAndSaveInputValue()
      }

      const validateAndSaveInputValue = () => {
        if (!inputValue) return setShowInput(false)
        const newArray = [...viewData[listKey], inputValue]
        updateDbByKey(listKey, newArray)
      }

      const inputKeypressHandler = e => {
        if (e.key === 'Enter') {
          validateAndSaveInputValue()
        }
      }

      return (
        showInput ? <input type="text" onChange={inputChangeHandler} onBlur={inputBlurHandler} onKeyPress={inputKeypressHandler} autoFocus></input> : null
      )

    }

    const AddListItemBtn = () => {
      return isEditingThisBox && (
        <div className="add-list-item-btn show-cursor" onClick={addListItem}>
          {addBtnText}
        </div>
      ) || null
    }

    return (
      <>
        <AddListItemBtn />
        <AddListItemInput />
      </>
    )
  }

  const ClickableListItems = () => {
    const [highlightIndex, setHighlightIndex] = useState(null)

    const handleHighlight = index => {
      highlightIndex === index ? setHighlightIndex(null) : setHighlightIndex(index)
    }

    const handleDeleteListItem = index => {
      const newArray = viewData[listKey].filter((item, i) => i !== index)
      updateDbByKey(listKey, newArray)
    }

    return (<ul>
      {
        list.map((listItem, i) => {
          if (!isEditingThisBox) return (<li key={i}>{listItem}</li>)
          return (<li key={i} tabIndex="0" className={i === highlightIndex ? 'highlight' : ''} onBlur={() => setHighlightIndex(null)}>
            <div className="show-cursor" onClick={() => handleHighlight(i)}>{listItem}</div>
            {i === highlightIndex && <div>
              <img
                src={BinIcon}
                alt="Ta bort denna rad"
                className="bin-icon show-cursor"
                onClick={() => handleDeleteListItem(i)}
              />
            </div>}
          </li>)
        })
      }
    </ul>)
  }

  return (
    <div className="heading-group">
      <div className="heading sticky">{listHeading}</div>
      <AddListItemBtnRow />
      <ClickableListItems />
    </div>
  )
}

export default CustomEditableList