import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addClient } from '../../../../store/actions/rekoActions';
import './AddClient.css';
import mWorksLogo from '../../../../assets/navbarLarge/mWorks/slice@3x.png';

const AddClient = history => {
  const dispatch = useDispatch();
  const addClientEmailSuccess = useSelector(
    state => state.reko.addClientEmailSuccess
  );
  const [clientEmail, setClientEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (clientEmail)
      emailValidation(clientEmail) ? setIsDisabled(true) : setIsDisabled(false);
    else setIsDisabled(true);
  }, [clientEmail]);

  useEffect(() => {
    clearEmail();
  }, [addClientEmailSuccess]);

  const emailValidation = email => {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(email)) return true;
    else return false;
  };

  const clearEmail = () => {
    setClientEmail('');
  };
  const handleAddClient = (evt, clientEmail) => {
    evt.preventDefault();
    if (evt) {
      dispatch(addClient(clientEmail));
    }
  };

  return (
    <>
      <div className="middle-section">
        <div className="addclient-content-container">
          <div className="circle-container">
            <div className="logo-container">
              <div className="link-mworks">Mitt mWorks</div>
              <img src={mWorksLogo} className="logo" alt="" />
            </div>
          </div>
          <br />
          <div>
            <h1>Lägg till klientens email</h1>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="addclient-content-container">
          <div className="reko-content col-sm-12 col-md-6">
            <div className="reko-form-container">
              <form className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="reko-email">Klient email</label>
                  <input
                    type="email"
                    autoComplete="off"
                    className="form-control"
                    id="reko-email"
                    onChange={evt => setClientEmail(evt.target.value)}
                    value={clientEmail}
                    aria-describedby="emailHelp"
                    placeholder="Email..."
                  />
                </div>
                <div className="reko-button-container ptb-10">
                  <div key={clientEmail}>

                  <button
                    className="btn btn-primary btn-block"
                    onClick={evt => handleAddClient(evt, clientEmail)}
                    disabled={isDisabled}
                    >
                    Skapa
                  </button>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClient;
