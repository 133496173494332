import React from 'react';
import { useDispatch } from 'react-redux';
import LabelInfo from '../../labelInfo/LabelInfo';
import { setCloudModalData } from '../../../store/actions/cloudActions';
import './wordCloudSection.scss';
import penIcon from '../../../assets/edit.svg';
import AddIcon from '../../../assets/plus.svg';

const WordCloudSection = ({
  id,
  title,
  placeholder,
  myStrengthsList,
  randomizeList,
  listClick
}) => {
  const dispatch = useDispatch();

  const editCurrentStrengthHandler = currentStrengthData => {
    currentStrengthData.edit = true;
    dispatch(setCloudModalData(currentStrengthData));
  };

  const shuffleArray = array => {
    array.sort(() => Math.random() - 0.5);
  };

  const centerArray = arr => {
    if (!arr.length) return [];
    const arrCopy = [...arr];
    const newArr = [];

    arrCopy.sort(function (a, b) {
      return a.level - b.level;
    });

    // Put center piece of new array
    newArr.push(arrCopy.pop());

    // Spread remaining items around center piece
    while (arrCopy.length) {
      newArr[arrCopy.length % 2 === 0 ? 'push' : 'unshift'](arrCopy.pop());
    }

    return newArr;
  };

  // OPTIONAL SHUFFELING!
  randomizeList && shuffleArray(myStrengthsList);

  const cloudPyramidArray = centerArray(myStrengthsList);

  return (
    <div className="wordCloudSectionWrapper">
      <div>
        <LabelInfo id={id} title={title} noIcon="true" />
        <button
          className="btn top-margin btn-add-float"
          onClick={() => listClick(false, 'custom')}
          data-toggle="modal"
          data-target="#selectedStrength"
        >
          <span className="pen-icon-container plus-icon-container">
            <img
              src={AddIcon}
              alt="edit pen"
              className="plus-icon-img"
              data-inline="false"
            />
          </span>
        </button>
      </div>
      <div className="cloudwords-container">
        {cloudPyramidArray &&
          cloudPyramidArray.map((word, index) => {
            return (
              <div
                key={index}
                className={
                  'editable-word ' + word.color + ' size-' + word.level
                }
                onClick={() => editCurrentStrengthHandler(word)}
                data-toggle="modal"
                data-target="#selectedStrength"
              >
                {word.name}
              </div>
            );
          })}

        {/* Show example text if empty */}
        {!myStrengthsList.length && (
          <div className="placeholder">{placeholder}</div>
        )}
      </div>
    </div>
  );
};

export default WordCloudSection;
