export const tellOthersInitialFields = [
  {
    id: 1,
    placeholder:
      'Exempel: En nackdel är att jag är rädd för hur andra reagerar, att de bara ser det jag inte kan. En klar fördel är att inte längre behöva dölja hur jag mår. Då kanske min chef kan hjälpa mig bättre.',
    title:
      'Vilka är för- och nackdelarna med att vara öppen med din psykiska ohälsa?',
    text: '',
    focus: true
  },
  {
    id: 2,
    placeholder:
      'Exempel: Jag vill berätta att min depression inte är konstigare än att jag ibland är lite orkeslös. Och att jag använder strategier jag tagit fram i mWorks.  ',
    title: 'Vad vill du då berätta?',
    text: '',
    focus: false
  },
  {
    id: 3,
    placeholder:
      'Exempel: Min chef, så jag slipper dölja hur jag mår och kan berätta lite om mWorks. Och mina vänner, så de inte ger upp och tar det personligt eller så.',
    title: 'Vem vill du ska veta och varför? ',
    text: '',
    focus: false
  },
  {
    id: 4,
    placeholder:
      'Exempel: Kontakta min chef nästa månad, när jag kollat igenom de ”5 stegen” lite närmare. Tänker ett kortare utvecklingsmöte i samtalsrummet på jobbet.',
    title: 'När vill du ta upp dina funderingar och hur vill du göra det?',
    text: '',
    focus: false
  }
];
