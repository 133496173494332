import { SET_CLOUD_DATA_LIST, SET_CLOUD_MODAL_DATA } from '../actions/types';

const initialState = {
  cloudDataList: [],
  cloudModalData: {
    listIdentifier: '',
    name: '',
    id: '',
    level: 3,
    color: '',
    size: 'm'
  }
};

const cloudReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CLOUD_DATA_LIST:
      return {
        ...state,
        cloudDataList: payload
      };
    case SET_CLOUD_MODAL_DATA:
      return {
        ...state,
        cloudModalData: payload
      };
    default:
      return state;
  }
};

export default cloudReducer;
