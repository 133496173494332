import './DisclaimerModal.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../store/actions/userActions';
import Carousel from '@brainhubeu/react-carousel';
import {
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7
} from '../slides/Slides';
import mWorkslogo from '../../../assets/homepage/mworks-logo@3x.png';

const DisclaimerModal = ({ setHideDisclaimer }) => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.user.user);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const disclaimerSlidesArray = [
    <Slide1 />,
    <Slide2 />,
    <Slide3 />,
    <Slide4 />,
    <Slide5 />,
    <Slide6 />,
    <Slide7 />
  ];
  const totalCarouselSlidesCount = disclaimerSlidesArray.length - 1;

  useEffect(() => {
    // Lock body from scrolling as long as disclaimer is up (for better experience on mobile)
    document.querySelector('body').setAttribute('class', 'body-lock');
    return () => {
      document.querySelector('body').setAttribute('class', '');
    };
  }, []);

  useEffect(() => {
    // In case slideHeight is large in one and small in other, make sure
    // to reset scroll so next slide is shown from top
    document.querySelector('.disclaimer-carousel-body').scrollTop = 0;
  }, [carouselIndex]);

  const carouselBackHandler = () => {
    setCarouselIndex(carouselIndex - 1);
  };

  const carouselForwardHandler = () => {
    setCarouselIndex(carouselIndex + 1);
  };

  const DismissButton = () => (
    <button type="button" onClick={handleDismiss}>
      Klar
    </button>
  );
  const NextButton = () => (
    <button type="button" onClick={carouselForwardHandler}>
      {'Nästa'}
    </button>
  );
  const PreviousButton = () => (
    <button
      className="carousel-back-button"
      type="button"
      onClick={carouselBackHandler}
    >
      {'Föregående'}
    </button>
  );

  const handleDismiss = () => {
    setHideDisclaimer(true);
    const tempUser = Object.assign({}, user, { hasLoggedInBefore: true });
    dispatch(updateUser(tempUser));
  };

  const CustomDots = () => {
    return disclaimerSlidesArray.map((x, i) => {
      return (
        <span key={i} className={i === carouselIndex ? 'active' : ''}>
          &bull;
        </span>
      );
    });
  };

  const CarouselButtonBar = () => {
    return (
      <div className="disclaimer-button-bar">
        <div>{carouselIndex !== 0 && <PreviousButton />}</div>
        <div className="custom-dots">
          <CustomDots />
        </div>
        <div>
          {carouselIndex === totalCarouselSlidesCount ? (
            <DismissButton />
          ) : null}
          {carouselIndex !== totalCarouselSlidesCount && <NextButton />}
        </div>
      </div>
    );
  };

  return (
    <div id="disclaimer-modal">
      <div className="disclaimer-modal-body">
        <div className="disclaimer-carousel-body">
          <div className="disclaimer-logo">
            <img src={mWorkslogo} alt="" />
          </div>
          <Carousel
            dots
            slides={disclaimerSlidesArray}
            value={carouselIndex}
            onChange={currIndex => setCarouselIndex(currIndex)}
          />
        </div>
        <CarouselButtonBar />
      </div>
    </div>
  );
};

export default DisclaimerModal;
