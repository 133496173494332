import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeRekoPassword,
  fetchRekoById
} from '../../../../store/actions/adminActions';
import './ChangeRekoPassword.css';
import { toast } from 'react-toastify';
import mWorksLogo from '../../../../assets/navbarLarge/mWorks/slice@3x.png';
import { CHANGE_REKO_PASSWORD_SUCCESS } from '../../../../store/actions/types';

const ChangeRekoPassword = rekoId => {
  const dispatch = useDispatch();
  const changeRekoPasswordSuccess = useSelector(
    state => state.admin.changeRekoPasswordSuccess
  );
  const [password, setPassword] = useState('');
  const [passwordCompare, setPasswordCompare] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const clearForm = () => {
    setPassword('');
    setPasswordCompare('');
  };

  useEffect(() => {
    if (changeRekoPasswordSuccess) clearForm();
  }, [changeRekoPasswordSuccess]);

  useEffect(() => {
    if (password && passwordCompare) setIsDisabled(false);
    else setIsDisabled(true);
  }, [password, passwordCompare]);

  const handleChangePassword = async evt => {
    evt.preventDefault();

    if (password !== passwordCompare) {
      return toast.error('Lösenorden matchar inte!', {
        position: 'top-center'
      });
    }

    console.log('RekoData', rekoId + password);
    console.log('evt targ val', evt.target.value);
    dispatch(changeRekoPassword(rekoId.rekoId, password));
  };

  return (
    <>
      <div className="middle-section">
        <div className="addreko-content-container">
          <div className="circle-container">
            <div className="logo-container">
              <div className="link-mworks">Mitt mWorks</div>
              <img src={mWorksLogo} className="logo" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="register-content col-sm-12 col-md-6">
          <p className="register-container-header-text ptb-10">
            <strong>Skapa nytt lösenord för personal</strong>
          </p>
          <div className="register-form-container">
            <form className="col-sm-12">
              <div className="form-group">
                <label htmlFor="register-password">Nytt lösenord</label>
                <input
                  autoComplete="off"
                  type="password"
                  className="form-control"
                  id="register-password"
                  onChange={evt => setPassword(evt.target.value)}
                  placeholder="Nya lösenord..."
                  value={password}
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-password-confirm">
                  Upprepa det nya lösenordet
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="register-password-confirm"
                  onChange={evt => setPasswordCompare(evt.target.value)}
                  placeholder="Lösenordet en gång till..."
                  value={passwordCompare}
                />
              </div>
              <div className="register-button-container ptb-10">
                <button
                  className="btn btn-primary btn-block"
                  onClick={evt =>
                    handleChangePassword(evt, { rekoId, password })
                  }
                  disabled={isDisabled}
                >
                  Uppdatera lösenordet
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeRekoPassword;
