import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import './MyMworks.css';
import { updateUser } from '../../store/actions/userActions';

import mWorkslogo from '../../assets/homepage/mworks-logo@3x.png';
import Section from '../section/Section';
import MyWayBackSection from './myWayBackSection/MyWayBackSection';
import InfoModal from '../infoModal/InfoModal';
import InfoBox from '../InfoBox/InfoBox';
import useChatbot from '../../hooks/useChatbot';
import ChatWindow from '../chatWindow/ChatWindow';
import useDevice from '../../constants/devices';
import { MY_MWORKS_EVENT } from '../../constants/constants';
import { setLogoutTime } from '../../utils/setLogoutTime';

const MyMworks = ({ pageWidth }) => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const [showResults, setShowResults] = useState(false);
  const messages = useSelector(state => state.message);
  const [myHistory, setMyHistory] = useState(
    user.myMworks ? user.myMworks.history : ''
  );
  const [myBillboard, setMyBillboard] = useState(
    user.myMworks?.billboard || ''
  );
  const [myHistoryInfoClassName, setMyHistoryInfoClassName] =
    useState('my-history-info');
  const [myBillboardInfoClassName, setMyBillboardInfoClassName] =
    useState('my-billboard-info');
  const [myWayBackInfoClassName, setMyWayBackInfoClassName] =
    useState('my-way-back-info');

  useEffect(() => {
    if (!user.pagesVisited.mworks) {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    setMyHistory(user.myMworks?.history || '');
    setMyBillboard(user.myMworks?.billboard || '');
  }, [user]);

  useEffect(() => {
    if (
      user &&
      user.chatbotConversations.mworks.length === 0 &&
      messages.mworks.length === 0
    ) {
      chatBot.eventQuery(MY_MWORKS_EVENT, user);
    }
  }, []);

  useEffect(() => {
    setMyHistory(user.myMworks ? user.myMworks.history : '');
    setMyBillboard(user.myMworks ? user.myMworks.billboard : '');
  }, [user]);

  const handleOnIdle = () => {
    const sessions = setLogoutTime(user, getLastActiveTime());
    const tempUser = Object.assign({}, user, { sessions });
    dispatch(updateUser(tempUser));
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const subSections = ['history', 'billboard'];
  const CHAT_WINDOW = {
    event: MY_MWORKS_EVENT,
    section: 'myMworks',
    subsection: subSections
  };

  /* INFO_TEXT_MODALS */
  const MY_STORY_MODAL = {
    title: 'Min berättelse',
    body: '',
    specialBody: [
      'Här är en plats du kan skriva om din sjukskrivning. Exempelvis kan du berätta om vad som ledde fram till din sjukskrivning och dina upplevelser sedan dess. Du kan också skriva om din situation just nu.',
      <>
        Använd skrivandet som ett sätt att reflektera och komma ikapp dina
        tankar och känslor.{' '}
        <em>Vad har påverkat dig mest och var är du idag?</em> Skriv gärna datum
        så hittar du bättre i texten.
      </>
    ],
    placeholder:
      'Exempel: \n11 sep 2020. Det var mycket jargong på jobbet. Att inte visa känslor var min grej så jag mådde allt sämre på jobbet. Hemma orkade jag inte upp ur sängen. Läkaren sa att jag hade en depression och sjukskrev mig… \n15 sep 2020. Blev lite bättre, fick någon medicin och så, men är orolig för att börja jobba. Men var ska jag börja? Tror på någon slags mjukstart.'
  };

  const MY_WAY_BACK_MODAL = {
    title: 'Min väg tillbaka',
    body: '',
    specialBody: [
      'Här kan du sammanfatta och ta reda på vad som motiverar dig att gå tillbaka till arbetet. Som hjälp finns några frågor:',
      <>
        <em>1) Just nu, hur viktigt är det att börja arbeta igen?</em> (0=inte
        viktigt alls—100=viktigast i mitt liv)? Motivera ditt svar i textfältet.
      </>,
      <>
        <em>
          2) Vilka är fördelarna och nackdelarna med att gå tillbaka till
          arbetet?
        </em>{' '}
        Skriv upp dem var för sig så du enkelt kan jämföra.
      </>,
      <>
        <em>3) Vad skulle ditt motiv vara för att gå tillbaka till arbetet?</em>{' '}
        Sammanfatta vad som är viktigt just för dig.
      </>
    ],
    placeholder:
      'Exempel: \nAtt komma in i ett sammanhang igen och träffa folk. \nAtt vara sjukskriven tynger en och gör en sämre. \nViktigt att bryta den onda cirkeln med att bara gå hemma.'
  };

  const MY_BILLBOARD_MODAL = {
    title: 'Mina anteckningar',
    body: (
      <>
        Det här är en plats du kan vara kreativ och samla idéer, sådant du går
        och funderar på. Ibland kan till exempel de <i>5 stegen</i> leda till
        nya tankar du vill komma ihåg.
      </>
    ),
    placeholder:
      'Exempel: \n-Glöm inte att ta hand om mig själv. Jag är lika viktig som vänner och familj <3 \n-Lägg alltid in pauser före och efter en händelse i kalendern. \n-Tänk på att målen ska uppdateras varje månad!'
  };
  /* INFO_TEXT_MODALS END */

  const updateUserHandler = () => {
    const myMworks = {
      history: myHistory,
      billboard: myBillboard
    };
    const tempUser = Object.assign({}, user, { myMworks });
    dispatch(updateUser(tempUser));
  };

  return (
    <div className="mymworks">
      <div className="background-container"></div>
      <div className="main-container ">
        <div className="left-container">
          <InfoBox
            className={myHistoryInfoClassName}
            modalText={MY_STORY_MODAL}
          />
          <div className="left-top-container">
            <Section
              rows={pageWidth > 992 ? 8 : 9}
              id="myHistory"
              value={myHistory}
              onChange={evt => setMyHistory(evt.target.value)}
              onBlur={updateUserHandler}
              title="Min berättelse"
              modalText={MY_STORY_MODAL}
              placeholder={MY_STORY_MODAL.placeholder}
              updateUserHandler={updateUserHandler}
              defaultInfoClassName="my-history-info"
              setInfoClassName={setMyHistoryInfoClassName}
            />
          </div>
          <InfoBox
            className={myBillboardInfoClassName}
            modalText={MY_BILLBOARD_MODAL}
          />
          <div className="left-bottom-container">
            <Section
              rows={pageWidth > 992 ? 8 : 7}
              id="myBillboard"
              value={myBillboard}
              onChange={evt => setMyBillboard(evt.target.value)}
              onBlur={updateUserHandler}
              title="Mina anteckningar"
              modalText={MY_BILLBOARD_MODAL}
              placeholder={MY_BILLBOARD_MODAL.placeholder}
              updateUserHandler={updateUserHandler}
              defaultInfoClassName="my-billboard-info"
              setInfoClassName={setMyBillboardInfoClassName}
            />
          </div>
        </div>
        <div className="right-container">
          <InfoBox
            className={myWayBackInfoClassName}
            modalText={MY_WAY_BACK_MODAL}
          />
          <div className="right-container-inner">
            <MyWayBackSection
              id="myWayBack"
              title="Min väg tillbaka"
              modalText={MY_WAY_BACK_MODAL}
              placeholder={MY_WAY_BACK_MODAL.placeholder}
              defaultInfoClassName="my-way-back-info"
              setInfoClassName={setMyWayBackInfoClassName}
            />
          </div>
        </div>
      </div>
      <InfoModal modalText={MY_STORY_MODAL} id="myHistory" />
      <InfoModal modalText={MY_WAY_BACK_MODAL} id="myWayBack" />
      <InfoModal modalText={MY_BILLBOARD_MODAL} id="myBillboard" />
      <ChatWindow
        modalText={CHAT_WINDOW}
        showResults={showResults}
        setShowResults={setShowResults}
        user={user}
        page={user.pagesVisited.mworks}
        pageName="mworks"
      />
    </div>
  );
};

export default MyMworks;
