import {
  ADD_REKO_SUCCESS,
  ADD_REKO_FAIL,
  ADD_REKO_FAIL_REKO_EXISTS,
  FETCH_REKO_BY_ID_FAIL,
  FETCH_REKO_BY_ID_SUCCESS,
  CHANGE_REKO_PASSWORD_FAIL,
  CHANGE_REKO_PASSWORD_SUCCESS,
  DELETE_REKO_FAIL,
  DELETE_REKO_SUCCESS,
  FETCH_REKOS_SUCCESS,
  FETCH_REKOS_FAIL,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL
} from './types';
import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true
};

export const fetchRekos = () => async dispatch => {
  const result = await fetch(`/api/admin/rekos`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: FETCH_REKOS_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }

  const data = await result.json();

  return dispatch({
    type: FETCH_REKOS_SUCCESS,
    payload: data
  });
};

export const fetchRekoById = rekoId => async dispatch => {
  const result = await fetch(`/api/admin/reko/${rekoId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: FETCH_REKO_BY_ID_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }

  const data = await result.json();

  return dispatch({
    type: FETCH_REKO_BY_ID_SUCCESS,
    payload: data
  });
};

export const addReko = userObject => async dispatch => {
  const result = await fetch(`/api/admin/reko`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userObject),
    credentials: 'include'
  });

  const data = await result.json();

  if (result.status !== 201) {
    if (result.status === 409) {
      //toast.error('Det finns redan en användare med den emailen!', toastConfig);
      return dispatch({
        type: ADD_REKO_FAIL_REKO_EXISTS,
        payload: 'Det finns redan en användare med den emailen!'
      });
    }

    return dispatch({
      type: ADD_REKO_FAIL,
      payload: data.error
    });
  }
  toast.info('Reko är tillagd!', toastConfig);
  return dispatch({
    type: ADD_REKO_SUCCESS
  });
};

export const changeRekoPassword = (rekoId, password) => async dispatch => {
  const rekoData = { rekoId, password };
  console.log(rekoData)
  const result = await fetch(`/api/admin/reko/${rekoId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true
    },
    credentials: 'include',
    body: JSON.stringify(rekoData)
  });

  if (result.status !== 200) {
    dispatch({
      type: CHANGE_REKO_PASSWORD_FAIL,
      payload: 'Oops, något gick fel...'
    });
  }

  const data = await result.json();

  toast.info('Lösenordet för vald REKO har förnyats!', toastConfig);
  return dispatch({
    type: CHANGE_REKO_PASSWORD_SUCCESS,
    payload: data.user
  });
};

export const deleteReko = rekoId => async dispatch => {
  const result = await fetch(`/api/admin/reko/${rekoId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: DELETE_REKO_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }
  toast.info('Reko är raderat!', toastConfig);
  return dispatch({
    type: DELETE_REKO_SUCCESS
  });
};

export const fetchClients = () => async dispatch => {
  const result = await fetch(`/api/admin/clients`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: FETCH_CLIENTS_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }

  const data = await result.json();

  return dispatch({
    type: FETCH_CLIENTS_SUCCESS,
    payload: data
  });
};

export const deleteClient = clientId => async dispatch => {
  const result = await fetch(`/api/admin/client/${clientId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: DELETE_CLIENT_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }
  toast.info('Klient är raderat!', toastConfig);
  return dispatch({
    type: DELETE_CLIENT_SUCCESS
  });
};

export const fetchUsers = () => async dispatch => {
  const result = await fetch(`/api/admin/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: FETCH_USERS_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }

  const data = await result.json();

  return dispatch({
    type: FETCH_USERS_SUCCESS,
    payload: data
  });
};
