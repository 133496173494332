// ADMIN
export const ADD_REKO_SUCCESS = 'ADD_REKO_SUCCESS';
export const ADD_REKO_FAIL = 'ADD_REKO_FAIL';
export const ADD_REKO_FAIL_REKO_EXISTS = 'ADD_REKO_FAIL_REKO_EXISTS';
export const CHANGE_REKO_PASSWORD_SUCCESS = 'CHANGE_REKO_PASSWORD_SUCCESS';
export const CHANGE_REKO_PASSWORD_FAIL = 'CHANGE_REKO_PASSWORD_FAIL';
export const DELETE_REKO_SUCCESS = 'DELETE_REKO_SUCCESS';
export const DELETE_REKO_FAIL = 'DELETE_REKO_FAIL';
export const FETCH_REKOS_SUCCESS = 'FETCH_REKOS_SUCCESS';
export const FETCH_REKOS_FAIL = 'FETCH_REKOS_FAIL';
export const FETCH_REKO_BY_ID_SUCCESS = 'FETCH_REKO_BY_ID_SUCCESS';
export const FETCH_REKO_BY_ID_FAIL = 'FETCH_REKO_BY_ID_FAIL';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

// AUTH
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_FAIL_USER_EXISTS = 'REGISTER_FAIL_USER_EXISTS';
export const TOKEN_VERIFICATION_SUCCESS = 'TOKEN_VERIFICATION_SUCCESS';
export const TOKEN_VERIFICATION_FAIL = 'TOKEN_VERIFICATION_FAIL';

// USER
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

// REKO
export const ADD_CLIENT_EMAIL_SUCCESS = 'ADD_CLIENT_EMAIL_SUCCESS';
export const ADD_CLIENT_EMAIL_FAIL = 'ADD_CLIENT_EMAIL_FAIL';
export const ADD_CLIENT_EMAIL_FAIL_EMAIL_EXISTS =
  'ADD_CLIENT_EMAIL_FAIL_EMAIL_EXISTS';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';

// CHATBOT
export const SAVE_HOME_MESSAGE = 'SAVE_HOME_MESSAGE';
export const SAVE_MWORKS_MESSAGE = 'SAVE_MWORKS_MESSAGE';
export const SAVE_RESOURCES_MESSAGE = 'SAVE_RESOURCES_MESSAGE';
export const SAVE_NETWORK_MESSAGE = 'SAVE_NETWORK_MESSAGE';
export const SAVE_WELLBEING_MESSAGE = 'SAVE_WELLBEING_MESSAGE';
export const SAVE_STRATEGY_MESSAGE = 'SAVE_STRATEGY_MESSAGE';
export const SAVE_PLANNING_MESSAGE = 'SAVE_PLANNING_MESSAGE';

//MAIN
export const SET_BACKGROUND_INDEX = 'SET_BACKGROUND_INDEX';
export const SET_EDITING_ACTIVE = 'SET_EDITING_ACTIVE';
export const SET_EDITING_NOT_ACTIVE = 'SET_EDITING_NOT_ACTIVE';

//CLOUD
export const SET_CLOUD_DATA_LIST = 'SET_CLOUD_DATA_LIST';
export const SET_CLOUD_PLACEMENTS_LIST = 'SET_CLOUD_PLACEMENT_LIST';
export const SET_CLOUD_MODAL_DATA = 'SET_CLOUD_MODAL_DATA';
