import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomNavLink from './CustomNavLink';
import { resetPassword } from '../../../store/actions/userActions';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {
  CustomLeftArrow,
  CustomRightArrow
} from './customArrows/CustomArrows.jsx';

import './NavbarSmall.css';

import mWorksLogo from '../../../assets/cardSymbols/mworks.svg';
import resourcesLogo from '../../../assets/cardSymbols/resources.svg';
import networkLogo from '../../../assets/cardSymbols/network.svg';
import wellbeingLogo from '../../../assets/cardSymbols/wellbeing.svg';
import strategiesLogo from '../../../assets/cardSymbols/strategies.svg';
import planningLogo from '../../../assets/cardSymbols/planning.svg';

import {
  ROUTE_HOME,
  ROUTE_MY_MWORKS,
  ROUTE_MY_RESOURCES,
  ROUTE_MY_STRATEGIES,
  ROUTE_MY_PLANNING,
  ROUTE_MY_WELLBEING,
  ROUTE_MY_NETWORK
} from '../../../constants/constants';

const NavbarSmall = ({ handleLogout, pathName, ...props }) => {
  const dispatch = useDispatch();
  const role = useSelector(state => state.user.userRole);
  const user = useSelector(state => state.user.user);

  const handleResetPassword = () => {
    return dispatch(resetPassword(user));
  };

  const items = [
    {
      route: ROUTE_MY_MWORKS,
      title: 'Mitt mWorks',
      cssClass: 'link-mworks',
      logo: mWorksLogo,
      lineColor: '#6ea29e'
    },
    {
      route: ROUTE_MY_RESOURCES,
      title: 'Mina resurser',
      cssClass: 'link-resources',
      logo: resourcesLogo,
      lineColor: '#f2c20d'
    },
    {
      route: ROUTE_MY_NETWORK,
      title: 'Mitt nätverk',
      cssClass: 'link-network',
      logo: networkLogo,
      lineColor: '#e6903b'
    },
    {
      route: ROUTE_MY_WELLBEING,
      title: 'Mitt mående',
      cssClass: 'link-wellbeing',
      logo: wellbeingLogo,
      lineColor: '#6c8bde'
    },
    {
      route: ROUTE_MY_STRATEGIES,
      title: 'Mina strategier',
      cssClass: 'link-strategies',
      logo: strategiesLogo,
      lineColor: '#d16476'
    },
    {
      route: ROUTE_MY_PLANNING,
      title: 'Min planering',
      cssClass: 'link-planning',
      logo: planningLogo,
      lineColor: '#7daa47'
    }
  ];

  const history = useHistory();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(null);
  const bgLine = document.querySelector('#bgLine');
  let currentList = useRef([]);
  let lastRoute = useRef(null);

  useEffect(() => {
    // Handle first visit to init carousel index
    if (currentSlideIndex === null) {
      items.map((item, i) => {
        if (item.route === currentRoute) {
          lastRoute.current = currentRoute;
          setCurrentSlideIndex(i);
        }
      });
    }

    // Manage browser back & forward actions
    if (currentSlideIndex !== null) {
      if (history.action === 'POP') {
        const items = currentList.current;
        let currentRouteIndex, previousRouteIndex;
        let slideIndexIsNegative = Math.sign(currentSlideIndex) === -1;

        items.map((item, i) => {
          if (item.route === currentRoute) currentRouteIndex = i;
          if (item.route === lastRoute.current) previousRouteIndex = i;
        });

        if (slideIndexIsNegative) {
          if (currentRouteIndex < previousRouteIndex) {
            lastRoute.current = currentRoute;
            let rangeJumpValue = previousRouteIndex - currentRouteIndex;
            setCurrentSlideIndex(currentSlideIndex + rangeJumpValue);
          }

          if (currentRouteIndex > previousRouteIndex) {
            lastRoute.current = currentRoute;
            let rangeJumpValue = currentRouteIndex - previousRouteIndex;
            setCurrentSlideIndex(currentSlideIndex - rangeJumpValue);
          }
        }

        if (!slideIndexIsNegative) {
          if (currentRouteIndex < previousRouteIndex) {
            lastRoute.current = currentRoute;
            let rangeJumpValue = previousRouteIndex - currentRouteIndex;
            setCurrentSlideIndex(currentSlideIndex - rangeJumpValue);
          }

          if (currentRouteIndex > previousRouteIndex) {
            lastRoute.current = currentRoute;
            let rangeJumpValue = currentRouteIndex - previousRouteIndex;
            setCurrentSlideIndex(currentSlideIndex + rangeJumpValue);
          }
        }
      }
    }
  }, [currentRoute]);

  useEffect(() => {
    document.querySelector('html').scrollTop = 0;
  }, [currentRoute]);

  useEffect(() => {
    // Because carousel is infinite, its index can go inte negatives which requires re-ordering (reversing) the list.
    // first slide needs to be center-point @ index 0
    let reArrangedItems = [];

    if (Number.isInteger(currentSlideIndex)) {
      if (Math.sign(currentSlideIndex) === -1) {
        const restOflist = [...items];
        restOflist.splice(0, 1);
        reArrangedItems = [items[0], ...restOflist.reverse()];
      } else {
        reArrangedItems = [...items];
      }

      const loadItemRoute = Math.abs(currentSlideIndex % items.length);
      currentList.current = reArrangedItems;
      reArrangedItems.map((item, i) => {
        if (i === loadItemRoute && item.route !== currentRoute) {
          lastRoute.current = item.route;
          history.push(item.route);
        }
      });
    }
  }, [currentSlideIndex]);

  useEffect(() => {
    // Colorize line-graphic
    if (!bgLine) return;
    const hexColor = items.filter(item => {
      return item.route === currentRoute;
    })[0].lineColor;
    document.querySelector('#bgLine').setAttribute('stroke', hexColor);
  }, [bgLine]);

  const ColoredBgLine = () => {
    return (
      <div className="bg-line">
        <svg xmlns="http://www.w3.org/2000/svg">
          <g
            id="mobilvy"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeOpacity="0.25"
          >
            <g
              id="bgLine"
              transform="translate(-1.000000, -72.000000)"
              stroke="#FDC472"
              strokeWidth="4"
            >
              <g id="Group-5" transform="translate(-48.000000, 40.000000)">
                <path d="M49.6533333,42.56 C61.8140469,42.5999071 85.3702803,42.6198607 120.322034,42.6198607 C172.749664,42.6198607 188.377157,30.7952306 225.06096,34.8000782 C261.744763,38.8049257 282.877953,65.4176148 340.603604,50.1088465 C379.08737,39.903001 408.770614,37.3867188 429.653333,42.56"></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  };

  return (
    <>
      <div className="navbar-small">
        <div className="top-container">
          <div className="left-container">
            <Link to={ROUTE_HOME} className="link-homepage">
              Till startsidan
            </Link>
          </div>
          <div className="right-container">
            {/* THIS IS FOR DEVELOPMENT... */}
            {role === 'admin' && (
              <Link
                to="/admin"
                style={{
                  textDecoration: 'none',
                  color: 'red',
                  marginRight: '20px'
                }}
              >
                Admin
              </Link>
            )}
            <p className="logout-link" onClick={handleResetPassword}>
              Återställ lösenord
            </p>
            <p className="logout-link" onClick={handleLogout}>
              Logga ut
            </p>
          </div>
        </div>
      </div>
      <ColoredBgLine />
      <div className="navbar-carousel">
        <Carousel
          className="nav-small-carousel"
          arrows
          dots
          centered
          infinite
          slidesPerPage={2}
          clickToChange
          value={currentSlideIndex}
          onChange={e => setCurrentSlideIndex(e)}
          arrowLeft={<CustomLeftArrow />}
          arrowRight={<CustomRightArrow />}
          addArrowClickHandler
        >
          {items.map((item, index) => {
            return (
              <div key={index}>
                <CustomNavLink item={item} index={index} />
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default NavbarSmall;
