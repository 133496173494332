export const stopZoomOnMobile = () => {
  // Add eventListeners to document in order to stop unintentional zooming
  // of app on mobile devies (e.g when user clicks something in succession and toggles a browser zoom)
  const delay = 500
  let preTouchTime = 0
  let lastTouchTime = 0

  document.addEventListener('touchstart', () => {
    preTouchTime = lastTouchTime
    lastTouchTime = Date.now()
  })

  document.addEventListener('touchend', (event) => {
    const touchEndTime = Date.now()
    if (touchEndTime - preTouchTime < delay) {
      event.preventDefault()
      event.target.click()
    }
  })
}