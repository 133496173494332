import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import svLocale from '@fullcalendar/core/locales/sv';

import EventForm from './eventForm/EventForm';

import './Calendar.css';

const Calendar = ({
  events,
  handleOnSubmit,
  deleteEvent,
  setEditing,
  setAdding,
  isAdding,
  isEditing
}) => {
  const [eventToEdit, setEventToEdit] = useState(null);
  // const addTableHeader = tableElement => {
  //   const table = tableElement.getElementsByClassName('fc-list-table')[0];
  //   console.log(table);
  //   if (table !== undefined) {
  //     const header = table.createTHead();
  //     header.className = `${header.className} my-header`;
  //     let row = header.insertRow(0);
  //     row.insertCell(0).innerHTML = 'Aktivitet'; // Don't use innerHTML due to security???
  //     row.insertCell(0);
  //     row.insertCell(0).innerHTML = 'Datum';
  //   }
  // };

  // const handleDatesRender = info => {
  //   addTableHeader(info.el);
  // };

  const handleEventClick = info => {
    const event = info.event;
    setEventToEdit({
      _id: event.extendedProps._id,
      title: event.title,
      start: event.start
    });

    setEditing(true);
  };

  return (
    <>
      {!isAdding && !isEditing && (
        <>
          <FullCalendar
            eventClick={handleEventClick}
            defaultView="listMonth"
            // datesRender={handleDatesRender}
            plugins={[listPlugin]}
            events={events}
            locale={svLocale}
            defaultTimedEventDuration="00:00"
            noEventsMessage="Händelser saknas denna månaden"
            titleFormat={{
              year: 'numeric',
              month: 'long'
            }}
            eventTimeFormat={{
              hour: 'numeric',
              minute: 'numeric',
              meridiem: false
            }}
            eventBackgroundColor={'white'}
          />
          <button
            className="fc-button fc-button-primary add-button"
            data-toggle="modal"
            data-target="#calendarEventModal"
            onClick={() => setAdding(true)}
          >
            Lägg till händelse
          </button>
        </>
      )}
      {isAdding && !isEditing && (
        <EventForm
          setEditing={setEditing}
          setAdding={setAdding}
          handleOnSubmit={handleOnSubmit}
        />
      )}
      {!isAdding && isEditing && (
        <EventForm
          setEditing={setEditing}
          setAdding={setAdding}
          eventToEdit={eventToEdit}
          handleOnSubmit={handleOnSubmit}
          deleteEvent={deleteEvent}
          isEditing={isEditing}
        />
      )}
    </>
  );
};

export default Calendar;
