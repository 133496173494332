import React, { useState, forwardRef } from 'react';
import { Link } from 'react-router-dom';

const Card = forwardRef((props, ref) => {
  const {
    number,
    title,
    logo,
    info,
    circleClass,
    boxClass,
    cardClass,
    to,
    showToggleButton,
    mworksSphere = false
  } = props
  const [showInfo, setShowInfo] = useState(!showToggleButton);

  const toggleInfo = evt => {
    evt.preventDefault();
    setShowInfo(prevState => !prevState);
  };

  return (
    <>
      <Link to={to} className={`card__box-container ${cardClass}`}>
        {circleClass && (
          <div className={`card__circle ${circleClass}`}>{number}</div>
        )}
        <div
          className={`card__box ${boxClass} ${mworksSphere ? 'mworks-sphere' : ''
            }`}
        >
          <div className="card__title">{title}</div>
          <div className="card__logo-container">
            <img src={logo} alt="" className="card__logo" />
          </div>
          <div className="card__content">
            {showInfo ? (
              <p className="card__info-text">{info}</p>
            ) : (
                <button className="card__info-button" onClick={toggleInfo}>
                  Mer info
                </button>
              )}
          </div>
        </div>
      </Link>
    </>
  );
})

export default Card;
