import { SET_CLOUD_PLACEMENTS_LIST  } from '../actions/types';

const initialState = [
  { cssClass: 'one', occupied: false },
  { cssClass: 'two', occupied: false },
  { cssClass: 'three', occupied: false },
  { cssClass: 'four', occupied: false },
  { cssClass: 'five', occupied: false },
  { cssClass: 'six', occupied: false }
];

const cloudPlacementsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CLOUD_PLACEMENTS_LIST:
      return payload;
    default:
      return state;
  }
}

export default cloudPlacementsReducer;