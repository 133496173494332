import React from 'react';
import './InfoBox.css';

const infoBox = ({
  className,
  modalText,
  id,
  defaultInfoClassName,
  setInfoClassName
}) => {
  const { title, body, specialBody } = modalText;

  return (
    <div className={className}>
      <div className="info-header">
        <h5>{title}</h5>
        {id === 'my-network-info' && (
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => {
                setInfoClassName(defaultInfoClassName);
              }}
            >
              Klar
            </button>
          </div>
        )}
      </div>
      <div className="info-body">
        {specialBody && specialBody.length ? (
          specialBody.map((item, index) => <p key={index}>{item}</p>)
        ) : (
          <p>{body}</p>
        )}
      </div>
    </div>
  );
};

export default infoBox;
