import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './EventForm.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { sv } from 'date-fns/locale';

const EventForm = ({
  deleteEvent,
  setAdding,
  setEditing,
  eventToEdit,
  handleOnSubmit,
  isEditing
}) => {
  const [date, setDate] = useState(
    eventToEdit
      ? moment(new Date(eventToEdit.start)).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD')
  );
  const [time, setTime] = useState(
    eventToEdit
      ? moment(new Date(eventToEdit.start)).format('HH:mm')
      : moment(new Date()).format('HH:mm')
  );
  const [eventTitle, setEventTitle] = useState(
    eventToEdit ? eventToEdit.title : ''
  );
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (eventTitle?.length > 0 && dateTimeComparison() === true)
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [eventTitle, date, time]);

  const dateTimeComparison = () => {
    if (date > moment(new Date()).format('YYYY-MM-DD')) {
      return true;
    }
    if (
      date === moment(new Date()).format('YYYY-MM-DD') &&
      time > moment(new Date()).format('HH:mm')
    )
      return true;
    else return false;
  };

  const cancelClick = evt => {
    evt.preventDefault();
    setAdding(false);
    setEditing(false);
  };

  const handleSubmit = evt => {
    evt.preventDefault();

    const trimmedEventTitle = eventTitle.replace(/^\s+|\s+$/g, '');
    if (isEditing) {
      const editedEvent = {
        title: trimmedEventTitle,
        start: `${date}T${time}`,
        _id: eventToEdit._id
      };
      setEditing(false);
      return handleOnSubmit(editedEvent);
    }
    const newEvent = {
      title: trimmedEventTitle,
      start: `${date}T${time}`
    };

    setAdding(false);
    setEditing(false);
    return handleOnSubmit(newEvent);
  };

  const handleDeleteEvent = evt => {
    evt.preventDefault();
    setEditing(false);
    deleteEvent(eventToEdit._id);
  };

  // TODO: Copy structur and styling from ContactForm.jsx...
  registerLocale('sv', sv);
  return (
    <>
      <div className="event-form">
        <div className="event-form__form-container">
          <div className="event-form__header-container">
            <p className="event-form__header">
              {isEditing ? `Redigera händelse` : 'Lägg till en händelse'}
            </p>
            <p className="intimation-message">
              *(Datum och tid måste vara i framtiden)
            </p>
          </div>
          <form className="event-form__form">
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="datePicker">Välj datum</label>
                <div className="input-group">
                  <DatePicker
                    selected={new Date(date)}
                    onChange={evt =>
                      setDate(moment(new Date(evt)).format('YYYY-MM-DD'))
                    }
                    value={date}
                    locale="sv"
                    className="datepicker"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="timePicker">Välj tid</label>
                <div className="input-group">
                  <input
                    type="time"
                    name="timePicker"
                    id="timePicker"
                    value={time}
                    onChange={evt => setTime(evt.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-xs-12 col-md-12">
                <label htmlFor="eventTitle">
                  Händelse
                  <small id="titleHelp" className="form-text text-muted">
                    *
                  </small>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="eventTitle"
                    id="eventTitle"
                    value={eventTitle}
                    onChange={evt => setEventTitle(evt.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="submit-button-container row">
              <button
                type="button"
                className="bg-danger cancel-button col-sm-2 col-xs-2 col-md-4 mb-3"
                data-dismiss="modal"
                onClick={evt => cancelClick(evt)}
              >
                Avbryt
              </button>
              {isEditing && (
                <button
                  type="button"
                  className="bg-warning delete-button col-sm-3 col-xs-3 col-md-4 mb-3"
                  onClick={evt => handleDeleteEvent(evt)}
                >
                  <span
                    className="iconify delete-icon"
                    data-icon="octicon-trashcan"
                    data-inline="false"
                  ></span>
                  <span>Ta bort</span>
                </button>
              )}
              <button
                type="button"
                className="submit-button col-sm-2 col-xs-2 col-md-4"
                onClick={evt => handleSubmit(evt)}
                disabled={isDisabled}
              >
                Spara
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EventForm;
