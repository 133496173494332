import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actions/authActions';
import './Reko.css';
import mWorksLogo from '../../assets/navbarLarge/mWorks/slice@3x.png';
import ManageClients from './manageClients/ManageClients';
import AddClient from './manageClients/addClient/AddClient';

const UserData = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const [showMain, setShowMain] = useState(true);
  const [showClients, setShowClients] = useState(false);
  const [showAddClients, setShowAddClients] = useState(false);

  const handleLogout = () => {
    return dispatch(logout());
  };

  const showReko = () => {
    setShowMain(true);
    setShowClients(false);
    setShowAddClients(false);
  };
  const showClient = () => {
    setShowMain(false);
    setShowClients(true);
    setShowAddClients(false);
  };
  const showAddClient = () => {
    setShowMain(false);
    setShowClients(false);
    setShowAddClients(true);
  };

  return (
    <div className="rekopage">
      <div className="main-container">
        <div className="top-section">
          <div className="navbar">
            <div>
              {' '}
              <p className="logout-link" onClick={handleLogout}>
                Logga ut
              </p>
            </div>
            <a className="homepage-link" onClick={showReko}>
              Hem
            </a>
          </div>
        </div>
        {!showClients && !showAddClients && showMain && (
          <>
            <div className="middle-section">
              <div className="rekopage-content-container">
                <div className="circle-container">
                  <div className="logo-container">
                    <div className="link-mworks">Mitt mWorks</div>
                    <img src={mWorksLogo} className="logo" alt="" />
                  </div>
                  <br />
                  <br />
                </div>
                <br />
                <div>
                  <h1>Personalsida</h1>
                </div>
              </div>
            </div>
            <div className="bottom-section">
              <div className="main-div">
                <div className="left-div">
                  <a className="pagelink" onClick={showClient}>
                    Hantera Kunder
                  </a>
                </div>
                <div className="right-div">
                  <a className="pagelink" onClick={showAddClient}>
                    Skapa Kund
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {!showMain && !showAddClients && showClients && (
          <>
            <ManageClients />
          </>
        )}
        {!showMain && !showClients && showAddClients && (
          <>
            <AddClient />
          </>
        )}
      </div>
    </div>
  );
};

export default UserData;
