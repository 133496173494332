import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import './MyNetwork.css';
import { updateUser, fetchUser } from '../../store/actions/userActions';

import LabelInfo from '../labelInfo/LabelInfo';
import mWorkslogo from '../../assets/homepage/mworks-logo@3x.png';
import ContactForm from './contactFormComponent/ContactForm';
import ContactCard from './contactCard/ContactCard';
import GridSquare from './gridSquare/GridSquare';
import InfoModal from '../infoModal/InfoModal';
import InfoBox from '../InfoBox/InfoBox';
import useChatbot from '../../hooks/useChatbot';
import ChatWindow from '../chatWindow/ChatWindow';
import useDevice from '../../constants/devices';
import { MY_NETWORK_EVENT } from '../../constants/constants';
import { setLogoutTime } from '../../utils/setLogoutTime';

const MyNetwork = () => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const messages = useSelector(state => state.message);
  const [showResults, setShowResults] = useState(false);
  const [currentContact, setCurrentContact] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [addingContact, setAddingContact] = useState(false);
  const [displayContact, setDisplayContact] = useState(false);
  const [myNetworkInfoClassName, setMyNetworkInfoClassName] =
    useState('my-network-info');
  const contacts = user.network;

  useEffect(() => {
    if (!user.pagesVisited.network) {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      user.chatbotConversations.network.length === 0 &&
      messages.network.length === 0
    ) {
      chatBot.eventQuery(MY_NETWORK_EVENT, user);
    }
  }, []);

  const handleOnIdle = () => {
    const sessions = setLogoutTime(user, getLastActiveTime());
    const tempUser = Object.assign({}, user, { sessions });
    dispatch(updateUser(tempUser));
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const hideForm = () => {
    setIsEditing(false);
    setAddingContact(false);
    setDisplayContact(false);
    setCurrentContact({});
    dispatch(fetchUser());
  };

  const editContact = () => {
    setIsEditing(true);
    setDisplayContact(false);
  };

  const showContact = contact => {
    setCurrentContact(contact);
    setDisplayContact(true);
  };

  const deleteContact = () => {
    const filteredContacts = contacts.filter(
      contact => contact !== currentContact
    );
    const updatedUser = { ...user, network: filteredContacts };

    setDisplayContact(false);

    return dispatch(updateUser(updatedUser));
  };

  const DisplayContactCard = () => {
    return (
      <div className="main-container">
        <ContactCard
          contact={currentContact}
          hideForm={hideForm}
          editContact={editContact}
          deleteContact={deleteContact}
        />
      </div>
    );
  };

  const DisplayContactForm = () => {
    return (
      <div className="main-container">
        <ContactForm
          user={user}
          updateUser={updateUser}
          contact={currentContact}
          isEditing={isEditing}
          hideForm={hideForm}
        />
      </div>
    );
  };

  const CHAT_WINDOW = {
    event: MY_NETWORK_EVENT,
    section: 'network',
    subsection: []
  };

  const ADD_CONTACT_MODAL = {
    title: 'Mitt Nätverk',
    specialBody: [
      'Här skapar du ditt nätverk med personer du vill ska ingå i ditt stöd tillbaka till arbetet. Fundera på vilka kontakter du vill ha (familj, vänner, arbete, vård, försäkringskassan, kontakt på brukarorganisation, osv). Vad kan de hjälpa dig med?',
      'Lägg till en kontakt!'
    ]
  };

  const DisplayGrid = () => {
    const maximumMobileGridDisplayCount = 12;

    if (device === 'small') {
      return (
        <div className="main-container">
          <div className="mynetwork-title-container">
            <LabelInfo
              id={'addContact'}
              title={'Mitt nätverk'}
              defaultInfoClassName="my-network-info"
              setInfoClassName={setMyNetworkInfoClassName}
            />
          </div>
          <div className="top-content">
            <div className="mynetwork-mobile-grid-container">
              {contacts.map((contact, index) =>
                index < maximumMobileGridDisplayCount ? (
                  <GridSquare
                    key={index}
                    contact={contact}
                    showContact={showContact}
                  />
                ) : null
              )}
            </div>
          </div>
          <div className="bottom-content">
            <div className="new-contact-button-container">
              <button
                className="new-contact-button"
                onClick={() => setAddingContact(true)}
              >
                + Lägg till kontakt
              </button>
            </div>
            {/* <ChatWindow
              modalText={CHAT_WINDOW}
              showResults={showResults}
              setShowResults={setShowResults}
            /> */}
          </div>
        </div>
      );
    } else
      return (
        <div className="main-container">
          <div className="mynetwork-title-container">
            <LabelInfo
              id={'addContact'}
              title={'Mitt nätverk'}
              defaultInfoClassName="my-network-info"
              setInfoClassName={setMyNetworkInfoClassName}
            />
          </div>
          <div className="top-content">
            {/* TODO: This grid is an embarrassing PoS code!!! Re-write ASAP!!!! */}
            <div className="mynetwork__grid-container">
              <div className="grid__top-row">
                <GridSquare contact={contacts[9]} showContact={showContact} />
                <GridSquare contact={contacts[8]} showContact={showContact} />
                <GridSquare contact={contacts[7]} showContact={showContact} />
                <GridSquare contact={contacts[6]} showContact={showContact} />
              </div>
              <div className="grid__middle-row">
                <div className="left-column ">
                  <GridSquare
                    contact={contacts[10]}
                    showContact={showContact}
                  />
                  <GridSquare
                    contact={contacts[11]}
                    showContact={showContact}
                  />
                </div>
                <div className="middle-column ">
                  <div className="grid-square me-square">Jag</div>
                </div>
                <div className="right-column ">
                  <GridSquare contact={contacts[5]} showContact={showContact} />
                  <GridSquare contact={contacts[4]} showContact={showContact} />
                </div>
              </div>
              <div className="grid__bottom-row">
                <GridSquare contact={contacts[0]} showContact={showContact} />
                <GridSquare contact={contacts[1]} showContact={showContact} />
                <GridSquare contact={contacts[2]} showContact={showContact} />
                <GridSquare contact={contacts[3]} showContact={showContact} />
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <div className="new-contact-button-container">
              <button
                className="new-contact-button"
                onClick={() => setAddingContact(true)}
              >
                + Lägg till kontakt
              </button>
              {/* <div>
              <span
                className="iconify info-icon "
                data-icon="ic-baseline-info"
                data-inline="false"
                data-toggle="modal"
                data-target="#addContactModal"
              ></span>
            </div> */}
            </div>
          </div>
        </div>
      );
  };

  return (
    <div className="mynetwork">
      <div className="background-container"></div>
      {(isEditing || addingContact) && <DisplayContactForm />}
      {displayContact && <DisplayContactCard />}
      {!isEditing && !addingContact && !displayContact && <DisplayGrid />}
      <InfoBox
        modalText={ADD_CONTACT_MODAL}
        className={myNetworkInfoClassName}
        id="my-network-info"
        defaultInfoClassName="my-network-info"
        setInfoClassName={setMyNetworkInfoClassName}
      />
      <ChatWindow
        modalText={CHAT_WINDOW}
        showResults={showResults}
        setShowResults={setShowResults}
        user={user}
        page={user.pagesVisited.network}
        pageName="network"
      />
    </div>
  );
};

export default MyNetwork;
