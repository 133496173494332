import React from 'react';
import WordCloud from '../wordCloud/WordCloud.jsx';

import './CloudModal.css';

// CloudModal is the modal that includ the header, body and footer , offcourse body is another component called WordCloud

const CloudModal = ({ id, myStrengthsList, setMyStrengthsList, modalText }) => {
  return (
    <div className="modal fade position" tabIndex="-1" role="dialog" id={id}>
      <div className="cloud-modal modal-dialog " role="document">
        <div className="modal-content">
          <div className="modal-body">
            <WordCloud
              data-toggle="modal"
              className="form-control textarea"
              myStrengthsList={myStrengthsList}
              setMyStrengthsList={setMyStrengthsList}
              modalText={modalText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudModal;
