export const QUALITIES = [
  "Accepterande",
  "Aktsam",
  "Alert",
  "Allvarlig",
  "Ambitiös",
  "Anpassningsbar",
  "Ansvarsfull",
  "Arbetsam",
  "Artig",
  "Banbrytande",
  "Behärskad",
  "Beskyddande",
  "Bestämd",
  "Detaljerad",
  "Diplomatisk",
  "Disciplinerad",
  "Diskret",
  "Drivande",
  "Dynamisk",
  "Effektiv",
  "Eftertänksam",
  "Ekonomisk",
  "Empatisk",
  "Energisk",
  "Engagerad",
  "Entusiastisk",
  "Entusiasmerande",
  "Envis",
  "Erfaren",
  "Exceptionell",
  "Expert",
  "Fantasifull",
  "Fingerfärdig",
  "Flexibel",
  "Flitig",
  "Formell",
  "Fysisk",
  "Följsam",
  "Förnuftig",
  "Försiktig",
  "Förstående",
  "Förtroendeingivande",
  "Genomtänkt",
  "Generös",
  "Genuin",
  "Glad",
  "Grundlig",
  "Handlingskraftig",
  "Hjälpsam",
  "Human",
  "Humoristisk",
  "Hård",
  "Händig",
  "Idérik",
  "Ihärdig",
  "Impulsiv",
  "Inkluderande",
  "Initiativtagande",
  "Innovativ",
  "Insiktsfull",
  "Inspirerande",
  "Introvert",
  "Karismatisk",
  "Kaxig",
  "Klarsynt",
  "Klok",
  "Kompetent",
  "Konsekvent",
  "Koncentrerad",
  "Kreativ",
  "Kunnig",
  "Kvalitetsmedveten",
  "Kvicktänkt",
  "Känslig",
  "Logisk",
  "Lojal",
  "Lugn",
  "Lyhörd",
  "Lättlärd",
  "Lättsam",
  "Lösningsfokuserad",
  "Medmänsklig",
  "Metodisk",
  "Miljömedveten",
  "Mjuk",
  "Modig",
  "Moralisk",
  "Motiverad",
  "Mottaglig",
  "Musikalisk",
  "Målinriktad",
  "Mångsidig",
  "Naturlig",
  "Noggrann",
  "Nyfiken",
  "Obeveklig",
  "Objektiv",
  "Observant",
  "Omsorgsfull",
  "Omtänksam",
  "Ordentlig",
  "Ordningssam",
  "Organiserad",
  "Orädd",
  "Otålig",
  "Ovanlig",
  "Pedagogisk",
  "Pigg",
  "Positiv",
  "Praktisk",
  "Pratsam",
  "Prestigelös",
  "Proffsig",
  "Punktlig",
  "Pålitlig",
  "Rationell",
  "Realistisk",
  "Resultatinriktad",
  "Rolig",
  "Rytmisk",
  "Rörlig",
  "Saklig",
  "Samarbetsvillig",
  "Serviceinriktad",
  "Självgående",
  "Självständig",
  "Självsäker",
  "Skarp",
  "Skärpt",
  "Smart",
  "Snabb",
  "Snabbtänkt",
  "Snäll",
  "Social",
  "Spontan",
  "Språkbegåvad",
  "Stark",
  "Storsint",
  "Strategisk",
  "Strukturerad",
  "Stresstålig",
  "Stödjande/Stöttande",
  "Systematisk",
  "Taktisk",
  "Teatralisk",
  "Tillmötesgående",
  "Tolerant",
  "Trevlig",
  "Trovärdig",
  "Tuff",
  "Tyst",
  "Tålmodig",
  "Unik",
  "Underfundig",
  "Uppfinningsrik",
  "Uppmärksam",
  "Uppriktig",
  "Uppskattande",
  "Uthållig",
  "Utåtriktad",
  "Vaksam",
  "Vaken",
  "Varsam",
  "Vältränad",
  "Vänlig",
  "Världsvan",
  "Äkta",
  "Ärlig",
  "Äventyrlig",
  "Ödmjuk",
  "Ömsint",
  "Öppen",
  "Övertydlig"
]

export const SKILLS = [
  "Administrera",
  "Analysera",
  "Argumentera",
  "Arkivera",
  "Arrangera",
  "Bedöma",
  "Beräkna",
  "Bestämma",
  "Budgetera",
  "Bygga",
  "Coacha",
  "Debattera",
  "Dekorera",
  "Deligera",
  "Designa",
  "Driva, processer",
  "Engagera",
  "Fakturera",
  "Fatta, beslut",
  "Formulera",
  "Formulera, mål",
  "Följa upp",
  "Föreläsa",
  "Förhandla",
  "Förklara",
  "Förstå, andra",
  "Ge återkoppling",
  "Göra urval",
  "Handleda",
  "Hjälpa andra",
  "Illustrera",
  "Improvisera",
  "Informera",
  "Inspirera",
  "Installera",
  "Instruera",
  "Intervjua",
  "Klassificera",
  "Knyta nya kontakter",
  "Kommunicera",
  "Komponera",
  "Kompromissa",
  "Konstruera",
  "Kontrollera",
  "Koordinera",
  "Kritiskt granska",
  "Leda",
  "Lyfta problem och konflikter",
  "Lyssna på andra",
  "Lära ut",
  "Läsa dokument",
  "Lösa problem",
  "Media",
  "Medla",
  "Moderera",
  "Montera",
  "Motivera",
  "Måla",
  "Nätverka",
  "Omarbeta",
  "Ordna",
  "Organisera",
  "Planera",
  "Prioritera",
  "Processa",
  "Producera",
  "Programmera",
  "Publicera",
  "Redovisa/granska",
  "Registrera",
  "Rekrytera",
  "Reparera",
  "Resonera",
  "Rita",
  "Rådge",
  "Samarbeta",
  "Samordna",
  "Samtala",
  "Se helheter",
  "Skapa",
  "Skapa bilder",
  "Skapa dialog",
  "Skapa idéer",
  "Skapa modeller",
  "Skriva",
  "Slutföra",
  "Sortera",
  "Spara",
  "Strukturera",
  "Styra",
  "Ställa de rätta frågorna",
  "Summera",
  "Sy",
  "Sälja",
  "Söka information",
  "Ta initiativet",
  "Tillverka",
  "Tydliggöra",
  "Tänka ut",
  "Underhålla",
  "Undervisa",
  "Uppfinna",
  "Uppmuntra",
  "Uppträda inför andra",
  "Utbilda",
  "Utforma",
  "Utveckla",
  "Visa empati",
  "Visualisera",
  "Vårda",
  "Vägleda",
  "Övertala"
]