import React from 'react';
import './NewStrategies.css';

const NewStrategies = () => {
  return (
    <div className="new-strategies">
      <h5>Strategier</h5>
      <h6>Generella</h6>
      <ul>
        <li>Äta regelbundet.</li>
        <li>Sova bättre.</li>
        <li>
          Röra på kroppen (ex. promenad runt arbetsplatsen, skogspromenad,
          styrketräning, simma).
        </li>
        <li>Umgås med andra som ger energi.</li>
        <li>
          Sätta gränser för vad man vill och orkar (ex. tacka nej till sådant du
          vill men inte orkar genomföra).
        </li>
        <li>Hantera mående och stress.</li>
        <li>Ringa anhörig/vän när jag mår dåligt</li>
        <li>Söka stöd i mitt sociala nätverk</li>
        <li>Avslappning, mindfulness, yoga eller liknande</li>
      </ul>
      <h6>På arbetet</h6>
      <h6 style={{ fontWeight: 'normal' }}>Arbetsmiljö</h6>
      <ul>
        <li>
          Klara av miljöer med för mycket intryck (ex. använda hörlurar eller
          headset från mobiltelefon, öronproppar, sätt dig avskilt, använd
          skärm).
        </li>
        <li>Hitta en plats där du kan koppla av en liten stund.</li>
        <li>
          Berätta för andra hur man mår, beskriv arbetsprofil och det stöd som
          behövs.
        </li>
        <li>Föreslå regelbunden dialog med arbetsgivare.</li>
      </ul>
      <h6 style={{ fontWeight: 'normal' }}>Arbetsupggifter</h6>
      <ul>
        <li>
          Reda ut vilka arbetsuppgifter som är ditt ansvar och vilka som är mest
          nödvändiga. Be gärna arbetsgivaren om hjälp och diskutera med hen.
        </li>
        <li>
          Planera dina arbetsuppgifter så att det finns en variation som passar
          dig.
        </li>
        <li>
          Vara öppen för lösningar så att arbetsuppgifterna kan passa dig
          bättre. Kan de utföras i en annan miljö, på ett annat sätt, eller
          kortare eller längre stunder?
        </li>
        <li>
          Prioritera dina arbetsuppgifter tillsammans med din arbetsgivare
        </li>
      </ul>
      <h6 style={{ fontWeight: 'normal' }}>Fördelning av aktivitet och paus</h6>
      <ul>
        <li>
          Hitta balans mellan aktivitet och pauser på jobbet (ex. för en
          tidsdagbok och överblicka det du gör, var och med vem du vistas och
          hur du upplever arbetsdagen. Kan din dag se annorlunda ut?
        </li>
        <li>
          Prova ut vilka mikropauser som fungerar, ex. promenad, mindfulness,
          yogaövning, lyssna på musik.
        </li>
        <li>
          Planera för vila, pauser och mikropauser, spärra tid i kalendern för
          mikropauser.
        </li>
        <li>
          Använda mikropauser spontant för att stå ut med ångest, starka känslor
          och stress. Använd de du provat ut, ex. utifrån mindfulness- Stanna
          upp och konstatera att det är jobbigt, följ dina andetag och låt
          tankar och känslor komma och gå, uppmärksamma din kropp och din
          närmiljö. Försök acceptera dina upplevelser. Sök på 1177 Vårdguiden
          och mindfulness och inspireras.
        </li>
      </ul>
      <h6>För helheten</h6>
      <ul>
        <li>
          Hitta balans mellan arbetsliv och privatliv (ex. för en tidsdagbok och
          planera in sådant som ger energi, glädje och återhämtning).
        </li>
        <li>
          Lägga in en rutin för planering av din dag (ex. morgon och/eller
          kväll).
        </li>
        <li>Prioritera det viktigaste ifall du inte hinner allt du vill.</li>
        <li>
          Ta bort inplanerade aktiviteter som tar för mycket energi och kraft
          och kan förvärra ditt mående.
        </li>
        <li>
          Använda ”att göra lista” i mWorks, påminnelser och timer i din
          mobiltelefon osv.
        </li>
        <li>
          Prata med andra på jobbet och hemma om hur och vad du kan planera, vad
          som är viktigast för dig.
        </li>
        <li>
          Lägga in aktiviteter på fritiden som ger glädje och energi och som du
          orkar genomföra.
        </li>
        <li>
          Minska på sysslorna hemma och planera ex. städning och gör en plats i
          taget och sprid ut över veckan.
        </li>
        <li>
          Hitta andra som kan och vill hjälpa till, fördela rollerna i hushållet
          om du bor med någon.
        </li>
        <li>Använda dig av ditt nätverk vid behov</li>
        <li>Odla dina goda relationer</li>
        <li>Lägg in egentid där dina behov står i centrum</li>
      </ul>
    </div>
  );
};

export default NewStrategies;
