import * as React from "react";

import './CustomArrows.css';

const CustomLeftArrow = () => {
  return (
    <button className="custom-left-arrow custom-arrow">
      Föregående
    </button>
  )
};
const CustomRightArrow = () => {
  return (
    <button className="custom-right-arrow custom-arrow">
      Nästa
    </button>
  )
};

export { CustomLeftArrow, CustomRightArrow };