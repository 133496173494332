import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  IS_LOGGED_IN,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_FAIL_USER_EXISTS,
  TOKEN_VERIFICATION_SUCCESS,
  TOKEN_VERIFICATION_FAIL
} from '../actions/types';
import { toast } from 'react-toastify';

const initialState = {
  isLoggedIn: false
};

const toastConfig = {
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true
      };
    case LOGIN_FAIL:
      toast.error('Felaktig email eller lösenord!', toastConfig);
      return {
        ...state,
        isLoggedIn: false
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true
      };
    case REGISTER_FAIL_USER_EXISTS:
      toast.error(action.payload, toastConfig);
      return {
        ...state,
        isLoggedIn: false
      };
    case REGISTER_FAIL:
      toast.error(`Något gick fel med registreringen!`, toastConfig);
      return {
        ...state,
        isLoggedIn: false
      };
    case IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false
      };
    case TOKEN_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: false
      };
    case TOKEN_VERIFICATION_FAIL:
      toast.error(`Token är ogiltig!`, toastConfig);
      return {
        ...state,
        isLoggedIn: false
      };
    default:
      return state;
  }
};

export default authReducer;
