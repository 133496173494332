import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import './MyWellbeing.css';
import { updateUser } from '../../store/actions/userActions';

import infoIcon from '../../assets/information.svg';
import mWorkslogo from '../../assets/homepage/mworks-logo@3x.png';
import Section from '../section/Section';
import TellothersSection from './tellothers/TellothersSection';
import InfoBox from '../InfoBox/InfoBox';
import InfoBoxButton from '../InfoBox/InfoBoxButton/InfoBoxButton';
import InfoModal from '../infoModal/InfoModal';
import useChatbot from '../../hooks/useChatbot';
import ChatWindow from '../chatWindow/ChatWindow';
import useDevice from '../../constants/devices';
import { MY_WELLBEING_EVENT } from '../../constants/constants';
import { setLogoutTime } from '../../utils/setLogoutTime';

const MyWellbeing = ({ pageWidth }) => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const messages = useSelector(state => state.message);
  const [showResults, setShowResults] = useState(false);
  const [feelings, setFeelings] = useState(
    user.wellBeing ? user.wellBeing.feelings : ''
  );
  const [feelingsInfoClassName, setFeelingsInfoClassName] =
    useState('feelings-info');
  const [tellOthersInfoClassName, setTellOthersInfoClassName] =
    useState('tell-others-info');
  const [videoMaxInfoClassName, setVideoMaxInfoClassName] =
    useState('video-max-info');
  const [videoSaraInfoClassName, setVideoSaraInfoClassName] =
    useState('video-sara-info');

  useEffect(() => {
    if (!user.pagesVisited.wellBeing) {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      user.chatbotConversations.wellBeing.length === 0 &&
      messages.wellBeing.length === 0
    ) {
      chatBot.eventQuery(MY_WELLBEING_EVENT, user);
    }
  }, []);

  useEffect(() => {
    setFeelings(user.wellBeing?.feelings || '');
  }, [user]);

  const handleOnIdle = () => {
    const sessions = setLogoutTime(user, getLastActiveTime());
    const tempUser = Object.assign({}, user, { sessions });
    dispatch(updateUser(tempUser));
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const subSections = ['feelings'];
  const CHAT_WINDOW = {
    event: MY_WELLBEING_EVENT,
    section: 'wellBeing',
    subsection: subSections
  };

  /* INFO_TEXT_MODALS */
  const MY_THOUGHTS_FEELINGS_BEHAVIOR_MODAL = {
    title: 'Lär känna dina känslor, tankar och reaktioner.',
    body: '',
    specialBody: [
      'Det är viktigt att känna igen situationer på jobbet eller i vardagen som gör att du mår dåligt. Då blir det lättare att stanna upp och hindra att det blir värre.',
      <>
        Fundera över olika situationer då du mår sämre. Tänk på en jobbig
        situation och svara på frågorna. Gör sedan på samma sätt med fler
        jobbiga situationer.
      </>,
      <>Svara på frågorna Situation, Kände, Tänkte, Gjorde, Annorlunda:</>,
      <>
        S. Beskriv en sådan situation. Vad utlöste den, när och var hände den?
      </>,
      <>K. Vad kände du? Var känslan mer eller mindre stark, hur kändes den?</>,
      <>T. Vad tänkte du när det hände?</>,
      <>G. Hur gjorde du eller vad sa du?</>,
      <>
        A. Var det du gjorde eller sa en fördel för dig? Kunde du gjort eller
        sagt något annorlunda?
      </>
    ],
    placeholder:
      'Exempel: \nS.   Lunchen på jobbet, alltid fullt med folk jag inte känner \nK.   Känner stress i hela kroppen, det känns rätt mycket och får ibland panik \nT.   Jag tänker att jag inte hinner, vet inte vem jag ska prata med, att jag \nB.  Oftast så undviker jag personalrummet och äter en banan på kontoret \nA.   Slipper ju det jobbiga, så det är bra. Men det blir att jag hamnar utanför, så jag kunde frågat någon innan på dagen kanske. Kanske ska berätta för Sam hur jag mår.'
  };

  const INFO_VIDEO_MAX = {
    title: 'Film om Max',
    body: 'Träffa Max som har egen erfarenhet av psykisk ohälsa. Varje persons berättelse är unik, precis som din. Att dela sin berättelse kan hjälpa andra att förstå och ge bättre stöd. ',
    specialBody: []
  };

  const INFO_VIDEO_SARA = {
    title: 'Film om Sara',
    body: 'Träffa Sara som har egen erfarenhet av psykisk ohälsa. Varje persons berättelse är unik, precis som din. Att dela sin berättelse kan hjälpa andra att förstå och ge bättre stöd. ',
    specialBody: []
  };

  const TELL_OTHERS = {
    title: 'Berätta för andra hur du mår?',
    body: '',
    specialBody: [
      'Att vara öppen med sin psykiska hälsa kan öka förståelsen hos andra och bidra till bättre stöd. Samtidigt kan det vara svårt att berätta och många kanske känner sig osäkra på hur andra reagerar. Därför har vi gjort några frågor som hjälper dig fatta beslut och gör dig tryggare i mötet med andra. Diskutera gärna med någon under tiden du skriver och funderar.'
    ]
  };
  /* INFO_TEXT_MODALS END */

  const updateUserHandler = () => {
    const wellBeing = {
      feelings
    };
    const tempUser = Object.assign({}, user, { wellBeing });
    dispatch(updateUser(tempUser));
  };

  return (
    <div className="mywellbeing">
      <div className="background-container"></div>
      <div className="main-container">
        <div className="left-container">
          <InfoBox
            className={videoMaxInfoClassName}
            modalText={INFO_VIDEO_MAX}
          />
          <div className="left-top-container">
            <div className="left-top-container-header">
              <label htmlFor="#kaltura_player" className="video-title">
                Film om Max&nbsp;
              </label>
              <InfoBoxButton
                infoClassName={videoMaxInfoClassName}
                setInfoClassName={setVideoMaxInfoClassName}
                defaultInfoClassName="video-max-info"
              />
            </div>
            <iframe
              id="kaltura_player"
              src="https://lu.instructuremedia.com/embed/356c4c8d-0f9c-4e40-b18b-6d648b119456"
              allowFullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allow="autoplay *; fullscreen *; encrypted-media *"
              frameBorder="0"
              title="Kaltura Player"
            ></iframe>
          </div>
          <InfoBox
            className={videoSaraInfoClassName}
            modalText={INFO_VIDEO_SARA}
          />
          <div className="left-middle-container">
            <div className="left-middle-container-header">
              <label htmlFor="#kaltura_player" className="video-title">
                Film om Sara&nbsp;
              </label>
              <InfoBoxButton
                infoClassName={videoSaraInfoClassName}
                setInfoClassName={setVideoSaraInfoClassName}
                defaultInfoClassName="video-sara-info"
              />
            </div>

            <iframe
              id="kaltura_player"
              src="https://lu.instructuremedia.com/embed/3dd6ec2b-463e-48d7-a044-9873e82eeb72"
              allowFullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allow="autoplay *; fullscreen *; encrypted-media *"
              frameBorder="0"
              title="Kaltura Player"
            ></iframe>
          </div>
          <InfoBox
            className={feelingsInfoClassName}
            modalText={MY_THOUGHTS_FEELINGS_BEHAVIOR_MODAL}
          />
          <div className="left-bottom-container">
            <Section
              id="myFeelings"
              value={feelings}
              onChange={evt => {
                setFeelings(evt.target.value);
              }}
              onBlur={updateUserHandler}
              title="Mina känslor"
              updateUserHandler={updateUserHandler}
              placeholder={MY_THOUGHTS_FEELINGS_BEHAVIOR_MODAL.placeholder}
              pageWidth={pageWidth}
              rows={11}
              defaultInfoClassName="feelings-info"
              setInfoClassName={setFeelingsInfoClassName}
            />
          </div>
        </div>

        <div className="right-container">
          <InfoBox
            className={tellOthersInfoClassName}
            modalText={TELL_OTHERS}
          />
          <div className="right-container-inner">
            <TellothersSection
              id="tellOthers"
              title="Att berätta för andra"
              pageWidth={pageWidth}
              defaultInfoClassName="tell-others-info"
              setInfoClassName={setTellOthersInfoClassName}
            />
          </div>
        </div>
      </div>
      <InfoModal
        modalText={MY_THOUGHTS_FEELINGS_BEHAVIOR_MODAL}
        id="myFeelings"
      />
      <InfoModal modalText={INFO_VIDEO_MAX} id="infoVideoMax" />
      <InfoModal modalText={INFO_VIDEO_SARA} id="infoVideoSara" />
      <InfoModal modalText={TELL_OTHERS} id="tellOthers" />
      <ChatWindow
        modalText={CHAT_WINDOW}
        showResults={showResults}
        setShowResults={setShowResults}
        user={user}
        page={user.pagesVisited.wellBeing}
        pageName="wellBeing"
      />
    </div>
  );
};

export default MyWellbeing;
