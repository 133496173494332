import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ManageRekos.css';
import mWorksLogo from '../../../assets/navbarLarge/mWorks/slice@3x.png';
import {
  fetchRekos,
  deleteReko,
  fetchRekoById
} from '../../../store/actions/adminActions';
import AddReko from './addReko/AddReko';
import ChangeRekoPassword from './changeRekoPassword/ChangeRekoPassword';

const ManageRekos = () => {
  const dispatch = useDispatch();
  const rekos = useSelector(state => state.admin.rekos);
  const [showManageRekos, setShowManageRekos] = useState(true);
  const [showAddRekos, setShowAddRekos] = useState(false);
  const [showChangeRekosPassword, setShowChangeRekosPassword] = useState('');

  const initData = async () => {
    await dispatch(fetchRekos());
  };
  useEffect(() => {
    initData();
  }, []);

  const handleDelete = async (evt, rekoId) => {
    evt.preventDefault();

    await dispatch(deleteReko(rekoId));
    return dispatch(fetchRekos());
  };

  const showAddReko = () => {
    setShowAddRekos(true);
    setShowManageRekos(false);
    setShowChangeRekosPassword('');
  };
  const showChangeRekoPassword = async (evt, rekoId) => {
    evt.preventDefault();
    setShowAddRekos(false);
    setShowManageRekos(false);
    if (evt) {
      console.log('rekoId', rekoId);
      await dispatch(fetchRekoById(rekoId));
      return setShowChangeRekosPassword(rekoId);
    }
  };

  return (
    <>
      {!showAddRekos && !showChangeRekosPassword && showManageRekos && (
        <>
          <div>
            <div className="managerekos-content-container">
              <div className="circle-container">
                <div className="logo-container">
                  <div>Mitt mWorks</div>
                  <img src={mWorksLogo} className="logo" alt="" />
                </div>
                <br />
                <br />
              </div>
              <div>
                <h1>Hantera Personal</h1>
              </div>
              <br />
            </div>
          </div>
          <div className="managerekos-bottom-section">
            <div className="data-container">
              <div className="managerekos-center-container">
                <div>
                  <p
                    className="managerekos-homepage-link add-reko"
                    onClick={showAddReko}
                  >
                    Lägg till personal
                  </p>
                  <div>
                    <div className="managerekos-heading-text">Personal</div>
                    <div className="managerekos-heading-text">Email</div>
                    <div className="managerekos-heading-text managerekos-action-heading">
                      Insatser
                    </div>
                  </div>
                  {rekos &&
                    rekos.map(reko => (
                      <div key={reko._id}>
                        <div className="managerekos-column-text">
                          {reko.firstName + ' ' + reko.lastName}
                        </div>
                        <div className="managerekos-column-text">
                          {reko.email}
                        </div>
                        <div className="managerekos-column-text managerekos-actions">
                          <strong>
                            <p
                              className="managerekos-action-text"
                              onClick={evt =>
                                showChangeRekoPassword(evt, reko._id)
                              }
                            >
                              Uppdatera lösenordet
                            </p>
                            <p
                              className="managerekos-action-del"
                              onClick={evt =>
                                window.confirm(
                                  'Om du tar bort denna REKO raderas också alla klienter som lagts till av denna REKO. Är du säker på att du vill ta bort den här REKO?'
                                ) && handleDelete(evt, reko._id)
                              }
                            >
                              Radera
                            </p>
                          </strong>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!showManageRekos && !showChangeRekosPassword && showAddRekos && (
        <>
          <AddReko />
        </>
      )}
      {!showManageRekos && !showAddRekos && showChangeRekosPassword && (
        <>
          <ChangeRekoPassword rekoId={showChangeRekosPassword} />
        </>
      )}
    </>
  );
};

export default ManageRekos;
