import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL
} from './types';
import { logout } from './authActions';
import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true
};

export const updateUser = currentUser => async (dispatch, getState) => {
  const chatbotConversations = getState().message;
  const updatedChatbotConversations = Object.assign(
    {},
    currentUser['chatbotConversations'],
    chatbotConversations
  );
  const tempUser = Object.assign({}, currentUser, {
    chatbotConversations: updatedChatbotConversations
  });

  const result = await fetch(`/api/user`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true
    },
    credentials: 'include',
    body: JSON.stringify(tempUser)
  });

  if (result.status !== 200) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload:
        'Oops, något gick fel. Du kommer att omdirigeras till inloggningssidan om 5 sekunder'
    });

    setTimeout(function () {
      return dispatch(logout());
    }, 5000);
  }

  const data = await result.json();

  return dispatch({
    type: UPDATE_USER_SUCCESS,
    payload: data.user
  });
};

export const fetchUser = () => async dispatch => {
  const result = await fetch(`/api/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: FETCH_USER_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }

  const data = await result.json();

  return dispatch({
    type: FETCH_USER_SUCCESS,
    payload: data
  });
};

export const fetchUserByEmail = email => async dispatch => {
  const result = await fetch(`/api/user/fetch-user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email
    })
  });

  const data = await result.json();

  if (result.status !== 200) {
    return dispatch({
      type: FETCH_USER_FAIL,
      payload: data.error
    });
  }

  return dispatch({
    type: FETCH_USER_SUCCESS,
    payload: data.user
  });
};

export const resetPassword = user => async dispatch => {
  const userId = user._id;
  const result = await fetch(`/api/user/reset-client-password/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true
    },
    credentials: 'include',
    body: JSON.stringify(user)
  });

  if (result.status !== 200) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: 'Oops, något gick fel i skicka mail...'
    });
  } else {
    const data = await result.json();
    toast.info(
      'Ett e-postmeddelande med länk för återställning av lösenord har skickats!',
      {
        position: 'top-center'
      }
    );
    return dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data
    });
  }
};
