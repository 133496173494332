import React from 'react';
import './ContactCard.css';
import BinIcon from '../../../assets/trash.svg';

const ContactCard = ({ contact, hideForm, editContact, deleteContact }) => {
  const { name, title, email, phone, description, color } = contact;

  return (
    <div className="card">
      <div className="card-body">
        <div
          className="card-title-icon-container"
          onClick={() => deleteContact()}
          title="Ta bort kontakt"
        >
          <img src={BinIcon} className="delete-icon" alt="trash"></img>
        </div>
        <div className="card-title-container">
          <h5 className="card-title bold name">
            {name?.length > 20 ? name.substring(0, 17) + '...' : name}
          </h5>
        </div>
        <p className="card-text title">{title}</p>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item phone">{phone}</li>
        <li className="list-group-item email">{email}</li>
        <li className="list-group-item note">
          <span className="bold note-title">Egen anteckning:&nbsp;</span>
          {description}
        </li>
      </ul>
      <div className="card-body contact-card__button-container">
        <button
          onClick={() => editContact()}
          className="contact-card__edit-button"
        >
          Redigera
        </button>
        <button
          onClick={() => hideForm()}
          className="bg-danger contact-card__back-button"
        >
          Klar
        </button>
      </div>
    </div>
  );
};

export default ContactCard;
