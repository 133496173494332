import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import './MyPlanning.css';
import { updateUser } from '../../store/actions/userActions';
import LabelInfo from '../labelInfo/LabelInfo';
import MyGoalsSection from './mygoals/MyGoalsSection';
import Section from '../section/Section';
import InfoModal from '../infoModal/InfoModal';
import InfoBox from '../InfoBox/InfoBox';
import InfoBoxButton from '../InfoBox/InfoBoxButton/InfoBoxButton';
import Calendar from '../fullCalendar/Calendar';
import { setLogoutTime } from '../../utils/setLogoutTime';

const MyPlanning = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const [myTodos, setMyTodos] = useState(
    user.planning ? user.planning.todos : ''
  );
  const [myCalendarEvents, setMyCalendarEvents] = useState(
    user.planning &&
      user.planning.calendarEvents &&
      user.planning.calendarEvents.length
      ? user.planning.calendarEvents
      : ''
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [myGoalsInfoClassName, setMyGoalsInfoClassName] =
    useState('my-goals-info');
  const [myTodosInfoClassName, setMyTodosInfoClassName] =
    useState('my-todos-info');
  const [myCalendarInfoClassName, setMyCalendarInfoClassName] =
    useState('my-calendar-info');

  useEffect(() => {
    setMyCalendarEvents(
      user.planning && user.planning.calendarEvents
        ? user.planning.calendarEvents
        : ''
    );
  }, [user]);

  const handleOnIdle = () => {
    const sessions = setLogoutTime(user, getLastActiveTime());
    const tempUser = Object.assign({}, user, { sessions });
    dispatch(updateUser(tempUser));
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  /* INFO_TEXT_MODALS */
  const MY_GOALS_MODAL = {
    title: 'Mina mål',
    body: '',
    specialBody: [
      <>
        Fundera över dina mål med att arbeta.{' '}
        <em>Vilka mål ska leda dig dit du vill?</em> Inspireras av vad du
        skrivit i ”Mitt mWorks” och de <i>5 stegen</i>.
      </>,
      'Lista dina tre övergripande mål.',
      '1) ...',
      '2) ...',
      '3) ...',
      'Lista dina tre mål denna månaden ',
      '1) ...',
      '2) ...',
      '3) ...'
    ]
  };
  const MY_TODOS_MODAL = {
    title: 'Att göra lista',
    body: '',
    specialBody: [
      'Utgå från dina tre mål denna månaden. Bryt ner detta i görbara saker. Vad vill du komma ihåg och vad vill du planera? Vem vill du träffa, vad orkar du göra, och vad behöver fixas innan?',
      'Skriv ner det du kommer på i din ”Att göra lista”'
    ],
    placeholder:
      'Exempel: \n-Lägga in två händelser nästa vecka för att komma igång med tänket i Mina resurser. Mitt första mål denna månaden. \n-Texta Sandra om vi kan fika och preppa lite, prata om mina styrkor. Kul!'
  };
  const MY_CALENDAR_MODAL = {
    title: 'Min kalender',
    body: '',
    specialBody: [
      <>
        Utifrån dina mål denna månaden, planera in de fem viktigaste sakerna i
        kalendern per vecka.{' '}
        <em>Vad skulle du bli gladast för efteråt att du gjort? </em>
        Kom ihåg, att besluta vad du inte ska göra är lika viktigt som vad du
        ska göra. Du ska hinna och orka genomföra det du skriver in.
      </>,
      <>
        Glöm inte reflektera!{' '}
        <em>Vad fungerar bra när du gjort saker. Vad har du lärt dig?</em>{' '}
        Behöver du uppdatera "Mitt mWorks" och i de <i>5 stegen</i>?
      </>
    ]
  };
  /* INFO_TEXT_MODALS END */

  const updateUserHandler = () => {
    const planning = {
      ...user.planning,
      todos: myTodos,
      calendarEvents: myCalendarEvents
    };
    const tempUser = Object.assign({}, user, { planning });
    dispatch(updateUser(tempUser));
  };

  const updateUserCalendar = newCalendar => {
    const tempUser = Object.assign({}, user);
    tempUser.planning.calendarEvents = newCalendar;
    dispatch(updateUser(tempUser));
  };

  const handleOnSubmit = newEvent => {
    let newCalendar = [];
    if (isEditing) {
      newCalendar = myCalendarEvents.map(calEvent => {
        if (calEvent._id === newEvent._id) {
          return newEvent;
        } else {
          return calEvent;
        }
      });
    } else {
      if (myCalendarEvents) {
        newCalendar = [...myCalendarEvents, newEvent];
      } else {
        newCalendar = [newEvent];
      }
    }
    return updateUserCalendar(newCalendar);
  };

  const deleteEvent = id => {
    const filtered = myCalendarEvents.filter(event => event._id !== id);
    return updateUserCalendar(filtered);
  };

  // TODO: Edit function...
  const setEditing = bool => {
    setIsEditing(bool);
  };

  const setAdding = bool => {
    setIsAdding(bool);
  };

  // TODO: Styling...

  return (
    <>
      <div className="myplanning">
        <div className="background-container"></div>
        <div className="main-container">
          <div className="left-container">
            <InfoBox
              className={myGoalsInfoClassName}
              modalText={MY_GOALS_MODAL}
            />
            <div className="left-top-container">
              <MyGoalsSection
                id="myGoals"
                title="Mina mål"
                defaultInfoClassName="my-goals-info"
                setInfoClassName={setMyGoalsInfoClassName}
              />
            </div>
            <InfoBox
              className={myTodosInfoClassName}
              modalText={MY_TODOS_MODAL}
            />
            <div className="left-bottom-container">
              <Section
                rows={8}
                id="myTodos"
                value={myTodos}
                onChange={evt => setMyTodos(evt.target.value)}
                onBlur={updateUserHandler}
                title="Att göra lista"
                updateUserHandler={updateUserHandler}
                placeholder={MY_TODOS_MODAL.placeholder}
                defaultInfoClassName="my-todos-info"
                setInfoClassName={setMyTodosInfoClassName}
              />
            </div>
          </div>
          <div className="right-container">
            <InfoBox
              className={myCalendarInfoClassName}
              modalText={MY_CALENDAR_MODAL}
            />
            <div className="right-container-inner">
              <div className="form-group textarea-container">
                <div className="calendar-header">
                  <LabelInfo
                    id="myCalendar"
                    title="Min Kalender"
                    noIcon="true"
                  />
                  <InfoBoxButton
                    infoClassName={myCalendarInfoClassName}
                    setInfoClassName={setMyCalendarInfoClassName}
                    defaultInfoClassName="my-calendar-info"
                  />
                </div>

                <Calendar
                  events={myCalendarEvents}
                  handleOnSubmit={handleOnSubmit}
                  setAdding={setAdding}
                  setEditing={setEditing}
                  isAdding={isAdding}
                  isEditing={isEditing}
                  deleteEvent={deleteEvent}
                />
              </div>
            </div>
          </div>
        </div>
        <InfoModal modalText={MY_GOALS_MODAL} id="myGoals" />
        <InfoModal modalText={MY_TODOS_MODAL} id="myTodos" />
        <InfoModal modalText={MY_CALENDAR_MODAL} id="myCalendar" />
      </div>
    </>
  );
};

export default MyPlanning;
