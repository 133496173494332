import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import updateUserPagesVisited from '../../utils/updateUserPagesVisited';
import './ChatWindow.css';
import useChatbot from '../../hooks/useChatbot';
import mWorkslogo from '../../assets/homepage/mworks-logo@3x.png';
import mWorksChatLogo from '../../assets/group-3.svg';
import { updateUser } from '../../store/actions/userActions';
import { saveMessage } from '../../store/actions/messageActions';
import {
  HOME_EVENT,
  MY_MWORKS_EVENT,
  MY_RESOURCES_EVENT,
  MY_NETWORK_EVENT,
  MY_WELLBEING_EVENT,
  MY_STRATEGIES_EVENT,
  MY_PLANNING_EVENT
} from '../../constants/constants';
import ChatButtonList from './elements/ChatButtonList';
import ChatMessage from './elements/ChatMessage';

const ChatWindow = ({
  modalText,
  userData,
  showResults,
  setShowResults,
  user,
  page,
  pageName,
  setGoalsOverall,
  setGoalsMonthly
}) => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const { event, section, subsection } = modalText;
  const messagesFromRedux = useSelector(state => state.message);
  const [userInput, setUserInput] = useState('');
  const [showDots, setShowDots] = useState(false);
  const [messageList, setMessageList] = useState('');
  const chatMessagesEndRef = useRef(null);

  const { home, mworks, resources, network, wellBeing, strategies, planning } =
    messagesFromRedux;

  useEffect(() => {
    updateMessageList();
  }, [messagesFromRedux]);

  const updateMessageList = () => {
    const messages = chatMessages();
    return setMessageList(messages);
  };

  const chatMessages = () => {
    switch (event) {
      case HOME_EVENT:
        return home;
      case MY_MWORKS_EVENT:
        return mworks;
      case MY_RESOURCES_EVENT:
        return resources;
      case MY_NETWORK_EVENT:
        return network;
      case MY_WELLBEING_EVENT:
        return wellBeing;
      case MY_STRATEGIES_EVENT:
        return strategies;
      case MY_PLANNING_EVENT:
        return planning;
      default:
        return;
    }
  };

  const updateEvent = messages => {
    const latestMessage = messages[messages.length - 1];
    return latestMessage.content?.payload?.fields?.event?.stringValue;
  };

  const updateChatConversation = messages => {
    const latestMessage = messages.pop();
    return dispatch(saveMessage(latestMessage));
  };

  useEffect(() => {
    // TODO: Not an appropriate solution to re-set output on each update
    const output = document.querySelector('#chat-output');
    if (!output) return;
    output.scrollTop = output.scrollHeight;
  }, [messageList, showDots]);

  useEffect(() => {
    setShowDots(true);
    const timer = setTimeout(() => {
      messageList.length > 1 && updateMessageList();
      setShowDots(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
      setShowDots(false);
    };
  }, [messageList]);

  const handleSubmit = async evt => {
    evt.preventDefault();

    if (userInput == '') {
      return alert('Du måste skriva något först');
    }

    const latestMessageEvent = updateEvent(messageList);
    updateChatConversation(messageList);

    //we will send request to text query route
    let payload = {
      eventArea: event,
      text: userInput,
      section: section,
      subsection: subsection,
      user,
      eventToInvoke: latestMessageEvent,
      setGoalsOverall,
      setGoalsMonthly
    };

    await chatBot.textQuery(payload);
    return setUserInput('');
  };

  const clickHandler = async nextEventInDialogFlow => {
    //we will send request to text query route
    let payloadEvent =
      nextEventInDialogFlow !== undefined ? nextEventInDialogFlow : event;
    updateChatConversation(messageList);

    const payload = {
      eventArea: event,
      section: section,
      subsection: subsection,
      eventToInvoke: payloadEvent,
      user
    };

    await chatBot.textQuery(payload);

    setUserInput('');
  };

  const toggle = async () => {
    if (showResults && !user.pagesVisited[pageName]) {
      const tempUser = updateUserPagesVisited(user, page, pageName);
      dispatch(updateUser(tempUser));
    }

    await setShowResults(!showResults);
    if (!showResults) chatMessagesEndRef.current.scrollIntoView();
  };

  const renderTextMessage = (
    message,
    index,
    returnedMessagesLength,
    latestMessageID
  ) => {
    return (
      <ChatMessage
        key={index}
        message={message}
        index={index}
        returnedMessagesLength={returnedMessagesLength}
        showDots={showDots}
        latestMessageID={latestMessageID}
      ></ChatMessage>
    );
  };

  const renderElementMessage = (
    message,
    fields,
    index,
    returnedMessagesLength,
    latestMessageID
  ) => {
    if (!fields.Buttons || fields.Buttons.length < 1) return null;

    const buttons = fields.Buttons.listValue.values.map(btnPayload => {
      return {
        text: btnPayload.structValue.fields.text.stringValue,
        event: btnPayload.structValue.fields.event
          ? btnPayload.structValue.fields.event.structValue.fields.name
              .stringValue
          : ''
      };
    });

    return (
      <ChatButtonList
        key={index}
        index={index}
        message={message}
        fields={fields}
        buttons={buttons}
        returnedMessagesLength={returnedMessagesLength}
        clickHandler={clickHandler}
        showDots={showDots}
        latestMessageID={latestMessageID}
      ></ChatButtonList>
    );
  };

  const replaceClientName = message => {
    // Replace the encrypted client name with the normal one
    if (message?.event === 'Welcome') {
      const selectedMessage = message.content.payload.fields.title.stringValue;
      const splittedMessage = selectedMessage.split('!');
      const initialGreeting = splittedMessage[0].split(' ');

      if (initialGreeting[0] === 'Hej') {
        let formattedGreeting = initialGreeting[0] + ' ' + user.firstName + '!';
        formattedGreeting = formattedGreeting.concat(splittedMessage[1]);
        message.content.payload.fields.title.stringValue = formattedGreeting;
      }
    }
  };

  const renderMessage = (message, index, returnedMessages, latestMessageID) => {
    // we need to give some condition here to separate message kinds
    // template for normal text
    if (!message.content) {
      return null;
    }

    replaceClientName(message);

    if (message.content.payload?.fields?.Buttons) {
      return renderElementMessage(
        message,
        message.content.payload.fields,
        index,
        returnedMessages.length,
        latestMessageID
      );
    } else if (message.content.text || message.content.payload?.fields?.title) {
      return renderTextMessage(
        message,
        index,
        returnedMessages.length,
        latestMessageID
      );
    }

    return null;
  };

  const renderMessages = returnedMessages => {
    if (!returnedMessages) {
      return null;
    }

    return returnedMessages.map((message, i) => {
      if (i === returnedMessages.length - 1) {
        return renderMessage(message, i, returnedMessages, 'last-message');
      }
      return renderMessage(message, i, returnedMessages);
    });
  };

  const disableInput = () => {
    const latestMessage = messageList[messageList.length - 1];
    if (
      (latestMessage?.who === 'Mott' &&
        latestMessage?.content?.payload?.fields?.Buttons) ||
      latestMessage?.who !== 'Mott' ||
      latestMessage?.endOfConversation
    ) {
      return true;
    }

    return false;
  };

  const disableInputPlaceholder = () => {
    const latestMessage = messageList[messageList.length - 1];
    if (
      latestMessage?.who === 'Mott' &&
      !latestMessage?.content?.payload?.fields?.Buttons &&
      !latestMessage?.endOfConversation
    ) {
      return 'Skriv här...';
    }

    return null;
  };

  return (
    <div>
      {showResults && (
        <div id="chat-window" className="chat-popup">
          <div className="chat-popup-header">
            <img src={mWorkslogo} className="chat-popup-header-image" />
            <span className="chat-popup-header-text">Mott</span>
            <button onClick={toggle} className="chat-popup-header-close-button">
              Stäng chatt
            </button>
          </div>
          <div id="chat-output" className="chat-popup-message-area">
            {renderMessages(messageList)}
            <div ref={chatMessagesEndRef} />
          </div>
          <form onSubmit={handleSubmit}>
            <input
              disabled={disableInput()}
              autoFocus
              placeholder={disableInputPlaceholder()}
              value={userInput}
              onChange={e => setUserInput(e.target.value)}
              type="text"
            />
            <button
              type="submit"
              className="chat-popup-send-button"
              disabled={disableInput()}
              style={disableInput() ? { display: 'none' } : null}
            ></button>
          </form>
        </div>
      )}
      <img
        src={mWorksChatLogo}
        onClick={toggle}
        className="mworks-chat-logo"
        alt="mworks logo"
      />
    </div>
  );
};

export default ChatWindow;
