import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  IS_LOGGED_IN,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_FAIL_USER_EXISTS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL
} from '../actions/types';
import { toast } from 'react-toastify';

const initialState = {
  user: null,
  userRole: ''
};

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        userRole: payload.role
      };
    case LOGIN_FAIL:
      return {
        ...state,
        user: null,
        userRole: ''
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: payload,
        userRole: payload.role
      };
    case REGISTER_FAIL_USER_EXISTS:
      return {
        ...state,
        user: null,
        userRole: ''
      };
    case REGISTER_FAIL:
      return {
        ...state,
        user: null,
        userRole: ''
      };
    case IS_LOGGED_IN:
      return {
        ...state,
        user: payload,
        userRole: payload.role
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        userRole: ''
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: payload
      };
    case UPDATE_USER_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_USER_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        userRole: payload.role
      };
    default:
      return state;
  }
};

export default userReducer;
