import { useState, useEffect } from 'react';
import throttle from 'lodash/fp/throttle';

const getDevice = () => {
  const { innerWidth: width } = window;
  if (width < 1024) return 'small';
  return 'large';
};

const useDevice = () => {
  const [device, setDevice] = useState(getDevice());

  useEffect(() => {
    const handleResize = throttle(100, () => setDevice(getDevice()));

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return device;
};

export default useDevice;
