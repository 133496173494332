/* ROUTES */
export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_REKO = '/reko';
export const ROUTE_MANAGE_REKOS = '/managerekos';
export const ROUTE_ADD_REKO = '/addreko';
export const ROUTE_CHANGE_REKO_PASSWORD = '/changerekopassword';
export const ROUTE_SESSION_DATA = '/sessiondata';
export const ROUTE_MANAGE_CLIENTS = '/manageclients';
export const ROUTE_ADD_CLIENT = '/addclient';
export const ROUTE_REGISTER = '/register';
export const ROUTE_REQUEST_PASSWORD_RESET = '/requestpasswordreset';
export const ROUTE_RESET_PASSWORD = '/resetpassword';
export const ROUTE_MY_MWORKS = '/mymworks';
export const ROUTE_MY_RESOURCES = '/myresources';
export const ROUTE_MY_STRATEGIES = '/mystrategies';
export const ROUTE_MY_PLANNING = '/myplanning';
export const ROUTE_MY_WELLBEING = '/mywellbeing';
export const ROUTE_MY_NETWORK = '/mynetwork';

/* CHATBOT EVENTS */
export const HOME_EVENT = 'Welcome';
export const MY_MWORKS_EVENT = 'MyMworks';
export const MY_RESOURCES_EVENT = 'MyResources';
export const MY_STRATEGIES_EVENT = 'MyStrategies';
export const MY_PLANNING_EVENT = 'MyPlanning';
export const MY_WELLBEING_EVENT = 'MyWellbeing';
export const MY_NETWORK_EVENT = 'MyNetwork';