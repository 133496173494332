import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './CarouselSwipe.css';
import { Link } from 'react-router-dom';

import Card from '../../cardComponent/Card';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { setBackgroundIndex } from '../../../../store/actions/mainActions';

const CarouselSwipe = ({ INFO_TEXTS, logos }) => {
  const dispatch = useDispatch();
  const {
    resourcesLogo,
    networkLogo,
    wellbeingLogo,
    strategiesLogo,
    planningLogo
  } = logos;

  const {
    RESOURCES_INFO,
    NETWORK_INFO,
    WELLBEING_INFO,
    STRATEGIES_INFO,
    PLANNING_INFO
  } = INFO_TEXTS;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentGoToUrl, setCurrentGoToUrl] = useState('');
  const [currentGoToLinkColor, setCurrentGoToLinkColor] = useState('');

  const imgArray = [
    <Card
      to=""
      number=""
      title="Mina resurser"
      info={RESOURCES_INFO}
      logo={resourcesLogo}
      cardClass="bg-resources"
      data-color="#615122"
      data-href={'/myresources'}
    />,

    <Card
      to=""
      number=""
      title="Mitt nätverk"
      info={NETWORK_INFO}
      logo={networkLogo}
      cardClass="bg-network"
      data-color="#67430b"
      data-href={'/mynetwork'}
    />,

    <Card
      to=""
      number=""
      title="Mitt mående"
      info={WELLBEING_INFO}
      logo={wellbeingLogo}
      cardClass="bg-wellbeing"
      data-color="#32547d"
      data-href={'/mywellbeing'}
    />,

    <Card
      to=""
      number=""
      title="Mina strategier"
      info={STRATEGIES_INFO}
      logo={strategiesLogo}
      cardClass="bg-strategies"
      data-color="#38171d"
      data-href={'/mystrategies'}
    />,

    <Card
      to=""
      number=""
      title="Min planering"
      info={PLANNING_INFO}
      logo={planningLogo}
      cardClass="bg-planning"
      data-color="#364c1c"
      data-href={'/myplanning'}
    />
  ];
  useEffect(() => {
    setCurrentGoToUrl(imgArray[0].props['data-href']);
    setCurrentGoToLinkColor(imgArray[0].props['data-color']);
    // eslint-disable-next-line
  }, []);

  const handleClick = e => {
    const slideIndex = e % 5;
    const linkUrl =
      slideIndex < 0
        ? imgArray[imgArray.length + slideIndex].props['data-href']
        : imgArray[slideIndex].props['data-href'];
    const linkColor =
      slideIndex < 0
        ? imgArray[imgArray.length + slideIndex].props['data-color']
        : imgArray[slideIndex].props['data-color'];
    setCurrentGoToUrl(linkUrl);
    setCurrentGoToLinkColor(linkColor);
    setCurrentIndex(e);
    const index = e;
    return dispatch(setBackgroundIndex(index));
  };

  const getGotoLinkText = () => {
    const slideIndex = currentIndex % imgArray.length;
    return slideIndex < 0
      ? imgArray[imgArray.length + slideIndex].props['title']
      : imgArray[slideIndex].props['title'];
  };

  return (
    <div className="carousel">
      <Carousel
        centered
        infinite
        slidesPerPage={2}
        clickToChange={true}
        draggable={true}
        dots={true}
        onChange={handleClick}
        offset={1}
        value={currentIndex}
        slides={imgArray}
      />

      <div>
        <div className="goToPage">
          <Link to={currentGoToUrl}>
            <button
              className="goToPage-button"
              style={{ color: currentGoToLinkColor }}
            >
              Gå till {getGotoLinkText()}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CarouselSwipe;
