import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../store/actions/authActions';
import { updateUser } from '../../../store/actions/userActions';
import useDevice from '../../../constants/devices';
import './HomePageMobile.css';

import LogoSmall from '../logoSmallComponent/LogoSmall';
import CarouselSwipe from './carousel/CarouselSwipe';

import logo from '../../../assets/homepage/mworks-logo.svg';

import BGImage from '../../../assets/homepage/mountain-2.svg';
import mWorksLogo from '../../../assets/homepage/mobileHomePage/group.png';
import mWorksCloud from '../../../assets/homepage/cloud.svg';
import resourcesLogo from '../../../assets/myResources/card-symbol-resurser.svg';
import networkLogo from '../../../assets/myNetwork/card-symbol-n-tverk.svg';
import wellbeingLogo from '../../../assets/myWellbeing/card-symbol-m-ende.svg';
import strategiesLogo from '../../../assets/navbarLarge/strategies/strategier.svg';
import planningLogo from '../../../assets/myPlanning/card-symbol-planering.svg';
import useChatbot from '../../../hooks/useChatbot';
import ChatWindow from '../../chatWindow/ChatWindow';
import { saveMessage } from '../../../store/actions/messageActions';
import { HOME_EVENT } from '../../../constants/constants';

import DisclaimerModal from '../../disclaimer/modal/DisclaimerModal';

const Cloud_INFO = 'Ett ställe där jag kan skriva och berätta om mig själv';

const HomePageSmall = ({ INFO_TEXTS, logos }) => {
  const dispatch = useDispatch();
  const backgroundIndex = useSelector(state => state.main.backgroundIndex);
  const role = useSelector(state => state.user.userRole);
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const messages = useSelector(state => state.message);
  const [showResults, setShowResults] = useState(false);
  const [hideDisclaimer, setHideDisclaimer] = useState(true);

  const handleLogout = () => {
    return dispatch(logout());
  };

  useEffect(() => {
    if (!user?.hasLoggedInBefore) {
      setHideDisclaimer(false);
    }

    if (!user.pagesVisited.home) {
      setShowResults(true);
    }
  }, []);

  const dismissChatbot = () => {
    if (!user.pagesVisited.home) {
      const pagesVisited = Object.assign({}, user['pagesVisited'], {
        home: true
      });
      const tempUser = Object.assign({}, user, { pagesVisited });
      dispatch(updateUser(tempUser));
    }
  };

  useEffect(() => {
    if (
      user &&
      user.chatbotConversations.home.length === 0 &&
      messages.home.length === 0
    ) {
      chatBot.eventQuery(HOME_EVENT, user);
    }
  }, []);

  const CHAT_WINDOW = {
    event: HOME_EVENT,
    section: 'home',
    subsection: ''
  };

  return (
    <div className="homepage-small">
      <div className="background-container">
        <img src={BGImage} alt="Mitt mWork" />
      </div>

      <div className="main-container">
        <div className="top-section">
          <div className="top-section-left">
            <button
              className="om-mworks"
              // data-toggle="modal"
              // data-target="#readMoreModal"
              onClick={() => setHideDisclaimer(false)}
            >
              <img src={logo} className="mworks_logo" alt="mWork logo" />
            </button>
          </div>
          <div className="top-section-right">
            <div className="admin">
              {/* THIS IS FOR DEVELOPMENT... */}
              {role === 'admin' && (
                <Link
                  to="/admin"
                  style={{
                    textDecoration: 'none',
                    color: 'red',
                    marginRight: '28px'
                  }}
                >
                  Admin
                </Link>
              )}
              <p className="logout-link">Återställ lösenord</p>
              <p className="logout-link" onClick={handleLogout}>
                Logga ut
              </p>
            </div>
          </div>
        </div>
        <div className="logo-section">
          <Link to="/mymworks">
            <img src={mWorksCloud} className="logo" alt="cloud logo" />
            <div className="cloud-info">
              <div className="cloud-title">Mitt mWorks</div>
              <div className="cloud-text">{Cloud_INFO}</div>
            </div>
          </Link>
        </div>
        <div className="middle-section">
          <CarouselSwipe
            logos={logos}
            INFO_TEXTS={INFO_TEXTS}
            currentIndex={backgroundIndex}
          />
        </div>
      </div>
      {!hideDisclaimer && (
        <DisclaimerModal setHideDisclaimer={setHideDisclaimer} />
      )}
      <ChatWindow
        modalText={CHAT_WINDOW}
        showResults={showResults}
        setShowResults={setShowResults}
        user={user}
        page={user.pagesVisited.home}
        pageName="home"
      />
    </div>
  );
};

export default HomePageSmall;
