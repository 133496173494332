import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { setCloudModalData } from '../../../store/actions/cloudActions';
import { updateUser } from '../../../store/actions/userActions';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ColorPicker from '../colorPicker/ColorPicker';
import BinIcon from '../../../assets/trash.svg';
import './WordCloud.css';

// This is the body for the word cloud modal and its save the user strengths list to dispach it to backend as well, this list will be use in wordClouds component in my resourse later .

const WordCloud = ({ myStrengthsList, setMyStrengthsList, modalText }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.cloud);
  const user = useSelector(state => state.user.user);
  const cloudModalData = data.cloudModalData;
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [buttonDisabledState, setButtonDisabledState] = useState(false);

  useEffect(() => {
    setSelectedItem({
      name: cloudModalData.name,
      id: cloudModalData.id || uuidv4(),
      level: cloudModalData.level,
      color: cloudModalData.color,
      listIdentifier: cloudModalData.listIdentifier
    });
  }, [cloudModalData]);

  useEffect(() => {
    setIsUpdate(cloudModalData.edit);
  }, [cloudModalData.edit]);

  useEffect(() => {
    if (selectedItem.name) {
      setButtonDisabledState(false);
    } else {
      setButtonDisabledState(true);
    }
  }, [selectedItem]);

  const updateSubmitHandler = e => {
    e.preventDefault();
    setSelectedItem({ ...selectedItem, name: cloudModalData.name });

    let isUpdate = false;
    let newList = [...myStrengthsList];

    myStrengthsList.map(entry => {
      if (
        entry.id === selectedItem.id &&
        entry.listIdentifier === selectedItem.listIdentifier
      ) {
        isUpdate = true;
      }
    });

    if (isUpdate) {
      newList = newList.map(entry => {
        if (entry.id === selectedItem.id) return selectedItem;
        return entry;
      });
    } else {
      newList.push(selectedItem);
    }

    updateUserStrenghtsList(newList);
  };

  const updateUserStrenghtsList = newList => {
    const resources = {
      workProfile: user.resources.workProfile,
      strengthsList: newList
    };

    const tempUser = Object.assign({}, user, { resources });
    dispatch(updateUser(tempUser));
    setMyStrengthsList(newList);
  };

  const handleDelete = () => {
    const newList = user.resources.strengthsList.filter(current => {
      return current.id !== selectedItem.id;
    });

    updateUserStrenghtsList(newList);
  };

  const selectedColor = color => {
    const updatedItem = { ...selectedItem, color: color };
    setSelectedItem(updatedItem);
  };

  const selectedLevel = level => {
    const updatedItem = { ...selectedItem, level: level };
    setSelectedItem(updatedItem);
  };

  const selectedName = element => {
    const updatedItem = { ...selectedItem, name: element.target.value };
    setSelectedItem(updatedItem);
  };

  const handleCancel = () => {
    dispatch(
      setCloudModalData({
        name: '',
        level: 0,
        color: ''
      })
    );
  };

  const marks = {
    1: 'Bra',
    2: '',
    3: '',
    4: '',
    5: 'Superbra'
  };

  return (
    <div className="modal word-cloud">
      <h5>{modalText.title}</h5>
      <p>{modalText.body}</p>
      <div className="word-cloud-container">
        <form
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          <input
            type="input"
            className="strengthName"
            value={selectedItem.name || ''}
            disabled={
              cloudModalData.listIdentifier !== 'custom' ? 'disabled' : ''
            }
            onChange={selectedName}
          />
          {isUpdate && (
            <img
              src={BinIcon}
              alt="bin"
              className="bin-icon"
              onClick={handleDelete}
              data-dismiss="modal"
            />
          )}
          <div className="form-group color">
            <ColorPicker
              setColor={selectedColor}
              currentColor={selectedItem.color}
            />
          </div>

          <div className="form-group slide">
            <label className="slide-label">Hur bra är jag på detta?</label>
            <Slider
              dots
              min={1}
              max={5}
              marks={marks}
              step={1}
              onChange={selectedLevel}
              value={selectedItem.level}
            />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger exit-modal"
              data-dismiss="modal"
              onClick={handleCancel}
            >
              Avbryt
            </button>

            <button
              type="button"
              className="save save-modal"
              onClick={updateSubmitHandler}
              data-dismiss="modal"
              disabled={buttonDisabledState}
            >
              Spara
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WordCloud;
