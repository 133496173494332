import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import bcrypt from 'bcryptjs';
import './ResetPassword.css';
import { toast } from 'react-toastify';
import {
  checkIfTokenIsValid,
  updateUserPassword
} from '../../store/actions/authActions';
import mWorksLogo from '../../assets/navbarLarge/mWorks/slice@3x.png';
import { ROUTE_LOGIN } from '../../constants/constants';
import moment from 'moment';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [passwordCompare, setPasswordCompare] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (password && passwordCompare) setIsDisabled(false);
    else setIsDisabled(true);
  }, [password, passwordCompare]);

  const handleResetPassword = async evt => {
    evt.preventDefault();

    const url = window.location.href;
    const tokenFromUrl = url.split('/').pop();
    if (password !== passwordCompare) {
      return toast.error('Lösenorden matchar inte!', {
        position: 'top-center'
      });
    }

    const Promise = dispatch(checkIfTokenIsValid(tokenFromUrl));
    let tokenGenTime = '';
    let userId = '';

    if (Promise) {
      await Promise.then(function (result) {
        userId = result.payload._id;
        tokenGenTime = result.payload.tokenGenerationTime;
      }).catch(function (err) {});

      checkTokenTimeValidity(userId, tokenGenTime);
    } else {
      toast.error('Token är felaktighet!', {
        position: 'top-center'
      });
    }
  };

  const checkTokenTimeValidity = async (userId, tokenTime) => {
    const currentTime = moment().format();
    const startTime = moment(tokenTime);
    const endTime = moment(currentTime);

    const durationInMinutes = moment
      .duration(endTime.diff(startTime))
      .asMinutes();

    if (Math.round(durationInMinutes) < 30) {
      dispatch(updateUserPassword(userId, password));
    } else {
      toast.error('Token har gått ut på 30 minuter!', {
        position: 'top-center'
      });
    }
  };

  return (
    <div className="resetpassword ptb-10">
      <div className="resetpassword-content-container">
        <div className="circle-container">
          <div className="logo-container">
            <div>Mitt mWorks</div>
            <img src={mWorksLogo} className="logo" alt="" />
          </div>
        </div>

        <div className="resetpassword-content col-sm-12 col-md-6">
          <p className="resetpassword-container-header-text ptb-10">
            <strong>Återställ lösenord här!</strong>
          </p>
          <div className="resetpassword-form-container">
            <form className="col-sm-12">
              <div className="form-group">
                <label htmlFor="resetpassword-password">
                  Ditt nya lösenord
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="resetpassword-password"
                  name="password"
                  aria-describedby="passwordHelp"
                  onChange={evt => setPassword(evt.target.value)}
                  placeholder="Lösenord..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="resetpassword-password-confirm">
                  Lösenord en gång till
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="resetpassword-password-confirm"
                  name="confirm-password"
                  aria-describedby="confirmPasswordHelp"
                  onChange={evt => setPasswordCompare(evt.target.value)}
                  placeholder="Lösenord en gång till..."
                />
              </div>
              <div className="resetpassword-button-container ptb-10">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleResetPassword}
                  disabled={isDisabled}
                >
                  Återställ lösenord
                </button>
              </div>
              <div className="login ptb-10">
                <Link to={ROUTE_LOGIN}>Tillbaka till inloggningssidan</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
