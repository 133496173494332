import {
  SAVE_HOME_MESSAGE,
  SAVE_MWORKS_MESSAGE,
  SAVE_RESOURCES_MESSAGE,
  SAVE_NETWORK_MESSAGE,
  SAVE_WELLBEING_MESSAGE,
  SAVE_STRATEGY_MESSAGE,
  SAVE_PLANNING_MESSAGE
} from './types';
import {
  HOME_EVENT,
  MY_MWORKS_EVENT,
  MY_RESOURCES_EVENT,
  MY_NETWORK_EVENT,
  MY_WELLBEING_EVENT,
  MY_STRATEGIES_EVENT,
  MY_PLANNING_EVENT
} from '../../constants/constants';

export const saveMessage = dataToSubmit => {
  switch (dataToSubmit.event) {
    case HOME_EVENT:
      return dispatch => {
        return dispatch(homeMessagesAction(dataToSubmit));
      };
    case MY_MWORKS_EVENT:
      return dispatch => {
        return dispatch(myMworksMessagesAction(dataToSubmit));
      };
    case MY_RESOURCES_EVENT:
      return dispatch => {
        return dispatch(myResourcesMessagesAction(dataToSubmit));
      };
    case MY_NETWORK_EVENT:
      return dispatch => {
        return dispatch(myNetworkMessagesAction(dataToSubmit));
      };
    case MY_WELLBEING_EVENT:
      return dispatch => {
        return dispatch(myWellbeingMessagesAction(dataToSubmit));
      };
    case MY_STRATEGIES_EVENT:
      return dispatch => {
        return dispatch(myStrategyMessagesAction(dataToSubmit));
      };
    case MY_PLANNING_EVENT:
      return dispatch => {
        return dispatch(myPlanningMessagesAction(dataToSubmit));
      };
    default:
      return;
  }
};

const homeMessagesAction = dataToSubmit => ({
  type: SAVE_HOME_MESSAGE,
  payload: dataToSubmit
});
const myMworksMessagesAction = dataToSubmit => ({
  type: SAVE_MWORKS_MESSAGE,
  payload: dataToSubmit
});
const myResourcesMessagesAction = dataToSubmit => ({
  type: SAVE_RESOURCES_MESSAGE,
  payload: dataToSubmit
});
const myNetworkMessagesAction = dataToSubmit => ({
  type: SAVE_NETWORK_MESSAGE,
  payload: dataToSubmit
});
const myWellbeingMessagesAction = dataToSubmit => ({
  type: SAVE_WELLBEING_MESSAGE,
  payload: dataToSubmit
});
const myStrategyMessagesAction = dataToSubmit => ({
  type: SAVE_STRATEGY_MESSAGE,
  payload: dataToSubmit
});
const myPlanningMessagesAction = dataToSubmit => ({
  type: SAVE_PLANNING_MESSAGE,
  payload: dataToSubmit
});
