import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './ContactForm.css';
import Colors from '../colorPicker/Colors';

import ColorPicker from '../colorPicker/ColorPicker';

const ContactForm = ({
  user,
  updateUser,
  isEditing = false,
  contact,
  hideForm
}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(isEditing ? contact.name : '');
  const [id, setId] = useState(contact._id);
  const [title, setTitle] = useState(isEditing ? contact.title : '');
  const [phone, setPhone] = useState(isEditing ? contact.phone : '');
  const [email, setEmail] = useState(isEditing ? contact.email : '');
  const [description, setDescription] = useState(
    isEditing ? contact.description : ''
  );
  const [color, setColor] = useState(isEditing ? contact.color : Colors.DEFAULT_COLOR.code);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (name?.length > 0 && title.length > 0) setIsDisabled(false);
    else setIsDisabled(true);
  }, [name, title]);

  // This function does NOT mutate the original array!!!!
  const replaceItemAtIndex = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index + 1)
  ];

  const editContact = (network, contactToEdit) => {
    const indexOfCurrent = network
      .map(item => item._id)
      .indexOf(contactToEdit._id);

    const updatedNetwork = replaceItemAtIndex(
      network,
      indexOfCurrent,
      contactToEdit
    );

    const updatedUser = { ...user, network: updatedNetwork };
    dispatch(updateUser(updatedUser));
  };

  const handleSave = evt => {
    evt.preventDefault();
    const { network } = user;
    const tempContact = {
      name,
      email,
      color,
      description,
      title,
      phone
    };
    if (isEditing) {
      const contactToEdit = Object.assign({}, tempContact, { _id: id });
      editContact(network, contactToEdit);
    } else {
      const updatedUserNetwork = [...network, tempContact];
      const updatedUser = { ...user, network: updatedUserNetwork };
      dispatch(updateUser(updatedUser));
    }

    return hideForm();
  };

  return (
    <div className="contact-form">
      <div className="contact-form__header-container">
        <p className="contact-form__header"></p>
      </div>
      <div className="contact-form__form-container">
        <form className="contact-form__form">
          <div className="row">
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="name">
                Namn
                <small id="nameHelp" className="form-text text-muted">
                  *
                </small>
              </label>
              <input
                value={name}
                type="text"
                onChange={evt => setName(evt.target.value)}
                id="name"
                className="form-control"
              />
            </div>
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="email">E-postadress</label>
              <input
                value={email}
                onChange={evt => setEmail(evt.target.value.trim())}
                type="email"
                id="email"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="title">
                Titel
                <small id="titleHelp" className="form-text text-muted">
                  *
                </small>
              </label>
              <input
                value={title}
                onChange={evt => setTitle(evt.target.value)}
                type="text"
                id="title"
                className="form-control"
              />
              <label htmlFor="phone">Telefonnummer</label>
              <input
                value={phone}
                onChange={evt => setPhone(evt.target.value.trim())}
                type="text"
                id="phone"
                className="form-control"
              />
            </div>
            <div className="form-group col-lg-6 col-sm-12">
              <label htmlFor="description">Egna anteckningar</label>
              <textarea
                value={description}
                onChange={evt => setDescription(evt.target.value)}
                name="description"
                id="description"
                cols="29"
                rows="5"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="email-color-container col-lg-6">
              <ColorPicker setColor={setColor} currentColor={color} />
            </div>
          </div>
          <div className="submit-button-container row">
            <button
              type="button"
              onClick={() => hideForm()}
              className="bg-danger cancel-button"
            >
              Avbryt
            </button>
            <button
              type="submit"
              onClick={evt => handleSave(evt)}
              disabled={isDisabled}
              className="submit-button"
            >
              Spara
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
