import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './UserSessions.css';

const UserSessions = userInfo => {
  const users = useSelector(state => state.admin.users);

  return (
    users && (
      <div className="usersession">
        <div>
          <div className="usersession-heading-text">Starttid</div>
          <div className="usersession-heading-text">Sluttid</div>
          <div className="usersession-heading-text column-width min-text">
            Sessionslängd i minuter
          </div>
        </div>
        {users
          .filter(user => user._id === userInfo.userInfo && user.sessions)
          .map(selectedUser => (
            <div key={selectedUser._id}>
              {selectedUser &&
                selectedUser.sessions.map(session => (
                  <div key={session._id}>
                    <div className="body-text">
                      {!session.loginTime
                        ? '-'
                        : moment(new Date(session.loginTime)).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}
                    </div>
                    <div className="body-text">
                      {!session.logoutTime
                        ? '-'
                        : moment(new Date(session.logoutTime)).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}
                    </div>
                    <div className="body-text column-width">
                      {!session.durationInMinutes
                        ? '-'
                        : Math.round(session.durationInMinutes)}
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    )
  );
};

export default UserSessions;
