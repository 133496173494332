import {
  ADD_CLIENT_EMAIL_SUCCESS,
  ADD_CLIENT_EMAIL_FAIL,
  ADD_CLIENT_EMAIL_FAIL_EMAIL_EXISTS,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL
} from '../actions/types';
import { toast } from 'react-toastify';

const initialState = {
  addClientEmailSuccess: false,
  clients: null
};

const toastConfig = {
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true
};

const rekoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_CLIENT_EMAIL_SUCCESS:
      return {
        ...state,
        addClientEmailSuccess: true
      };
    case ADD_CLIENT_EMAIL_FAIL_EMAIL_EXISTS:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case ADD_CLIENT_EMAIL_FAIL:
      toast.error(`Något gick fel med lägga till emailadressen!`, toastConfig);
      return {
        ...state
      };
    case FETCH_CLIENTS_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: payload
      };
    default:
      return state;
  }
};

export default rekoReducer;
