import {
  SET_BACKGROUND_INDEX,
  SET_EDITING_ACTIVE,
  SET_EDITING_NOT_ACTIVE,
  SET_CLOUD_DATA_LIST
} from '../actions/types';

const initialState = {
  backgroundIndex: 0,
  isEditing: false,
  sectionId: '',
  isActive: false,
  colorId: ''
};

const mainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BACKGROUND_INDEX:
      return {
        ...state,
        backgroundIndex: payload
      };
    case SET_EDITING_ACTIVE:
      return {
        ...state,
        isEditing: true,
        sectionId: payload
      };
    case SET_EDITING_NOT_ACTIVE:
      return {
        ...state,
        isEditing: false,
        sectionId: ''
      };
    case SET_CLOUD_DATA_LIST:
      return {
        ...state,
        cloudDataList: payload
      };
    default:
      return state;
  }
};

export default mainReducer;
