import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReko } from '../../../../store/actions/adminActions';
import './AddReko.css';
import { toast } from 'react-toastify';
import mWorksLogo from '../../../../assets/navbarLarge/mWorks/slice@3x.png';

const AddReko = () => {
  const dispatch = useDispatch();
  const addRekoSuccess = useSelector(state => state.admin.addRekoSuccess);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCompare, setPasswordCompare] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setPassword('');
    setPasswordCompare('');
    setEmail('');
  };

  useEffect(() => {
    if (addRekoSuccess) clearForm();
  }, [addRekoSuccess]);

  useEffect(() => {
    if (firstName && lastName && email && password && passwordCompare)
      emailValidation(email) ? setIsDisabled(true) : setIsDisabled(false);
    else setIsDisabled(true);
  }, [firstName, lastName, email, password, passwordCompare]);

  const emailValidation = email => {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) return true;
    else return false;
  };

  const handleRegister = evt => {
    evt.preventDefault();

    if (password !== passwordCompare) {
      return toast.error('Lösenorden matchar inte!', {
        position: 'top-center'
      });
    }

    // Register reko...
    const user = {
      firstName,
      lastName,
      email,
      password
    };

    return dispatch(addReko(user));
  };

  return (
    <>
      <div className="middle-section">
        <div className="addreko-content-container">
          <div className="circle-container">
            <div className="logo-container">
              <div className="link-mworks">Mitt mWorks</div>
              <img src={mWorksLogo} className="logo" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="register-content col-sm-12 col-md-6">
          <p className="register-container-header-text ptb-10">
            <strong>Registrera Personal</strong>
          </p>
          <div className="register-form-container">
            <form className="col-sm-12">
              <div className="form-group">
                <label htmlFor="register-first-name">Förnamn</label>
                <input
                  type="text"
                  className="form-control"
                  id="register-first-name"
                  onChange={evt => setFirstName(evt.target.value)}
                  aria-describedby="firstNameHelp"
                  placeholder="Förnamn..."
                  value={firstName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-last-name">Efternamn</label>
                <input
                  type="text"
                  className="form-control"
                  id="register-last-name"
                  onChange={evt => setLastName(evt.target.value)}
                  aria-describedby="lastNameHelp"
                  placeholder="Efternamn..."
                  value={lastName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-email">Email</label>
                <input
                  autoComplete="off"
                  type="email"
                  className="form-control"
                  id="register-email"
                  onChange={evt => setEmail(evt.target.value)}
                  aria-describedby="emailHelp"
                  placeholder="Email..."
                  value={email}
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-password">Lösenord</label>
                <input
                  autoComplete="off"
                  type="password"
                  className="form-control"
                  id="register-password"
                  onChange={evt => setPassword(evt.target.value)}
                  placeholder="Lösenord..."
                  value={password}
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-password-confirm">
                  Lösenordet en gång till
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="register-password-confirm"
                  onChange={evt => setPasswordCompare(evt.target.value)}
                  placeholder="Lösenordet en gång till..."
                  value={passwordCompare}
                />
              </div>
              <div className="register-button-container ptb-10">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleRegister}
                  disabled={isDisabled}
                >
                  Registrera Personal
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddReko;
