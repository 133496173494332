import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../store/actions/authActions';
import './Login.css';
import mWorksLogo from '../../assets/navbarLarge/mWorks/slice@3x.png';

import closedEye from '../../assets/closedEye.svg';
import openEye from '../../assets/openEye.svg';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    if (email.length > 0 && password.length > 0) setIsDisabled(false);
    else setIsDisabled(true);
  }, [email, password]);

  const handleSubmit = evt => {
    evt.preventDefault();

    return dispatch(login(email, password));
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="login ">
      <div className="login-content-container">
        <div className="circle-container">
          <div className="logo-container">
            <div>Mitt mWorks</div>
            <img src={mWorksLogo} className="logo" alt="" />
          </div>
        </div>
        <div className="login-content col-sm-12 col-md-6">
          <p className="login-container-header-text ptb-10">
            <strong>Logga in på ditt mWorks</strong>
          </p>
          <div className=" login-form-container">
            <form className="col-sm-12">
              <div className="form-group">
                <label htmlFor="login-email">Din email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={evt => setEmail(evt.target.value.trim())}
                  aria-describedby="firstNameHelp"
                  id="login-email"
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="login-password">Ditt lösenord</label>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  className="form-control pass-wrapper"
                  value={password}
                  onChange={evt => setPassword(evt.target.value.trim())}
                  aria-describedby="lastNameHelp"
                  id="login-password"
                  name="password"
                  required
                />
                <div onClick={togglePasswordVisiblity} className="hideshow">
                  <img src={passwordShown ? closedEye : openEye} alt="" />
                </div>
              </div>
              <div className="login-button-container ptb-10">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={handleSubmit}
                  disabled={isDisabled}
                >
                  Logga in
                </button>
              </div>
              <div className="register-link-container ptb-10">
                <span className="register">
                  Första besöket? Registrera dig <Link to="/register">här</Link>
                  !
                </span>
                <span className="resetpassword">
                  <Link to="/requestpasswordreset">Återställ lösenord</Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
