import React from 'react';
import './LabelInfo.css';
import infoIcon from '../../assets/information.svg';

const LabelInfo = ({
  title,
  id,
  noIcon,
  defaultInfoClassName,
  setInfoClassName = false
}) => {
  if (id === 'addContact') {
    return (
      <label htmlFor={id} className="textarea-title">
        {title} &nbsp;
        <span className="old-info-icon-container">
          <img
            src={infoIcon}
            alt="information"
            className="iconify info-icon "
            onClick={() => setInfoClassName(defaultInfoClassName + ' opened')}
          />
        </span>
      </label>
    );
  }
  return (
    <label htmlFor={id} className="textarea-title">
      {title} &nbsp;
      {!noIcon && (
        <span className="old-info-icon-container">
          <img
            src={infoIcon}
            alt="information"
            className="iconify info-icon "
            data-inline="false"
            data-toggle="modal"
            data-target={`#${id}Modal`}
          />
        </span>
      )}
    </label>
  );
};

export default LabelInfo;
