import React from 'react';

const InfoBoxButton = ({
  infoClassName,
  setInfoClassName,
  defaultInfoClassName
}) => {
  return (
    <div className="btn-container">
      {infoClassName === defaultInfoClassName && (
        <button
          className="btn top-margin"
          onClick={() => {
            setInfoClassName(defaultInfoClassName + ' opened');
          }}
        >
          <span className="info-icon-container">i</span>
        </button>
      )}

      {infoClassName !== defaultInfoClassName && (
        <button
          type="button"
          className="btn btn-dark top-margin"
          onClick={() => {
            setInfoClassName(defaultInfoClassName);
          }}
        >
          Klar
        </button>
      )}
    </div>
  );
};

export default InfoBoxButton;
