export const setLogoutTime = (user, logoutTime) => {
  const currentSession = user.sessions[user.sessions.length - 1];
  let tempSessions = [...user.sessions];

  const templogoutTime = new Date(logoutTime);
  const tempLoginTime = new Date(currentSession.loginTime);
  const logoutTimeMilli = templogoutTime.getTime();
  const loginTimeMilli = tempLoginTime.getTime();

  const durationInMinutes = Math.floor(
    (logoutTimeMilli - loginTimeMilli) / 1000 / 60
  );

  const tempSession = {
    ...currentSession,
    logoutTime: templogoutTime.toString().split(' ').slice(1, 5).join(' '),
    durationInMinutes: durationInMinutes
  };

  tempSessions[tempSessions.length - 1] = tempSession;
  const sessions = [...tempSessions];

  return sessions;
};
