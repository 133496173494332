import {
  ADD_REKO_SUCCESS,
  ADD_REKO_FAIL,
  ADD_REKO_FAIL_REKO_EXISTS,
  CHANGE_REKO_PASSWORD_SUCCESS,
  DELETE_REKO_SUCCESS,
  FETCH_REKOS_SUCCESS,
  FETCH_REKOS_FAIL,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_REKO_BY_ID_FAIL,
  FETCH_REKO_BY_ID_SUCCESS,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL
} from '../actions/types';
import { toast } from 'react-toastify';

const initialState = {
  addRekoSuccess: false,
  changeRekoPasswordSuccess: false,
  deleteRekoSuccess: false,
  rekos: null,
  reko: null,
  users: null,
  user: null,
  clients: null
};

const toastConfig = {
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true
};

const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_REKO_SUCCESS:
      return { ...state, addRekoSuccess: true };
    case ADD_REKO_FAIL_REKO_EXISTS:
      toast.error(payload, toastConfig);
      //toast.error('Det finns redan en användare med den emailen!', toastConfig);
      return {
        ...state
      }
    case ADD_REKO_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case CHANGE_REKO_PASSWORD_SUCCESS:
      return { ...state, changeRekoPasswordSuccess: true };
    case DELETE_REKO_SUCCESS:
      return { ...state, deleteRekoSuccess: true };

    case FETCH_REKOS_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_REKOS_SUCCESS:
      return {
        ...state,
        rekos: payload
      };
    case FETCH_REKO_BY_ID_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_REKO_BY_ID_SUCCESS:
      return {
        ...state,
        reko: payload
      };
    case FETCH_USERS_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload
      };
    case FETCH_USER_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: payload
      };
    case FETCH_CLIENTS_FAIL:
      toast.error(payload, toastConfig);
      return {
        ...state
      };
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: payload
      };
    default:
      return state;
  }
};

export default adminReducer;
