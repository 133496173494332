import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Section.css';
import {
  setEditingActive,
  setEditingNotActive
} from '../../store/actions/mainActions';
import TextArea from '../textArea/TextArea';
import LabelInfo from '../labelInfo/LabelInfo';
import penIcon from '../../assets/edit.svg';

const Section = ({
  value,
  title,
  onChange,
  onBlur,
  id,
  placeholder,
  updateUserHandler,
  noIcon,
  pageWidth,
  rows,
  defaultInfoClassName,
  setInfoClassName
}) => {
  const dispatch = useDispatch();
  const { sectionId, isEditing } = useSelector(state => state.main);
  const [timer, setTimer] = useState(null);
  const timerInterval = 1000;
  const [disableTextarea, setDisableTextarea] = useState(true);

  const saveWhenNoTyping = () => {
    clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        updateUserHandler();
      }, timerInterval)
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      dispatch(setEditingNotActive());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activePenIcon = id => {
    setInfoClassName(defaultInfoClassName + ' opened');
    dispatch(setEditingActive(id));
    setDisableTextarea(false);
  };

  return (
    <div className="form-group textarea-container">
      <div className="textarea-container-group">
        <LabelInfo id={id} title={title} noIcon="true" />
        <div className="btn-container">
          {!isEditing && (
            <button
              className="btn top-margin"
              onClick={() => {
                activePenIcon(id);
              }}
            >
              <span className="pen-icon-container">
                <img
                  src={penIcon}
                  alt="edit pen"
                  className="iconify"
                  data-inline="false"
                />
              </span>
            </button>
          )}

          {isEditing && id === sectionId && (
            <button
              type="button"
              className="btn btn-dark top-margin"
              onClick={() => {
                setDisableTextarea(true);
                setInfoClassName(defaultInfoClassName);
                dispatch(setEditingNotActive());
                updateUserHandler();
              }}
            >
              Klar
            </button>
          )}
        </div>
      </div>
      <TextArea
        disabled={disableTextarea}
        className="form-control textarea"
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        title={title}
        placeholder={placeholder}
        onKeyUp={() => saveWhenNoTyping()}
        updateUserHandler={updateUserHandler}
        pageWidth={pageWidth}
        rows={rows}
      />
    </div>
  );
};

export default Section;
