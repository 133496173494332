import React from 'react'

const CustomNavLink = ({ item, index }) => {
  return (
    <div
      className={item.cssClass}
      key={index}
    >
      {
        item.cssClass === 'link-mworks' ? (
          <div>
            <p className="link-title standalone-link-title">Mitt mWorks</p>
            <img src={item.logo} className="logo standalone-logo" alt="" />
          </div>
        ) :
          (
            <div className="mobile-nav-item">
              <div className="link-title">{item.title}</div>
              <img
                src={item.logo}
                className="navbar-small-logo"
                alt=""
              />
            </div>
          )
      }
    </div>
  )
}

export default CustomNavLink