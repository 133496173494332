import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  fetchUserByEmail,
  resetPassword
} from '../../store/actions/userActions';
import './RequestPasswordReset.css';
import mWorksLogo from '../../assets/navbarLarge/mWorks/slice@3x.png';
import { ROUTE_LOGIN } from '../../constants/constants';

const RequestPasswordReset = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (email.length > 0) setIsDisabled(false);
    else setIsDisabled(true);
  }, [email]);

  const handleSubmit = async evt => {
    evt.preventDefault();
    let userData = '';

    const Promise = dispatch(fetchUserByEmail(email));
    if (Promise) {
      await Promise.then(function (result) {
        userData = result.payload;
      }).catch(function (err) {});

      return dispatch(resetPassword(userData));
    }
  };

  return (
    <div className="requestPassword">
      <div className="requestPassword-content-container">
        <div className="circle-container">
          <div className="logo-container">
            <div>Mitt mWorks</div>
            <img src={mWorksLogo} className="logo" alt="" />
          </div>
        </div>
        <div className="requestPassword-content col-sm-12 col-md-6">
          <p className="requestPassword-container-header-text ptb-10">
            <strong>Aterställ lösenord</strong>
          </p>
          <div className="requestPassword-form-container">
            <form className="col-sm-12">
              <div className="form-group">
                <label htmlFor="requestPassword-email">Din email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={evt => setEmail(evt.target.value.trim())}
                  aria-describedby="firstNameHelp"
                  id="requestPassword-email"
                  name="email"
                  required
                />
              </div>
              <div className="requestPassword-button-container ptb-10">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={handleSubmit}
                  disabled={isDisabled}
                >
                  Skicka förfrågan
                </button>
              </div>
              <div className="login-link-container ptb-10">
                <Link to={ROUTE_LOGIN}>Tillbaka till inloggningssidan</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
