import React from 'react';
import { List } from 'antd';

function Message(props) {
  const desc = () => {
    if (props.who === 'Mott') {
      return (
        <div>
          <p className="chat-popup-client-text" id={props.latestMessageID}>
            {props.text}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="chat-popup-bot-text" id={props.latestMessageID}>
            {props.text}
          </p>
        </div>
      );
    }
  };
  return (
    <List.Item style={{ padding: '1rem' }}>
      <List.Item.Meta description={desc()} />
    </List.Item>
  );
}

export default Message;
