import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { saveMessage } from '../store/actions/messageActions';
import { updateUser } from '../store/actions/userActions';
import { UPDATE_USER_FAIL } from '../store/actions/types';
import { logout } from '../store/actions/authActions';
const ChatbotAttendee = 'Mott';

function useChatbot() {
  const dispatch = useDispatch();

  const addSection = ({ text, section }, subSect, user) => {
    return Object.assign({}, user, {
      [section]: {
        [subSect]: text
      }
    });
  };

  const updateSection = (subSect, payload, sect, user) => {
    const { text, section, subsection } = payload;
    let subSection = '';
    let sectionToUpdate;
    if (section === 'planning') {
      if (subSect === 'goalsOverall') {
        subSection = sect.goals.overall || '';
        subSection[subSection.indexOf('') || 0] = text;
        sectionToUpdate = Object.assign({}, user[section], {
          [subSect]: subSection
        });
        payload.setGoalsOverall(subSection);
      }
      if (subSect === 'goalsMonthly') {
        subSection = sect.goals.monthly || '';
        subSection[subSection.indexOf('') || 0] = text;
        sectionToUpdate = Object.assign({}, user[section], {
          [subSect]: subSection
        });
        payload.setGoalsMonthly(subSection);
      } else {
        subSection = sect[subSect] || '';
        sectionToUpdate = Object.assign({}, user[section], {
          [subSect]: `${subSection}${text}`
        });
      }

      return Object.assign({}, user, {
        [section]: sectionToUpdate
      });
    } else {
      subSection = sect[subSect] || '';
      const lineBreak = subSection !== '' ? '\n' : '';
      const sectionToUpdate = Object.assign({}, user[section], {
        [subSect]: `${subSection}${lineBreak}${text}`
      });

      return Object.assign({}, user, {
        [section]: sectionToUpdate
      });
    }
  };

  const extractSubsection = (messageData, { section, subsection }, user) => {
    if (section === 'planning') {
      let actionString = messageData.action
        .split('')
        .reverse()
        .join('')
        .split('.')[0]
        .split('')
        .reverse()
        .join('')
        .replace('-', '');
      actionString =
        actionString.charAt(0).toLowerCase() + actionString.slice(1);
      const [subSect] = subsection.filter(item => item.match(actionString));
      return subSect;
    } else {
      const [subSect] = subsection.filter(subItem => {
        const newRegex = new RegExp(`${subItem}_input`, 'ig');
        const event =
          messageData.fulfillmentMessages[0]?.payload?.fields?.event
            ?.stringValue ||
          messageData.fulfillmentMessages[0]?.payload?.fields?.Buttons
            ?.listValue?.values[0]?.structValue?.fields?.event?.structValue
            ?.fields?.name?.stringValue;

        const result = event?.match(newRegex);
        if (result) return result;
      });
      return subSect;
    }
  };

  const addUpdateSection = async (messageData, payload, user) => {
    const subSect = extractSubsection(messageData, payload);
    let tempUser;
    const sect = user[payload.section] || {};

    if (Object.keys(sect).length) {
      tempUser = updateSection(subSect, payload, sect, user);
    } else {
      tempUser = addSection(payload, subSect, user);
    }

    // This is added to stop adding an undefined contact on myNetwork
    if (payload.section === 'network') {
      tempUser = tempUser[payload.section] = {};
    }
    dispatch(updateUser(tempUser));
  };

  const dispatchError = error => {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload:
        'Oops, något gick fel. Du kommer att omdirigeras till inloggningssidan om 5 sekunder'
    });

    setTimeout(function () {
      return dispatch(logout());
    }, 5000);
  };

  const isEndOfConversation = messageData => {
    return messageData.diagnosticInfo?.fields.end_conversation.boolValue;
  };

  const dispatchTextQueryMessage = (content, area, response) => {
    const conversation = {
      event: area,
      who: ChatbotAttendee,
      content: content,
      outputContexts: response.data.outputContexts,
      endOfConversation: isEndOfConversation(response.data)
    };

    dispatch(saveMessage(conversation));
  };

  const textQuery = async payload => {
    //  First  Need to  take care of the message I sent
    const { eventArea, text, section, subsection, user, eventToInvoke } =
      payload;

    let conversation = {
      event: eventArea,
      who: `${user.firstName} ${user.lastName}`,
      section,
      subsection
    };

    if (!!text) {
      conversation.content = { text: { text } };
    }

    dispatch(saveMessage(conversation));

    const dataToSend = {
      event: eventToInvoke
    };

    try {
      const response = await Axios.post(
        '/api/dialogflow/textQuery',
        dataToSend
      );

      for (let content of response.data.fulfillmentMessages) {
        dispatchTextQueryMessage(content, eventArea, response);
      }

      eventArea !== 'Welcome'
        ? addUpdateSection(response.data, payload, user)
        : dispatch(updateUser(user));
    } catch (error) {
      dispatchError(error);
    }
  };

  const dispatchEventQueryMessage = (content, area, response) => {
    const conversation = {
      event: area,
      who: ChatbotAttendee,
      content: content,
      endOfConversation: isEndOfConversation(response.data)
    };

    dispatch(saveMessage(conversation));
  };

  const eventQuery = async (area, user) => {
    const eventQueryVariables = {
      event: area
    };

    try {
      const response = await Axios.post(
        '/api/dialogflow/eventQuery',
        eventQueryVariables
      );

      for (let content of response.data.fulfillmentMessages) {
        dispatchEventQueryMessage(content, area, response);
      }
    } catch (error) {
      dispatchError(error);
    }
  };

  return { eventQuery, textQuery };
}

export default useChatbot;
