import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkIfLoggedIn } from '../store/actions/authActions';
import './App.css';

// COMPONENTS
import Login from '../components/login/Login.jsx';
import Register from '../components/register/Register.jsx';
import RequestPasswordReset from '../components/requestPasswordReset/RequestPasswordReset.jsx';
import ResetPassword from '../components/resetPassword/ResetPassword.jsx';
import Navbar from '../components/navbar/Navbar';
import HomePage from '../components/homePage/HomePage';
import MyMworks from '../components/myMworks/MyMworks.jsx';
import MyResources from '../components/myResources/MyResources';
import MyStrategies from '../components/myStrategies/MyStrategies';
import MyPlanning from '../components/myPlanning/MyPlanning';
import MyWellbeing from '../components/myWellbeing/MyWellbeing';
import MyNetwork from '../components/myNetwork/MyNetwork';
import Disclaimer from '../components/disclaimer/modal/DisclaimerModal';

//VIEWS
import AdminPage from './admin/Admin';
import REKOPage from './reko/Reko';
import SessionData from './admin/sessionData/SessionData';
import ManageRekos from './admin/manageRekos/ManageRekos';
import AddReko from './admin/manageRekos/addReko/AddReko';
import ChangeRekoPassword from './admin/manageRekos/changeRekoPassword/ChangeRekoPassword';
import ManageClients from './reko/manageClients/ManageClients';
import AddClient from './reko/manageClients/addClient/AddClient';

import {
  ROUTE_LOGIN,
  ROUTE_ADMIN,
  ROUTE_REKO,
  ROUTE_SESSION_DATA,
  ROUTE_MANAGE_REKOS,
  ROUTE_ADD_REKO,
  ROUTE_CHANGE_REKO_PASSWORD,
  ROUTE_MANAGE_CLIENTS,
  ROUTE_ADD_CLIENT,
  ROUTE_REGISTER,
  ROUTE_REQUEST_PASSWORD_RESET,
  ROUTE_RESET_PASSWORD,
  ROUTE_HOME,
  ROUTE_MY_MWORKS,
  ROUTE_MY_RESOURCES,
  ROUTE_MY_STRATEGIES,
  ROUTE_MY_PLANNING,
  ROUTE_MY_WELLBEING,
  ROUTE_MY_NETWORK
} from '../constants/constants';
import { fetchUser } from '../store/actions/userActions';

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const role = useSelector(state => state.user.userRole);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isReko, setIsReko] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setPageWidth(window.innerWidth));
    setIsLoading(true);
    dispatch(checkIfLoggedIn()).then(() => setIsLoading(false));
    dispatch(fetchUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role) {
      setIsAdmin(role === 'admin');
      setIsReko(role === 'reko');
      setIsClient(role === 'client');
    } else {
      setIsAdmin(false);
      setIsReko(false);
      setIsClient(false);
    }
  }, [role]);

  const Spinner = () => {
    return (
      <div className="App">
        <div className="d-flex justify-content-center loading-spinner-container">
          <div className="spinner-border loading-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  };

  const LoginRoutes = () => {
    return (
      <div className="App">
        <Switch>
          <Route exact path={ROUTE_LOGIN}>
            <Login />
          </Route>
          <Route path={ROUTE_REGISTER}>
            <Register />
          </Route>
          <Route exact path={ROUTE_REQUEST_PASSWORD_RESET}>
            <RequestPasswordReset />
          </Route>
          <Route path={ROUTE_RESET_PASSWORD}>
            <ResetPassword />
          </Route>
          <Redirect to={ROUTE_LOGIN} />
        </Switch>
      </div>
    );
  };

  const AdminRoutes = () => {
    return (
      <div className="App">
        <Switch>
          <Route path={ROUTE_ADMIN} exact>
            <AdminPage />
          </Route>
          <Route path={ROUTE_SESSION_DATA} exact>
            <SessionData />
          </Route>
          <Route path={ROUTE_MANAGE_REKOS} exact>
            <ManageRekos />
          </Route>
          <Route path={ROUTE_MANAGE_CLIENTS} exact>
            <ManageClients />
          </Route>
          <Route path={ROUTE_ADD_REKO} exact>
            <AddReko />
          </Route>
          <Route path={ROUTE_CHANGE_REKO_PASSWORD} exact>
            <ChangeRekoPassword />
          </Route>
          <Redirect to={ROUTE_ADMIN} />
        </Switch>
      </div>
    );
  };

  const RekoRoutes = () => {
    return (
      <div className="App">
        <Switch>
          <Route path={ROUTE_REKO}>
            <REKOPage />
          </Route>
          <Route path={ROUTE_MANAGE_CLIENTS}>
            <ManageClients />
          </Route>
          <Route path={ROUTE_ADD_CLIENT} exact>
            <AddClient />
          </Route>
          <Redirect to={ROUTE_REKO} />
        </Switch>
      </div>
    );
  };

  const ClientRoutes = () => {
    return (
      <div className="App">
        <Navbar />
        <Switch>
          <Route path={ROUTE_HOME} exact>
            <HomePage pageWidth={pageWidth} />
            <div className="version">v 2.0.2</div>
          </Route>
          <Route path={ROUTE_MY_MWORKS}>
            <MyMworks pageWidth={pageWidth} />
          </Route>
          <Route path={ROUTE_MY_RESOURCES}>
            <MyResources pageWidth={pageWidth} />
          </Route>
          <Route path={ROUTE_MY_STRATEGIES}>
            <MyStrategies pageWidth={pageWidth} />
          </Route>
          <Route path={ROUTE_MY_PLANNING}>
            <MyPlanning pageWidth={pageWidth} />
          </Route>
          <Route path={ROUTE_MY_WELLBEING}>
            <MyWellbeing pageWidth={pageWidth} />
          </Route>
          <Route path={ROUTE_MY_NETWORK}>
            <MyNetwork pageWidth={pageWidth} />
          </Route>
          <Redirect to={ROUTE_HOME} />
        </Switch>
      </div>
    );
  };

  return (
    <div className="App">
      {isLoading && <Spinner />}
      {!isLoading && !isLoggedIn && <LoginRoutes />}
      {isLoggedIn && isAdmin && <AdminRoutes />}
      {isLoggedIn && isReko && <RekoRoutes />}
      {isLoggedIn && isClient && <ClientRoutes />}
    </div>
  );
};

export default App;
