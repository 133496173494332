import {
  SAVE_HOME_MESSAGE,
  SAVE_MWORKS_MESSAGE,
  SAVE_RESOURCES_MESSAGE,
  SAVE_NETWORK_MESSAGE,
  SAVE_WELLBEING_MESSAGE,
  SAVE_STRATEGY_MESSAGE,
  SAVE_PLANNING_MESSAGE,
  UPDATE_USER_SUCCESS,
  FETCH_USER_SUCCESS,
  LOGIN_SUCCESS
} from '../actions/types';

const initialState = {
  home: [],
  mworks: [],
  resources: [],
  network: [],
  wellBeing: [],
  strategies: [],
  planning: []
};

export default function (state = initialState, { payload, type }) {
  switch (type) {
    case SAVE_HOME_MESSAGE:
      return {
        ...state,
        home: state.home.concat(payload)
      };
    case SAVE_MWORKS_MESSAGE:
      return {
        ...state,
        mworks: state.mworks.concat(payload)
      };
    case SAVE_RESOURCES_MESSAGE:
      return {
        ...state,
        resources: state.resources.concat(payload)
      };
    case SAVE_NETWORK_MESSAGE:
      return {
        ...state,
        network: state.network.concat(payload)
      };
    case SAVE_WELLBEING_MESSAGE:
      return {
        ...state,
        wellBeing: state.wellBeing.concat(payload)
      };
    case SAVE_STRATEGY_MESSAGE:
      return {
        ...state,
        strategies: state.strategies.concat(payload)
      };
    case SAVE_PLANNING_MESSAGE:
      return {
        ...state,
        planning: state.planning.concat(payload)
      };
    case LOGIN_SUCCESS:
    case FETCH_USER_SUCCESS:
      return payload.chatbotConversations
        ? payload.chatbotConversations
        : state;
    default:
      return state;
  }
}
