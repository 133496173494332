import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Register.css';
import { toast } from 'react-toastify';
import { register } from '../../store/actions/authActions';
import mWorksLogo from '../../assets/navbarLarge/mWorks/slice@3x.png';
import infoIcon from '../../assets/information.svg';

import { ROUTE_LOGIN } from '../../constants/constants';

const Register = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCompare, setPasswordCompare] = useState('');
  const [acceptCookie, setAcceptCookie] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const COOKIE_INFO_TOAST_ID = 'COOKIE_INFO_TOAST_ID';

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      password &&
      passwordCompare &&
      acceptCookie
    )
      emailValidation(email) ? setIsDisabled(true) : setIsDisabled(false);
    else setIsDisabled(true);
  }, [firstName, lastName, email, password, passwordCompare, acceptCookie]);

  const cookieClick = () => {
    if (!toast.isActive(COOKIE_INFO_TOAST_ID)) {
      toast(cookieInfo, {
        position: 'bottom-center',
        autoClose: false,
        toastId: COOKIE_INFO_TOAST_ID
      });
    }
  };

  const emailValidation = email => {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(email)) return true;
    else return false;
  };

  const handleRegister = evt => {
    evt.preventDefault();

    if (password !== passwordCompare) {
      return toast.error('Lösenorden matchar inte!', {
        position: 'top-center'
      });
    }

    // Register user...
    const user = {
      firstName,
      lastName,
      email,
      password
    };

    return dispatch(register(user));
  };

  const cookieInfo =
    'Du måste godkänna att vi sparar cookies i din webbläsare. Cookien används endast till att säkerställa att du är inloggad. Informationen delas ej vidare till tredje part.';

  return (
    <div className="register ptb-10">
      <div className="register-content-container">
        <div className="circle-container">
          <div className="logo-container">
            <div>Mitt mWorks</div>
            <img src={mWorksLogo} className="logo" alt="" />
          </div>
        </div>

        <div className="register-content col-sm-12 col-md-6">
          <p className="register-container-header-text ptb-10">
            <strong>Registrera dig här!</strong>
          </p>
          <div className="register-form-container">
            <form className="col-sm-12">
              <div className="form-group">
                <label htmlFor="register-first-name">Ditt förnamn</label>
                <input
                  type="text"
                  className="form-control"
                  id="register-first-name"
                  onChange={evt => setFirstName(evt.target.value)}
                  aria-describedby="firstNameHelp"
                  placeholder="Förnamn..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-last-name">Ditt efternamn</label>
                <input
                  type="text"
                  className="form-control"
                  id="register-last-name"
                  onChange={evt => setLastName(evt.target.value)}
                  aria-describedby="lastNameHelp"
                  placeholder="Efternamn..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-email">Din email</label>
                <input
                  type="email"
                  className="form-control"
                  id="register-email"
                  onChange={evt => setEmail(evt.target.value)}
                  aria-describedby="emailHelp"
                  placeholder="Email..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-password">Ditt lösenord</label>
                <input
                  type="password"
                  className="form-control"
                  id="register-password"
                  onChange={evt => setPassword(evt.target.value)}
                  placeholder="Lösenord..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="register-password-confirm">
                  Ditt lösenord en gång till
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="register-password-confirm"
                  onChange={evt => setPasswordCompare(evt.target.value)}
                  placeholder="Lösenord en gång till..."
                />
              </div>
              <div className="form-check cookie-accept-container">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="cookie-accept"
                  id="cookie-accept"
                  onChange={evt => setAcceptCookie(evt.target.checked)}
                />
                <label
                  htmlFor="cookie-accept"
                  className="form-check-label mr-2"
                >
                  Jag godkänner cookies
                </label>
                <span className="old-info-icon-container" onClick={cookieClick}>
                  <img
                    src={infoIcon}
                    alt="information"
                    className="iconify text-warning "
                    data-inline="false"
                  />
                </span>
              </div>
              <div className="register-button-container ptb-10">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleRegister}
                  disabled={isDisabled}
                >
                  Registrera dig
                </button>
              </div>
              <div className="login-link-container ptb-10">
                <Link to={ROUTE_LOGIN}>Tillbaka till inloggningssidan</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
