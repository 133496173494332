import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../store/actions/adminActions';
import './SessionData.css';
import mWorksLogo from '../../../assets/navbarLarge/mWorks/slice@3x.png';
import UserSessions from './userSessions/UserSessions';

const SessionData = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.admin.users);
  const [userInfo, setUserInfo] = useState('');

  const initData = async () => {
    await dispatch(fetchUsers());
  };
  useEffect(() => {
    initData();
  }, []);

  const handleOnSubmit = (evt, userId) => {
    evt.preventDefault();
    if (evt) {
      setUserInfo(userId);
    }
  };

  return (
    <>
      <div className="sessions-middle-section">
        <div className="sessions-content-container">
          <div className="circle-container">
            <div className="logo-container">
              <div>Mitt mWorks</div>
              <img src={mWorksLogo} className="logo" alt="" />
            </div>
            <br />
            <br />
          </div>
          <div>
            <h1>Användarsession data</h1>
          </div>
          <br />
        </div>
      </div>
      <div className="sessions-bottom-section">
        <div className="data-container">
          <div className="sessions-center-container">
            <div className="left-top-container">
              <div>
                <div>
                  <div className="session-heading-text">Klient</div>
                  <div className="session-heading-text total-sessions">
                    Totala sessioner
                  </div>
                </div>
                {users &&
                  users
                    .filter(user => user.sessions && user.sessions.length > 0)
                    .map(user => (
                      <div key={user._id}>
                        <div
                          className="session-column-userdata underlined-text"
                          onClick={evt => handleOnSubmit(evt, user._id)}
                        >
                          <strong>
                            {user.firstName + ' ' + user.lastName}
                          </strong>
                        </div>
                        <div className="session-column-userdata">
                          {user.sessions && user.sessions.length}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <UserSessions userInfo={userInfo} />
        </div>
      </div>
    </>
  );
};

export default SessionData;
