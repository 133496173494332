import React from 'react';

const GridSquare = ({ contact, showContact }) => {
  const style = contact
    ? { backgroundColor: contact.color }
    : { visibility: 'hidden' };

  return (
    <div
      className="grid-square"
      style={style}
      onClick={() => showContact(contact)}
    >
      <p className="name">
        {contact &&
          `${
            contact.name?.length > 15
              ? contact.name.substring(0, 15) + '...'
              : contact.name
          }`}
      </p>
      <p className="title">
        {contact &&
          `${
            contact.title?.length > 15
              ? contact.title.substring(0, 15) + '...'
              : contact.title
          }`}
      </p>
    </div>
  );
};

export default GridSquare;
