import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/authActions';
import { useLocation } from 'react-router';
import NavbarLarge from './navbarLarge/NavbarLarge.jsx';
import NavbarSmall from './navbarSmall/NavbarSmall.jsx';
import useDevice from '../../constants/devices';

const Navbar = () => {
  const dispatch = useDispatch();
  const device = useDevice();
  const location = useLocation();
  const pathName = location.pathname;
  const showNavbar =
    pathName !== '/' && pathName !== '/login' && pathName !== '/register';

  const handleLogout = () => {
    return dispatch(logout());
  };

  return (
    <>
      {device === 'small' && showNavbar && (
        <NavbarSmall handleLogout={handleLogout} pathName={pathName} />
      )}
      {device === 'large' && showNavbar && (
        <NavbarLarge handleLogout={handleLogout} />
      )}
    </>
  );
};

export default Navbar;
