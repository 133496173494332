import { message } from 'antd';
import React from 'react';
import Message from '../Sections/Message';

const ChatMessage = ({
  message,
  index,
  returnedMessagesLength,
  showDots,
  latestMessageID
}) => {
  const TypingAnimation = () => {
    return (
      <div className="typing-dot-container">
        <span className="typing-dot"></span>
        <span className="typing-dot"></span>
        <span className="typing-dot"></span>
      </div>
    );
  };

  let messageText = '';

  if (isMottInput(message.content)) {
    messageText = message.content.payload.fields.title.stringValue;
  } else {
    messageText = message.content.text.text;
    if (message.content.text.text.text !== undefined) {
      messageText = message.content.text.text.text;
    }
  }

  return (
    <div key={index}>
      {showDots &&
        returnedMessagesLength > 1 &&
        index === returnedMessagesLength - 1 &&
        message.who === 'Mott' && <TypingAnimation />}
      {!showDots && index === returnedMessagesLength - 1 && (
        <Message
          key={index}
          who={message.who}
          text={messageText}
          latestMessageID={latestMessageID}
        />
      )}
      {returnedMessagesLength > 1 && index !== returnedMessagesLength - 1 && (
        <Message
          key={index}
          who={message.who}
          text={messageText}
          latestMessageID={latestMessageID}
        />
      )}
    </div>
  );
};

const isMottInput = messageContent => messageContent.payload?.fields?.title;

export default ChatMessage;
