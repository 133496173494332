import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../store/actions/userActions';
import {
  setEditingActive,
  setEditingNotActive
} from '../../../store/actions/mainActions';
import LabelInfo from '../../labelInfo/LabelInfo';
import penIcon from '../../../assets/edit.svg';
import { MyGoalsInitialFields } from './initialfields';
import './MyGoalsSection.scss';
import useChatbot from '../../../hooks/useChatbot';
import ChatWindow from '../../chatWindow/ChatWindow';
import useDevice from '../../../constants/devices';
import { MY_PLANNING_EVENT } from '../../../constants/constants';

const MyGoalsSection = ({
  title,
  id,
  noIcon,
  defaultInfoClassName,
  setInfoClassName
}) => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const messages = useSelector(state => state.message);
  const [showResults, setShowResults] = useState(false);
  const { sectionId, isEditing } = useSelector(state => state.main);
  const isEditingThisBox = sectionId === 'myGoals' && isEditing;
  const [goalsOverall, setGoalsOverall] = useState(
    user.planning.goals ? user.planning.goals.overall : ''
  );
  const [goalsMonthly, setGoalsMonthly] = useState(
    user.planning.goals ? user.planning.goals.monthly : ''
  );

  const activePenIcon = id => {
    setInfoClassName(defaultInfoClassName + ' opened');
    dispatch(setEditingActive(id));
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (!user.pagesVisited.planning) {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      user.chatbotConversations.planning.length === 0 &&
      messages.planning.length === 0
    ) {
      chatBot.eventQuery(MY_PLANNING_EVENT, user);
    }
  }, []);

  const subSections = ['goalsOverall', 'goalsMonthly'];
  const CHAT_WINDOW = {
    event: MY_PLANNING_EVENT,
    section: 'planning',
    subsection: subSections
  };

  const goalsChangeHandler = (value, index, array, type) => {
    let tempGoals = [...array];
    tempGoals[index] = value;
    if (type === 'overall') {
      setGoalsOverall(tempGoals);
    } else if (type === 'monthly') {
      setGoalsMonthly(tempGoals);
    }
  };

  const updateUserObject = () => {
    const planning = {
      ...user.planning,
      goals: {
        overall: goalsOverall,
        monthly: goalsMonthly
      }
    };
    const tempUser = Object.assign({}, user, { planning });
    dispatch(updateUser(tempUser));
  };

  return (
    <div className="form-group textarea-container">
      <div className="textarea-container-group">
        <LabelInfo id={id} title={title} noIcon="true" />
        <div className="btn-container">
          {!isEditing && (
            <button
              className="btn top-margin"
              onClick={() => {
                activePenIcon(id);
              }}
            >
              <span className="pen-icon-container">
                <img
                  src={penIcon}
                  alt="edit pen"
                  className="iconify"
                  data-inline="false"
                />
              </span>
            </button>
          )}

          {isEditing && id === sectionId && (
            <button
              type="button"
              className="btn btn-dark top-margin"
              onClick={() => {
                setInfoClassName(defaultInfoClassName);
                dispatch(setEditingNotActive());
              }}
            >
              Klar
            </button>
          )}
        </div>
      </div>

      <div className="goals-wrapper">
        <div className={'lists-wrapper' + (isEditingThisBox ? '' : ' locked')}>
          <div className="list">
            <div className="heading">Mina tre övergripande mål</div>
            <ol>
              {goalsOverall.map((text, i) => {
                return (
                  <li key={i}>
                    <input
                      id="goalsOverall"
                      value={goalsOverall[i]}
                      type="text"
                      onChange={evt =>
                        goalsChangeHandler(
                          evt.target.value,
                          i,
                          goalsOverall,
                          'overall'
                        )
                      }
                      onBlur={updateUserObject}
                      disabled={isEditingThisBox ? false : true}
                      ref={i === 0 ? inputRef : null}
                    />
                  </li>
                );
              })}
            </ol>
          </div>
          <div className="list">
            <div className="heading">Mina tre mål denna månad</div>
            <ol>
              {goalsMonthly.map((text, i) => {
                return (
                  <li key={i}>
                    <input
                      id="goalsMonthly"
                      value={goalsMonthly[i]}
                      type="text"
                      onChange={evt =>
                        goalsChangeHandler(
                          evt.target.value,
                          i,
                          goalsMonthly,
                          'monthly'
                        )
                      }
                      onBlur={updateUserObject}
                      disabled={isEditingThisBox ? false : true}
                    />
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
      <ChatWindow
        modalText={CHAT_WINDOW}
        showResults={showResults}
        setShowResults={setShowResults}
        user={user}
        page={user.pagesVisited.planning}
        pageName="planning"
        setGoalsOverall={setGoalsOverall}
        setGoalsMonthly={setGoalsMonthly}
      />
    </div>
  );
};

export default MyGoalsSection;
