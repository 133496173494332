const Colors = {};

Colors.PINK = { name: 'pink', code: '#dfb0d4' };
Colors.BLUE = { name: 'blue', code: '#84bcdd' };
Colors.GREEN = { name: 'green', code: '#81bb94' };
Colors.PURPLE = { name: 'purple', code: '#af92d1' };
Colors.YELLOW = { name: 'yellow', code: '#ebd17b' };

Colors.DEFAULT_COLOR = Colors.GREEN;

Colors.ColorMap = [
    Colors.PINK,
    Colors.BLUE,
    Colors.GREEN,
    Colors.PURPLE,
    Colors.YELLOW
];

export default Colors;