import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actions/authActions';
import ManageClients from './manageClients/ManageClients';
import SessionData from './sessionData/SessionData';
import ManageRekos from './manageRekos/ManageRekos';
import ChangeRekoPassword from './manageRekos/changeRekoPassword/ChangeRekoPassword';
import './Admin.css';
import mWorksLogo from '../../assets/navbarLarge/mWorks/slice@3x.png';
import AddReko from './manageRekos/addReko/AddReko';

const UserData = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const [showSessions, setShowSessions] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const [showRekos, setShowRekos] = useState(false);
  const [showAddRekos, setShowAddRekos] = useState(false);
  const [showChangeRekosPassword, setShowChangeRekosPassword] = useState(false);

  const handleLogout = () => {
    return dispatch(logout());
  };

  const showAdmin = () => {
    setShowMain(true);
    setShowClients(false);
    setShowSessions(false);
    setShowRekos(false);
    setShowAddRekos(false);
    setShowChangeRekosPassword(false);
  };
  const showClient = () => {
    setShowMain(false);
    setShowClients(true);
    setShowSessions(false);
    setShowRekos(false);
    setShowAddRekos(false);
    setShowChangeRekosPassword(false);
  };
  const showSession = () => {
    setShowMain(false);
    setShowClients(false);
    setShowSessions(true);
    setShowRekos(false);
    setShowAddRekos(false);
    setShowChangeRekosPassword(false);
  };
  const showReko = () => {
    setShowMain(false);
    setShowClients(false);
    setShowSessions(false);
    setShowRekos(true);
    setShowAddRekos(false);
    setShowChangeRekosPassword(false);
  };
  const showAddReko = () => {
    setShowMain(false);
    setShowClients(false);
    setShowSessions(false);
    setShowRekos(false);
    setShowAddRekos(true);
    setShowChangeRekosPassword(false);
  };
  const showChangeRekoPassword = () => {
    setShowMain(false);
    setShowClients(false);
    setShowSessions(false);
    setShowRekos(false);
    setShowAddRekos(false);
    setShowChangeRekosPassword(true);
  };

  return (
    <div className="adminpage">
      <div className="main-container">
        <div className="top-section">
          <div className="navbar">
            <div>
              {' '}
              <p className="logout-link" onClick={handleLogout}>
                Logga ut
              </p>
            </div>
            <a className="pagelink" onClick={showAdmin}>
              Hem
            </a>
          </div>
        </div>
        {!showClients &&
          !showSessions &&
          !showRekos &&
          !showAddRekos &&
          !showChangeRekosPassword &&
          showMain && (
            <>
              <div className="middle-section">
                <div className="adminpage-content-container">
                  <div className="circle-container">
                    <div className="logo-container">
                      <div className="link-mworks">Mitt mWorks</div>
                      <img src={mWorksLogo} className="logo" alt="" />
                    </div>
                    <br />
                    <br />
                  </div>
                  <div>
                    <>
                      <h1>Admin's sida</h1>
                    </>
                  </div>
                  <br />
                </div>
              </div>
              <div className="bottom-section">
                <div className="main-div">
                  <div className="left-div">
                    <a className="pagelink" onClick={showReko}>
                      Hantera personal
                    </a>
                  </div>
                  <div className="center-div">
                    <a className="pagelink" onClick={showClient}>
                      Hantera Kunder
                    </a>
                  </div>
                  <div className="right-div">
                    <a className="pagelink" onClick={showSession}>
                      Användarsession Data
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        {!showMain &&
          !showClients &&
          !showRekos &&
          !showAddRekos &&
          !showChangeRekosPassword &&
          showSessions && (
            <>
              <SessionData />
            </>
          )}
        {!showMain &&
          !showSessions &&
          !showRekos &&
          !showAddRekos &&
          !showChangeRekosPassword &&
          showClients && (
            <>
              <ManageClients />
            </>
          )}
        {!showMain &&
          !showClients &&
          !showSessions &&
          !showAddRekos &&
          !showChangeRekosPassword &&
          showRekos && (
            <>
              <ManageRekos />
            </>
          )}
        {!showMain &&
          !showClients &&
          !showSessions &&
          !showRekos &&
          !showChangeRekosPassword &&
          showAddRekos && (
            <>
              <AddReko />
            </>
          )}
        {!showMain &&
          !showClients &&
          !showSessions &&
          !showRekos &&
          !showAddRekos &&
          showChangeRekosPassword && (
            <>
              <ChangeRekoPassword />
            </>
          )}
      </div>
    </div>
  );
};

export default UserData;
