import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { setCloudModalData } from '../../store/actions/cloudActions';
import { updateUser } from '../../store/actions/userActions';

import { LIST_MY_STRENGTHS } from '../../constants/placeholderTexts';
import { QUALITIES, SKILLS } from '../../constants/qualitiesAndSkills';
import mWorkslogo from '../../assets/homepage/mworks-logo@3x.png';
import InfoModal from '../infoModal/InfoModal';
import InfoBox from '../InfoBox/InfoBox';
import InfoBoxButton from '../InfoBox/InfoBoxButton/InfoBoxButton';
import LabelInfo from '../labelInfo/LabelInfo';
import Section from '../section/Section';
import WordCloudSection from '../myResources/wordCloudSection/WordCloudSection';
import CloudModal from './cloudModal/CloudModal';
import './MyResources.css';
import useChatbot from '../../hooks/useChatbot';
import ChatWindow from '../chatWindow/ChatWindow';
import useDevice from '../../constants/devices';
import { MY_RESOURCES_EVENT } from '../../constants/constants';
import { setLogoutTime } from '../../utils/setLogoutTime';

const MyResources = ({ pageWidth }) => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const [showResults, setShowResults] = useState(false);
  const messages = useSelector(state => state.message);
  const [myWorkProfile, setMyWorkProfile] = useState(
    user.resources?.workProfile || ''
  );
  const [myStrengthsList, setMyStrengthsList] = useState(
    user.resources?.strengthsList ? user.resources.strengthsList : []
  );
  const [qualitiesList, setUpdateQualitiesList] = useState(QUALITIES);
  const sortedQualitiesList = [...qualitiesList].sort((a, b) => {
    return b - a;
  });
  const [skillsList, setUpdateSkillsList] = useState(SKILLS);
  const sortedSkillsList = [...skillsList].sort((a, b) => {
    return b - a;
  });
  const [myWorkProfileInfoClassName, setMyWorkProfileInfoClassName] = useState(
    'my-work-profile-info'
  );
  const [myStrengthsListInfoClassName, setMyStrengthsListInfoClassName] =
    useState('my-strengths-list-info');

  useEffect(() => {
    if (!user.pagesVisited.resources) {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      user.chatbotConversations.resources.length === 0 &&
      messages.resources.length === 0
    ) {
      chatBot.eventQuery(MY_RESOURCES_EVENT, user);
    }
  }, []);

  const handleOnIdle = () => {
    const sessions = setLogoutTime(user, getLastActiveTime());
    const tempUser = Object.assign({}, user, { sessions });
    dispatch(updateUser(tempUser));
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const CHAT_WINDOW = {
    event: MY_RESOURCES_EVENT,
    section: 'resources',
    subsection: []
  };

  /* INFO_TEXT_MODALS */

  const MY_STRENGTHSLIST_MODAL = {
    title: 'Lista med styrkor ',
    body: 'Ta en stund och fundera över dina styrkor. Inspireras av exempellistorna med egenskaper och färdigheter. Fråga någon som känner dig, en vän, anhörig, arbetsgivare, eller varför inte en granne du hjälpt, och hör med dem vad de tycker du är bra på. Något du vill planera för i din kalender? Lista sen dina styrkor genom att klicka på dem i listorna.'
  };

  const LIST_OF_MY_STRENGTHSLIST_MODAL = {
    title: 'Min lista med styrkor ',
    body: 'Här listar du dina styrkor och egenskaper, klicka på en egenskap i listan ovanför eller skriv in egna.'
  };

  const MY_WORKPROFILE_MODAL = {
    title: 'Min arbetsprofil',
    specialBody: [
      <>
        Beskriv och sammanfatta dina styrkor från listan i en arbetsprofil.
        Tanken är att skriva fram en profil som stämmer överens med dig och hur
        det är idag. Det gör att du lättare för dig själv och andra kan ringa in
        dina resurser.
      </>,
      <>
        Ex:{' '}
        <em>
          Jag tycker om att vara med andra och är fortfarande social. Jag
          använder den färdigheten tillsammans med min nya insikt och styrka som
          handlar om att vara mer dynamisk och lyssna in andra. Jag skulle säga
          att jag är mer empatisk nu, en egenskap jag använder när jag träffar
          kunderna.
        </em>
      </>,
      <>
        <em>
          Jag arbetar självständigt och kan planera mitt eget arbete, och är bra
          på att ta mindre pauser under dagen. Det är en liten introvert sida
          jag upptäckt som gör att jag kan återhämta mig och orkar arbeta.
        </em>
      </>,
      <>
        <em>
          Andra säger jag är bra på att fokusera och att jag har mycket kunskap,
          jag kan saker. Men det är viktigt att ladda mina batterier som gör att
          jag behöver jobba mer flexibelt.
        </em>
      </>
    ],
    placeholder:
      'Exempel: \nJag tycker om att vara med andra och är fortfarande social. \nJag använder den färdigheten tillsammans med min nya insikt och styrka som handlar om att vara mer dynamisk och lyssna in andra. \nJag skulle säga att jag är mer empatisk nu, en egenskap jag använder när jag träffar kunderna.'
  };

  /* INFO_TEXT_MODALS END */

  const updateUserHandler = () => {
    const resources = {
      workProfile: myWorkProfile,
      strengthsList: myStrengthsList
    };
    const tempUser = Object.assign({}, user, {
      resources
    });
    dispatch(updateUser(tempUser));
  };

  const listClick = (item, listIdentifier) => {
    dispatch(
      setCloudModalData({
        listIdentifier,
        name: item || '',
        id: '',
        level: 3,
        color: 'color-3'
      })
    );
  };

  const updateListAndQualityUiLists = () => {
    const qualities = [];
    const skills = [];

    // Build arrays of only names for quality/skill for easy checks in filters below
    // This could all be in one array but is designed this way in case customer puts same word in both lists
    myStrengthsList.map(entry => {
      const belongsToList = entry.listIdentifier;
      if (belongsToList === 'quality') {
        qualities.push(entry.name);
      }
      if (belongsToList === 'skill') {
        skills.push(entry.name);
      }
      return null;
    });

    const newQualitiesList = QUALITIES.filter(
      name => !qualities.includes(name)
    );
    const newSkillsList = SKILLS.filter(name => !skills.includes(name));

    setUpdateQualitiesList(newQualitiesList);
    setUpdateSkillsList(newSkillsList);
  };

  useEffect(() => {
    updateListAndQualityUiLists();
  }, [myStrengthsList]);

  useEffect(() => {
    updateListAndQualityUiLists();
  }, []);

  return (
    <div className="myresources">
      <div className="background-container"></div>
      <div className="main-container">
        <div className="left-container">
          <InfoBox
            className={myStrengthsListInfoClassName}
            modalText={MY_STRENGTHSLIST_MODAL}
          />
          <div className="left-top-container">
            {/* TODO: Extract to seperate component */}
            <div className="left-top-header">
              <LabelInfo
                id="myStrengthsList"
                title="Vilka är mina styrkor?"
                noIcon="true"
              />
              <InfoBoxButton
                infoClassName={myStrengthsListInfoClassName}
                setInfoClassName={setMyStrengthsListInfoClassName}
                defaultInfoClassName="my-strengths-list-info"
              />
            </div>

            <div
              style={{
                height: '100%',
                overflow: 'hidden',
                display: 'flex'
              }}
            >
              <div className="div-strengths">
                <b className="b-strengths">
                  <ins>Egenskaper</ins>
                </b>
                <ul className="ul-strengths">
                  {sortedQualitiesList.map((q, index) => (
                    <li
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => listClick(q, 'quality')}
                      data-toggle="modal"
                      data-target="#selectedStrength"
                    >
                      {q}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="div-strengths">
                <b className="b-strengths">
                  <ins>Färdigheter</ins>
                </b>
                <ul className="ul-strengths">
                  {sortedSkillsList.map((s, index) => (
                    <li
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => listClick(s, 'skill')}
                      data-toggle="modal"
                      data-target="#selectedStrength"
                    >
                      {s}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* TODO: Extract to seperate component END */}
          </div>

          <div className="left-bottom-container">
            <WordCloudSection
              id="listOfSelectedStrengths"
              title="Här kan jag lista mina styrkor"
              placeholder={LIST_MY_STRENGTHS}
              myStrengthsList={myStrengthsList}
              randomizeList={false}
              listClick={listClick}
            />
          </div>
        </div>
        <div className="right-container">
          <InfoBox
            className={myWorkProfileInfoClassName}
            modalText={MY_WORKPROFILE_MODAL}
          />
          <div className="right-container-inner">
            <Section
              rows={pageWidth > 992 ? 20 : 8}
              id="myworkprofile"
              value={myWorkProfile}
              onChange={evt => setMyWorkProfile(evt.target.value)}
              onBlur={updateUserHandler}
              title="Min arbetsprofil"
              updateUserHandler={updateUserHandler}
              placeholder={MY_WORKPROFILE_MODAL.placeholder}
              defaultInfoClassName="my-work-profile-info"
              setInfoClassName={setMyWorkProfileInfoClassName}
            />
          </div>
        </div>
      </div>
      <InfoModal modalText={MY_WORKPROFILE_MODAL} id="myworkprofile" />
      <InfoModal modalText={MY_STRENGTHSLIST_MODAL} id="myStrengthsList" />
      <InfoModal
        modalText={LIST_OF_MY_STRENGTHSLIST_MODAL}
        id="listOfSelectedStrengths"
      />
      {/* call the modal component when the user press on the strengths list */}
      <CloudModal
        id="selectedStrength"
        myStrengthsList={myStrengthsList}
        setMyStrengthsList={setMyStrengthsList}
        modalText={LIST_OF_MY_STRENGTHSLIST_MODAL}
      />
      <ChatWindow
        modalText={CHAT_WINDOW}
        showResults={showResults}
        setShowResults={setShowResults}
        user={user}
        page={user.pagesVisited.resources}
        pageName="resources"
      />
    </div>
  );
};

export default MyResources;
