import React from 'react';
import './InfoModal.css';

const InfoModal = ({ modalText, id, dataBackdrop = true }) => {
  const { title, body, specialBody } = modalText;

  return (
    <div className="modal fade" id={`${id}Modal`} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {specialBody && specialBody.length ? (
              specialBody.map((item, index) => <p key={index}>{item}</p>)
            ) : (
              <p>{body}</p>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Stäng
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
