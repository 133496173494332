import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import './TextArea.css';

const TextArea = ({
  id,
  value,
  onChange,
  onBlur,
  title,
  placeholder,
  disabled,
  pageWidth,
  rows
}) => {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusEndOfTextarea = element => {
    const val = element.target.value;
    element.target.value = '';
    element.target.value = val;
    return scrollToBottom();
  };

  const scrollToBottom = () => {
    const textArea = [...document.querySelectorAll('textarea')];
    if (!textArea) return;
    textArea.map(area => {
      if (!area) return;
      area.scrollTop = area.scrollHeight;
    });
  };

  return (
    <div className="form-group textarea-container">
      {!disabled && (
        <TextareaAutosize
          rows={rows}
          autoFocus
          onFocus={focusEndOfTextarea}
          disabled={disabled}
          className="form-control textarea"
          id={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          title={title}
          placeholder={placeholder}
          rows={rows}
        />
      )}
      {disabled && (
        <TextareaAutosize
          rows={rows}
          disabled={disabled}
          className="form-control textarea disabled"
          value={value}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default TextArea;
