import {
  ADD_CLIENT_EMAIL_SUCCESS,
  ADD_CLIENT_EMAIL_FAIL,
  ADD_CLIENT_EMAIL_FAIL_EMAIL_EXISTS,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL
} from './types';
import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true
};

export const addClient = clientEmail => async dispatch => {
  const result = await fetch(`/api/reko/validClientEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ clientEmail }),
    credentials: 'include'
  });

  const data = await result.json();

  if (result.status !== 201) {
    if (result.status === 409) {
      return dispatch({
        type: ADD_CLIENT_EMAIL_FAIL_EMAIL_EXISTS,
        payload: 'Det finns redan en användare med den emailadressen!'
      });
    }

    return dispatch({
      type: ADD_CLIENT_EMAIL_FAIL,
      payload: data.error
    });
  }

  toast.info('Emailadressen är tillagd!', toastConfig);
  return dispatch({
    type: ADD_CLIENT_EMAIL_SUCCESS
  });
};

export const fetchClients = () => async dispatch => {
  const result = await fetch(`/api/reko/clients`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: FETCH_CLIENTS_FAIL
      });
    }
    return;
  }

  const data = await result.json();

  return dispatch({
    type: FETCH_CLIENTS_SUCCESS,
    payload: data
  });
};

export const deleteClient = email => async dispatch => {
  console.log('value', email)
  const result = await fetch(`/api/reko/client/${email}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (result.status !== 200) {
    if (result.status !== 401) {
      return dispatch({
        type: DELETE_CLIENT_FAIL,
        payload: 'Oops, något gick fel...'
      });
    }
    return;
  }
  toast.info('Klient är raderat!', toastConfig);
  return dispatch({
    type: DELETE_CLIENT_SUCCESS
  });
};
