const updateUserPagesVisited = (user, page, pageName) => {
  if (!user.pagesVisited[pageName]) {
    const pagesVisited = Object.assign({}, user['pagesVisited'], {
      [pageName]: true
    });
    const tempUser = Object.assign({}, user, { pagesVisited });
    return tempUser;
  } else {
    return user;
  }
};

export default updateUserPagesVisited;
