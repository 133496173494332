import { SET_CLOUD_DATA_LIST, SET_CLOUD_MODAL_DATA } from './types';

export const setCloudDataList = cloudData => dispatch => {
  return dispatch({
    type: SET_CLOUD_DATA_LIST,
    payload: cloudData
  });
};

export const setCloudModalData = modalData => dispatch => {
  return dispatch({
    type: SET_CLOUD_MODAL_DATA,
    payload: modalData
  });
};
