import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import './MyStrategies.css';
import { updateUser } from '../../store/actions/userActions';

import infoIcon from '../../assets/information.svg';
import mWorkslogo from '../../assets/homepage/mworks-logo@3x.png';
import Section from '../section/Section';
import InfoModal from '../infoModal/InfoModal';
import InfoBox from '../InfoBox/InfoBox';
import InfoBoxButton from '../InfoBox/InfoBoxButton/InfoBoxButton';
import NewStrategies from './newStrategies/NewStrategies';
import useChatbot from '../../hooks/useChatbot';
import ChatWindow from '../chatWindow/ChatWindow';
import useDevice from '../../constants/devices';
import { MY_STRATEGIES_EVENT } from '../../constants/constants';
import { setLogoutTime } from '../../utils/setLogoutTime';

const MyStrategies = () => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const [showResults, setShowResults] = useState(false);
  const messages = useSelector(state => state.message);
  const [needs, setNeeds] = useState(
    user.strategies ? user.strategies.needs : ''
  );
  const [wants, setWants] = useState(
    user.strategies ? user.strategies.wants : ''
  );
  const [workedBefore, setWorkedBefore] = useState(
    user.strategies?.workedBefore || ''
  );
  const [myNeedsInfoClassName, setMyNeedsInfoClassName] =
    useState('my-needs-info');
  const [workedBeforeInfoClassName, setWorkedBeforeInfoClassName] =
    useState('worked-before-info');
  const [myWantsInfoClassName, setMyWantsInfoClassName] =
    useState('my-wants-info');
  const [myNewStrategiesInfoClassName, setMyNewStrategiesInfoClassName] =
    useState('my-new-strategies-info');

  useEffect(() => {
    if (!user.pagesVisited.strategies) {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      user.chatbotConversations.strategies.length === 0 &&
      messages.strategies.length === 0
    ) {
      chatBot.eventQuery(MY_STRATEGIES_EVENT, user);
    }
  }, []);

  useEffect(() => {
    setNeeds(user.strategies?.needs || '');
    setWants(user.strategies?.wants || '');
    setWorkedBefore(user.strategies?.workedBefore || '');
  }, [user]);

  const handleOnIdle = () => {
    const sessions = setLogoutTime(user, getLastActiveTime());
    const tempUser = Object.assign({}, user, { sessions });
    dispatch(updateUser(tempUser));
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const subSections = ['needs', 'wants', 'workedBefore'];
  const CHAT_WINDOW = {
    event: MY_STRATEGIES_EVENT,
    section: 'strategies',
    subsection: subSections,
    pageTitle: 'Mina strategier'
  };

  /* INFO_TEXT_MODALS */
  const MY_NEEDS_MODAL = {
    title: 'Detta är viktigt för mig på jobbet',
    body: '',
    specialBody: [
      'Vad behöver fungera för dig på jobbet?',
      <>
        Tänk igenom situationer som är nödvändiga för att du ska må bra.{' '}
        <em>
          Är det några särskilda situationer i ”Mitt mående” du vill lyfta här?
        </em>
      </>,
      'Skriv ner några viktiga situationer som du vill ska fungera.',
      <>
        <em>
          Ex. Att inte känna mig stressad vid lunchtid när det är meningen att
          koppla av och få ny kraft.
        </em>
      </>
    ],
    placeholder:
      'Exempel: \nAtt inte känna mig stressad vid lunchtid när det är meningen att koppla av och få ny kraft.'
  };
  const MY_WANTS_MODAL = {
    title: 'Strategier jag vill använda',
    body: '',
    specialBody: [
      'Lista strategier du behöver för att må bra på jobbet, både de du redan använder och nya.',
      <>
        <em>
          1) Ex. Skapa rutiner runt lunchen och prata med någon om att luncha
          tillsammans (egen strategi)
        </em>
      </>,
      <>
        <em>
          2) Ex. Planera in en mikropaus med mindfulness efter lunchen för att
          låta tankar och känslor som väckts passera (nya strategi)
        </em>
      </>,
      <>
        <em>3) ……………..</em>
      </>,
      <>
        <em>4) …………..</em>
      </>
    ],
    placeholder:
      'Exempel: \n-Planera för vila, pauser och mikropauser, spärra tid i kalendern för mikropauser. \n-Hitta en plats på jobbet där jag kan koppla av en liten stund. \n\nDu hittar förslag på strategier i rutan ovanför.'
  };
  const WORKED_BEFORE_MODAL = {
    title: 'Mina strategier idag',
    body: '',
    specialBody: [
      <>
        <em>Vad gör du idag som fungerar?</em> Beskriv situationer, de tankar,
        känslor och handlingar, som är kopplade till när du vänt något svårt
        till något bra.
      </>,
      <>
        <em>
          Ex. När jag blir stressad och får ångest (situation) så brukar jag
          tänka: ”Ta det lugnt”, och känna att det finns tid och utrymme. Ibland
          lägger jag in en påminnelse i mobilen så jag kan avsluta något och
          förbereda mig för nästa grej, så får jag mer koll och varvar inte upp.
        </em>
      </>
    ],
    placeholder:
      'Exempel: \nNär jag blir stressad och får ångest (situation) så brukar jag tänka: ”Ta det lugnt”, och känna att det finns tid och utrymme. Ibland lägger jag in en påminnelse i mobilen så jag kan avsluta något och förbereda mig för nästa grej, så får jag mer koll och varvar inte upp.'
  };
  const MY_NEW_STRATEGIES = {
    title: 'Nya strategier',
    body: 'Inspireras av exempellistan med strategier. Diskutera gärna med någon du känner. Lista de strategier du vill använda i nästa moment.'
  };
  /* INFO_TEXT_MODALS END */

  const updateUserHandler = () => {
    const strategies = {
      needs,
      wants,
      workedBefore
      // newStrategies
    };
    const tempUser = Object.assign({}, user, { strategies });
    dispatch(updateUser(tempUser));
  };

  return (
    <div className="mystrategies">
      <div className="background-container"></div>
      <div className="main-container">
        <div className="left-container">
          <InfoBox
            className={myNeedsInfoClassName}
            modalText={MY_NEEDS_MODAL}
          />
          <div className="left-top-container">
            <Section
              rows={9}
              id="needs"
              value={needs}
              onChange={evt => setNeeds(evt.target.value)}
              onBlur={updateUserHandler}
              title="Detta är viktigt för mig på jobbet"
              updateUserHandler={updateUserHandler}
              placeholder={MY_NEEDS_MODAL.placeholder}
              defaultInfoClassName="my-needs-info"
              setInfoClassName={setMyNeedsInfoClassName}
            />
          </div>
          <InfoBox
            className={workedBeforeInfoClassName}
            modalText={WORKED_BEFORE_MODAL}
          />
          <div className="left-bottom-container">
            <Section
              rows={9}
              id="workedBefore"
              value={workedBefore}
              onChange={evt => setWorkedBefore(evt.target.value)}
              onBlur={updateUserHandler}
              title="Mina strategier idag"
              updateUserHandler={updateUserHandler}
              placeholder={WORKED_BEFORE_MODAL.placeholder}
              defaultInfoClassName="worked-before-info"
              setInfoClassName={setWorkedBeforeInfoClassName}
            />
          </div>
        </div>
        <div className="right-container">
          <InfoBox
            className={myNewStrategiesInfoClassName}
            modalText={MY_NEW_STRATEGIES}
          />
          <div className="right-top-container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label htmlFor="#kaltura_player" className="video-title">
                {MY_NEW_STRATEGIES.title} &nbsp;
                {/* <span className="info-icon-container">
                  <img
                    src={infoIcon}
                    alt="information"
                    className="iconify info-icon "
                    data-inline="false"
                    data-toggle="modal"
                    data-target={`#newStrategiesModal`}
                  />
                </span> */}
              </label>
              <InfoBoxButton
                infoClassName={myNewStrategiesInfoClassName}
                setInfoClassName={setMyNewStrategiesInfoClassName}
                defaultInfoClassName="my-new-strategies-info"
              />
            </div>

            <NewStrategies />
          </div>
          <InfoBox
            className={myWantsInfoClassName}
            modalText={MY_WANTS_MODAL}
          />
          <div className="right-bottom-container">
            <Section
              rows={9}
              id="wants"
              value={wants}
              onChange={evt => setWants(evt.target.value)}
              onBlur={updateUserHandler}
              title="Min lista med strategier"
              updateUserHandler={updateUserHandler}
              placeholder={MY_WANTS_MODAL.placeholder}
              defaultInfoClassName="my-wants-info"
              setInfoClassName={setMyWantsInfoClassName}
            />
          </div>
        </div>
      </div>
      <InfoModal modalText={MY_NEEDS_MODAL} id="needs" />
      <InfoModal modalText={WORKED_BEFORE_MODAL} id="workedBefore" />
      <InfoModal modalText={MY_WANTS_MODAL} id="wants" />
      <InfoModal modalText={MY_NEW_STRATEGIES} id="newStrategies" />
      <ChatWindow
        modalText={CHAT_WINDOW}
        showResults={showResults}
        setShowResults={setShowResults}
        user={user}
        page={user.pagesVisited.strategies}
        pageName="strategies"
      />
    </div>
  );
};

export default MyStrategies;
