import { combineReducers } from 'redux';

import authReducer from './authReducer';
import userReducer from './userReducer';
import mainReducer from './mainReducer';
import adminReducer from './adminReducer';
import rekoReducer from './rekoReducer';
import cloudReducer from './cloudReducer'
import cloudPlacementsReducer from './cloudPlacementsReducer';
import messageReducer from './messageReducer';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  main: mainReducer,
  admin: adminReducer,
  reko: rekoReducer,
  cloud: cloudReducer,
  placements: cloudPlacementsReducer,
  message: messageReducer
});
