import React from 'react';
const ChatButton = ({
    index,
    buttonIndex,
    returnedMessagesLength,
    buttonItem,
    clickHandler
}) => {

    const isButtonDisabled = returnedMessagesLength - 1 !== index;

    const style = {
        color: '#fff',
        background: '#1890ff',
        borderColor: '#1890ff',
        borderRadius: 5,
        marginRight: '5px',
        cursor: isButtonDisabled ? 'not-allowed' : ''
    };

    return (
        <button
            key={buttonIndex}
            className="chat-popup-button"
            disabled={isButtonDisabled}
            style={style}
            value={buttonItem}
            onClick={e => clickHandler(buttonItem.event)}>
            {buttonItem.text}
        </button>
    );
};

export default ChatButton;