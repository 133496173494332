import React from 'react';
import { List } from 'antd';
import ChatButton from './ChatButton';

const ChatButtonList = ({
  index,
  message,
  fields,
  buttons,
  returnedMessagesLength,
  clickHandler,
  showDots,
  latestMessageID
}) => {
  const TypingAnimation = () => {
    return (
      <div className="typing-dot-container">
        <span className="typing-dot"></span>
        <span className="typing-dot"></span>
        <span className="typing-dot"></span>
      </div>
    );
  };

  const chatButtons =
    buttons &&
    buttons.map((buttonItem, buttonIndex) => {
      return (
        <ChatButton
          key={buttonIndex}
          index={index}
          buttonIndex={buttonIndex}
          returnedMessagesLength={returnedMessagesLength}
          buttonItem={buttonItem}
          clickHandler={clickHandler}
        ></ChatButton>
      );
    });

  const description = (
    <div id={index}>
      <p className="chat-popup-client-text" id={latestMessageID}>
        {fields.title.stringValue}
      </p>
      {chatButtons}
    </div>
  );

  return (
    <div key={index}>
      {showDots &&
        returnedMessagesLength > 0 &&
        index === returnedMessagesLength - 1 &&
        message.who === 'Mott' && <TypingAnimation />}
      {!showDots && index === returnedMessagesLength - 1 && (
        <List.Item style={{ padding: '1rem' }}>
          <List.Item.Meta description={description} key={index} />
        </List.Item>
      )}
      {returnedMessagesLength > 1 && index !== returnedMessagesLength - 1 && (
        <List.Item style={{ padding: '1rem' }}>
          <List.Item.Meta description={description} key={index} />
        </List.Item>
      )}
    </div>
  );
};

export default ChatButtonList;
