import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import './MyWayBackSection.scss';
import {
  setEditingActive,
  setEditingNotActive
} from '../../../store/actions/mainActions';
import LabelInfo from '../../labelInfo/LabelInfo';
import penIcon from '../../../assets/edit.svg';
import Slider, { Handle } from 'rc-slider';
import { debounce } from 'lodash';
import CustomEditableList from './CustomEditableList';
import { updateUser } from '../../../store/actions/userActions';
import { wayBackDataInitial } from './initialdata';

const MyWayBackSection = ({
  title,
  id,
  placeholder,
  noIcon,
  defaultInfoClassName,
  setInfoClassName
}) => {
  const dispatch = useDispatch();
  const { sectionId, isEditing } = useSelector(state => state.main);
  const user = useSelector(state => state.user.user);
  const [viewData, setViewData] = useState(
    user.myMworks?.wayBack || wayBackDataInitial
  );
  const isEditingThisBox = sectionId === 'myWayBack' && isEditing;

  const activePenIcon = id => {
    setInfoClassName(defaultInfoClassName + ' opened');
    dispatch(setEditingActive(id));
  };

  const updateDbByKey = (...args) => {
    const [listKey, data] = args;
    const newWayBackData = Object.assign({}, viewData, { [listKey]: data });

    const tempUser = Object.assign({}, user);
    tempUser.myMworks.wayBack = newWayBackData;

    dispatch(updateUser(tempUser));
    setViewData(newWayBackData);
  };

  const debouncedDispatch = debounce((...args) => updateDbByKey(...args), 1000);

  const CustomSlider = () => {
    const [sliderValue, setSliderValue] = useState(viewData.getBackLevel);

    const handleSlideChange = value => {
      setSliderValue(value);
      debouncedDispatch('getBackLevel', value);
    };

    return (
      <>
        <Slider
          step={1}
          marks={{ 0: { label: 0 }, 100: { label: 100 } }}
          onChange={handleSlideChange}
          value={sliderValue}
          disabled={isEditingThisBox ? false : true}
          handle={handleProps => {
            return (
              <Handle {...handleProps} dragging={'true'}>
                <div>{handleProps.value}</div>
              </Handle>
            );
          }}
        />
      </>
    );
  };

  const CustomTextarea = () => {
    const [text, setText] = useState(viewData.text);

    return (
      <TextareaAutosize
        rows={12}
        disabled={!isEditingThisBox}
        className="form-control textarea"
        value={text}
        onChange={e => setText(e.target.value)}
        onBlur={e => updateDbByKey('text', e.target.value)}
        title={title}
        placeholder={placeholder}
      />
    );
  };

  return (
    <div className="form-group textarea-container">
      <div className="textarea-container-group">
        <LabelInfo id={id} title={title} noIcon="true" />
        <div className="btn-container">
          {!isEditing && (
            <button
              className="btn top-margin"
              onClick={() => {
                activePenIcon(id);
              }}
            >
              <span className="pen-icon-container">
                <img
                  src={penIcon}
                  alt="edit pen"
                  className="iconify"
                  data-inline="false"
                />
              </span>
            </button>
          )}

          {isEditingThisBox && id === sectionId && (
            <button
              type="button"
              className="btn btn-dark top-margin"
              onClick={() => {
                setInfoClassName(defaultInfoClassName);
                dispatch(setEditingNotActive());
              }}
            >
              Klar
            </button>
          )}
        </div>
      </div>
      <div className="my-way-back-wrapper">
        <div className="draggable-slider">
          <div className="heading">
            Hur gärna vill jag tillbaka till jobbet?
          </div>
          <CustomSlider />
        </div>
        <div className="pros-and-cons">
          <div className="pros">
            <div className="pros-list">
              <CustomEditableList
                listKey="pros"
                listHeading={'Fördelar'}
                addBtnText={'+ Lägg till fördel'}
                viewData={viewData}
                isEditingThisBox={isEditingThisBox}
                updateDbByKey={updateDbByKey}
              />
            </div>
          </div>
          <div className="cons">
            <div className="cons-list">
              <CustomEditableList
                listKey="cons"
                listHeading={'Nackdelar'}
                addBtnText={'+ Lägg till nackdel'}
                viewData={viewData}
                isEditingThisBox={isEditingThisBox}
                updateDbByKey={updateDbByKey}
              />
            </div>
          </div>
        </div>
        <div className="heading custom-text-area">
          Vad får mig att vilja gå tillbaka till jobbet?
        </div>
        <CustomTextarea />
      </div>
    </div>
  );
};

export default MyWayBackSection;
