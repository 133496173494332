import React, { useState } from 'react';
import Colors from './Colors';

const ColorPicker = ({ setColor, currentColor }) => {
  const [activeIndex, setActiveIndex] = useState();

  const checkActiveColor = (index, color) => {
    setActiveIndex(index);
    setColor(color);
  };

  return (
    <div className="col-lg-12 col-sm-12 color-picker-container">
      <label htmlFor=""></label>
      <div className="color-container">
        {Colors.ColorMap.map((color, index) => {
          return (
            <div
              id={color.name}
              value={color.name}
              key={index}
              className={`color-circle ${color.name} ${activeIndex === index || currentColor === color.code
                ? 'active'
                : ''
                }`}
              onClick={() => checkActiveColor(index, color.code)}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default ColorPicker;
