import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { logout } from '../../store/actions/authActions';
import { resetPassword, updateUser } from '../../store/actions/userActions';

import './HomePage.css';
import HomePageMobile from './homePageMobile/HomePageMobile';
import Card from './cardComponent/Card';
import BGImage from '../../assets/homepage/mountain-2.svg';
import mWorksCloud from '../../assets/homepage/cloud.svg';
import mWorkslogo from '../../assets/homepage/mworks-logo@3x.png';
import resourcesLogo from '../../assets/myResources/card-symbol-resurser.svg';
import networkLogo from '../../assets/myNetwork/card-symbol-n-tverk.svg';
import wellbeingLogo from '../../assets/myWellbeing/card-symbol-m-ende.svg';
import strategiesLogo from '../../assets/navbarLarge/strategies/strategier.svg';
import planningLogo from '../../assets/myPlanning/card-symbol-planering.svg';
import starLogo from '../../assets/homepage/star.svg';
import bgLine from '../../assets/homepage/homepage-bg-line.svg';
import useDevice from '../../constants/devices';
import useChatbot from '../../hooks/useChatbot';
import ChatWindow from '../chatWindow/ChatWindow';
import DisclaimerModal from '../disclaimer/modal/DisclaimerModal';
import { HOME_EVENT } from '../../constants/constants';

import { setLogoutTime } from '../../utils/setLogoutTime';

const logos = {
  mWorksCloud,
  resourcesLogo,
  networkLogo,
  wellbeingLogo,
  strategiesLogo,
  planningLogo,
  starLogo
};

/* INFO TEXT IN THE BOXES */
const Cloud_INFO = 'Ett ställe där jag kan skriva och berätta om mig själv';
const MWORKS_INFO = `Mitt mWorks är en plats för dig. Här kan du stanna upp och reflektera över vad som hänt, vad som är viktigt och vad som inspirerar och motiverar dig. `;
const RESOURCES_INFO =
  'Hjälper dig att förstå dina styrkor och använda dom i din arbetsprofil';
const NETWORK_INFO =
  'Hjälper dig att samla dina kontakter som stöttar dig på ett och samma ställe';
const WELLBEING_INFO =
  'Hjälper dig att förstå hur du mår, och hur du kan berätta det för andra';
const STRATEGIES_INFO =
  'Hjälper dig att identifiera vad som är viktigt för dig på jobbet för att du ska må bra';
const PLANNING_INFO =
  'Tar dina mål och ”att göra”-lista till genomförbara mål och handlingar som du väljer ';
const BACK_TO_WORK_INFO =
  'Nu är du tillbaka på jobbet och kan ta hjälp av de fem stegen som du har fyllt i';
/* INFO TEXT IN THE BOXES END */

/* MODAL TEXT */
/* MODAL TEXT END */

const INFO_TEXTS = {
  MWORKS_INFO,
  RESOURCES_INFO,
  NETWORK_INFO,
  WELLBEING_INFO,
  STRATEGIES_INFO,
  PLANNING_INFO,
  BACK_TO_WORK_INFO
};

const HomePage = () => {
  const dispatch = useDispatch();
  const chatBot = useChatbot();
  const device = useDevice();
  const user = useSelector(state => state.user.user);
  const messages = useSelector(state => state.message);
  const [showResults, setShowResults] = useState(false);
  const [hideDisclaimer, setHideDisclaimer] = useState(true);

  const handleLogout = () => {
    return dispatch(logout());
  };

  const handleResetPassword = () => {
    // Here to call send email function
    return dispatch(resetPassword(user));
  };

  useEffect(() => {
    if (!user?.hasLoggedInBefore) {
      setHideDisclaimer(false);
    }
  }, []);

  useEffect(() => {
    if (!user.pagesVisited.home) {
      setShowResults(true);
    }
  }, []);

  useEffect(() => {
    if (
      device !== 'small' &&
      user &&
      user.chatbotConversations?.home.length === 0 &&
      messages.home.length === 0
    ) {
      chatBot.eventQuery(HOME_EVENT, user);
    }
  }, []);

  const handleOnIdle = () => {
    const sessions = setLogoutTime(user, getLastActiveTime());
    const tempUser = Object.assign({}, user, { sessions });
    dispatch(updateUser(tempUser));
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const CHAT_WINDOW = {
    event: HOME_EVENT,
    section: 'home',
    subsection: ''
  };

  if (device === 'small') {
    return <HomePageMobile logos={logos} INFO_TEXTS={INFO_TEXTS} user={user} />;
  } else {
    return (
      <div className="homepage">
        <div className="background-container">
          <img src={BGImage} alt="mWork" />
        </div>
        <div className="main-container">
          <div className="top-section">
            <div className="left-section">
              <button
                className="about-mworks"
                onClick={() => setHideDisclaimer(false)}
              >
                <img
                  src={mWorkslogo}
                  className="mworks_logo"
                  alt="mworks logo"
                />
                Om mWorks
              </button>
            </div>
            <div className="center-section">
              <Link to="/mymworks">
                <img src={mWorksCloud} className="logo" alt="cloud logo" />
                <div className="cloud-info">
                  <div className="cloud-title">Mitt mWorks</div>
                  <div className="cloud-text">{Cloud_INFO}</div>
                </div>
              </Link>
            </div>
            <div className="right-section">
              <div className="navbar">
                <div>
                  {' '}
                  <p className="logout-link" onClick={handleResetPassword}>
                    Återställ lösenord
                  </p>
                  <p className="logout-link" onClick={handleLogout}>
                    Logga ut
                  </p>
                </div>
                {/* THIS IS FOR DEVELOPMENT... */}
                {user.role === 'admin' && (
                  <Link
                    to="/admin"
                    style={{
                      color: 'red',
                      paddingLeft: '15px'
                    }}
                  >
                    Admin
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="middle-section">
            <div
              className="content-container"
              style={{ backgroundImage: `url(${bgLine})` }}
            >
              <div className="bg-resources cards">
                <Card
                  to="/myresources"
                  number="1"
                  title="Mina resurser"
                  info={RESOURCES_INFO}
                  logo={resourcesLogo}
                  circleClass="circle-bg-resources"
                  showToggleButton={true}
                />
              </div>

              <div className="bg-network cards">
                <Card
                  to="/mynetwork"
                  number="2"
                  title="Mitt nätverk"
                  info={NETWORK_INFO}
                  logo={networkLogo}
                  circleClass="circle-bg-network"
                  showToggleButton={true}
                />
              </div>

              <div className="bg-wellbeing cards">
                <Card
                  to="/mywellbeing"
                  number="3"
                  title="Mitt mående"
                  info={WELLBEING_INFO}
                  logo={wellbeingLogo}
                  circleClass="circle-bg-wellbeing"
                  showToggleButton={true}
                />
              </div>

              <div className="bg-strategies cards">
                <Card
                  to="/mystrategies"
                  number="4"
                  title="Mina strategier"
                  info={STRATEGIES_INFO}
                  logo={strategiesLogo}
                  circleClass="circle-bg-strategies"
                  showToggleButton={true}
                />
              </div>

              <div className="bg-planning cards">
                <Card
                  to="/myplanning"
                  number="5"
                  title="Min planering"
                  info={PLANNING_INFO}
                  logo={planningLogo}
                  circleClass="circle-bg-planning"
                  showToggleButton={true}
                />
              </div>
            </div>
          </div>
        </div>

        {!hideDisclaimer && (
          <DisclaimerModal setHideDisclaimer={setHideDisclaimer} />
        )}
        <ChatWindow
          modalText={CHAT_WINDOW}
          showResults={showResults}
          setShowResults={setShowResults}
          user={user}
          page={user.pagesVisited.home}
          pageName="home"
        />
      </div>
    );
  }
};

export default HomePage;
