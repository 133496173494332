import {
  SET_BACKGROUND_INDEX,
  SET_EDITING_ACTIVE,
  SET_EDITING_NOT_ACTIVE
} from './types';

export const setBackgroundIndex = index => async dispatch => {
  return dispatch({
    type: SET_BACKGROUND_INDEX,
    payload: index
  });
};

export const setEditingActive = sectionId => dispatch => {
  return dispatch({
    type: SET_EDITING_ACTIVE,
    payload: sectionId
  });
};

export const setEditingNotActive = () => dispatch => {
  return dispatch({
    type: SET_EDITING_NOT_ACTIVE
  });
};
