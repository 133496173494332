import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  IS_LOGGED_IN,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_FAIL_USER_EXISTS,
  TOKEN_VERIFICATION_SUCCESS,
  TOKEN_VERIFICATION_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL
} from './types';
import { toast } from 'react-toastify';

const toastConfig = {
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true
};

export const register = userObject => async dispatch => {
  const result = await fetch(`/api/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userObject),
    credentials: 'include'
  });

  const data = await result.json();

  if (result.status !== 201) {
    if (result.status === 409) {
      return dispatch({
        type: REGISTER_FAIL_USER_EXISTS,
        payload: 'Det finns redan en användare med den emailen!'
      });
    }

    return dispatch({
      type: REGISTER_FAIL,
      payload: data.error
    });
  }

  return dispatch({
    type: REGISTER_SUCCESS,
    payload: data.user
  });
};

export const checkIfLoggedIn = () => async dispatch => {
  const result = await fetch(`/api/auth/checkLogin`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  const data = await result.json();

  if (result.status !== 200) {
    return dispatch({
      type: LOGOUT_SUCCESS
    });
  }

  return dispatch({
    type: IS_LOGGED_IN,
    payload: data.user
  });
};

export const login = (email, password) => async dispatch => {
  const result = await fetch(`/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password
    })
  });

  const data = await result.json();

  if (result.status !== 200) {
    return dispatch({
      type: LOGIN_FAIL,
      payload: data.error
    });
  }

  return dispatch({
    type: LOGIN_SUCCESS,
    payload: data.user
  });
};

export const logout = () => async dispatch => {
  const result = await fetch(`/api/auth/logout`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  });

  const data = await result.json();

  if (result.status !== 200) {
    return dispatch({
      type: LOGOUT_FAIL,
      payload: data.error
    });
  }

  toast.info('Du har loggats ut!', toastConfig);
  return dispatch({
    type: LOGOUT_SUCCESS
  });
};

export const checkIfTokenIsValid = token => async dispatch => {
  const result = await fetch(`/api/auth/verifyToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token
    })
  });

  const data = await result.json();

  if (result.status !== 200) {
    return dispatch({
      type: TOKEN_VERIFICATION_FAIL,
      payload: data.error
    });
  }

  return dispatch({
    type: TOKEN_VERIFICATION_SUCCESS,
    payload: data.user
  });
};

export const updateUserPassword = (userId, password) => async dispatch => {
  const userData = { userId, password };
  const result = await fetch(`/api/auth/client/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true
    },
    credentials: 'include',
    body: JSON.stringify(userData)
  });

  if (result.status !== 200) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: 'Oops, något gick fel i lösenordsuppdateringen...'
    });
  } else {
    const data = await result.json();
    toast.info('Lösenordet för detta klient har förnyats!', {
      position: 'top-center'
    });
    return dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.user
    });
  }
};
