import React, { useState } from 'react';

import './ColorPicker.css';

const ColorPicker = ({ setColor, currentColor }) => {
  const [activeIndex, setActiveIndex] = useState();
  const checkActiveColor = (index, color) => {
    setActiveIndex(index);
    setColor(color);
  };
  const colorsName = [
    { name: 'color-1', code: 'color-1' },
    { name: 'color-2', code: 'color-2' },
    { name: 'color-3', code: 'color-3' },
    { name: 'color-4', code: 'color-4' },
    { name: 'color-5', code: 'color-5' },
  ];
  return (
    <div className="col-lg-12 col-sm-12 color-picker-container">
      <label htmlFor=""></label>
      <div className="color-container">
        {colorsName.map((color, index) => {
          return (
            <div
              id={color.name}
              value={color.name}
              key={index}
              className={`color-circle bg-${color.name} ${
                activeIndex === index || currentColor === color.code
                  ? 'active'
                  : ''
                }`}
              onClick={() => checkActiveColor(index, color.code)}
            ></div>
          );
        })}
      </div>
    </div>
  );
};
export default ColorPicker;
