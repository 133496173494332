import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './views/App';
import { Provider } from 'react-redux';
import store from './store';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { stopZoomOnMobile } from './utils/stopZoomOnMobile'
import 'react-toastify/dist/ReactToastify.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import '@fullcalendar/list/main.css';

require('dotenv').config();

toast.configure({
  position: 'top-center',
  autoClose: 8000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: 'slide'
});

stopZoomOnMobile()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer transition={Slide} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
