import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchClients,
  deleteClient
} from '../../../store/actions/adminActions';
import './ManageClients.css';
import mWorksLogo from '../../../assets/navbarLarge/mWorks/slice@3x.png';

const ManageClients = () => {
  const dispatch = useDispatch();
  const clients = useSelector(state => state.admin.clients);

  const initData = async () => {
    await dispatch(fetchClients());
  };
  useEffect(() => {
    initData();
  }, []);

  const handleDelete = async (evt, clientId) => {
    evt.preventDefault();

    await dispatch(deleteClient(clientId));
    return dispatch(fetchClients());
  };

  return (
    <>
      <div className="clients-middle-section">
        <div className="clients-content-container">
          <div className="circle-container">
            <div className="logo-container">
              <div>Mitt mWorks</div>
              <img src={mWorksLogo} className="logo" alt="" />
            </div>
            <br />
            <br />
          </div>
          <div>
            <h1>Hantera Kunder</h1>
          </div>
          <br />
        </div>
      </div>
      <div className="clients-bottom-section">
        <div className="data-container">
          <div className="clients-center-container">
            <div>
              <div>
                <div className="clients-heading-text">Klient</div>
                <div className="clients-heading-text">E-post</div>
                <div className="clients-heading-text clients-action-heading">
                  Insatser
                </div>
              </div>
              {clients &&
                clients.map(client => (
                  <div key={client._id}>
                    <div className="clients-column-text">
                      {client.firstName + ' ' + client.lastName}
                    </div>
                    <div className="clients-column-text">{client.email}</div>
                    <div className="clients-column-text clients-action-text">
                      <strong>
                        <p
                          className="clients-actions-text"
                          onClick={evt =>
                            window.confirm(
                              'Är du säker på att du vill ta bort den här Klient?'
                            ) && handleDelete(evt, client._id)
                          }
                        >
                          Radera
                        </p>
                      </strong>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClients;
