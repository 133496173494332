import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-autosize-textarea';
import { updateUser } from '../../../store/actions/userActions';
import {
  setEditingActive,
  setEditingNotActive
} from '../../../store/actions/mainActions';
import LabelInfo from '../../labelInfo/LabelInfo';
import penIcon from '../../../assets/edit.svg';
import { tellOthersInitialFields } from './initialfields';
import './TellothersSection.scss';

const TellothersSection = ({
  title,
  id,
  noIcon,
  pageWidth,
  defaultInfoClassName,
  setInfoClassName
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const { sectionId, isEditing } = useSelector(state => state.main);
  const viewData = initOrLoadTextFields();
  const isEditingThisBox = sectionId === 'tellOthers' && isEditing;

  function initOrLoadTextFields() {
    if (
      Array.isArray(user.wellBeing?.tellOthers) &&
      user.wellBeing?.tellOthers.length === 4
    ) {
      return user.wellBeing.tellOthers;
    }
    return tellOthersInitialFields;
  }

  const activePenIcon = id => {
    dispatch(setEditingActive(id));
    setInfoClassName(defaultInfoClassName + ' opened');
  };

  const saveFieldData = (newTextValue, field) => {
    const { id, text } = field;
    if (newTextValue === text) return;

    const newFieldsData = viewData.map(field => {
      if (field.id === id) {
        field.text = newTextValue.trim();
      }
      return field;
    });

    const tmpUser = Object.assign({}, user);
    tmpUser.wellBeing.tellOthers = newFieldsData;
    dispatch(updateUser(tmpUser));
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditingThisBox]);

  return (
    <div className="form-group textarea-container">
      <div className="textarea-container-group">
        <div className="tellothers-header">
          <LabelInfo id={id} title={title} noIcon="true" />
          <div className="btn-container">
            {!isEditing && (
              <button
                className="btn top-margin tellothers-btn"
                onClick={() => {
                  activePenIcon(id);
                }}
              >
                <span className="pen-icon-container">
                  <img
                    src={penIcon}
                    alt="edit pen"
                    className="iconify"
                    data-inline="false"
                  />
                </span>
              </button>
            )}

            {isEditing && id === sectionId && (
              <button
                type="button"
                className="btn btn-dark top-margin"
                onClick={() => {
                  setInfoClassName(defaultInfoClassName);
                  dispatch(setEditingNotActive());
                }}
              >
                Klar
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="tellothers-wrapper">
        {viewData.map((field, i) => {
          const { placeholder, title, text, focus } = field;
          return (
            <div className="tellothers-row" key={i}>
              <div className="heading">{title}</div>
              <TextareaAutosize
                className="textarea"
                rows={pageWidth > 992 ? 7 : 5}
                defaultValue={text}
                placeholder={placeholder}
                onBlur={e => saveFieldData(e.target.value, field)}
                disabled={isEditingThisBox ? false : true}
                ref={focus ? inputRef : null}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TellothersSection;
