import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import './NavbarLarge.css';
import { resetPassword } from '../../../store/actions/userActions';

import mWorksLogo from '../../../assets/cardSymbols/mworks.svg';
import resourcesLogo from '../../../assets/cardSymbols/resources.svg';
import networkLogo from '../../../assets/cardSymbols/network.svg';
import wellbeingLogo from '../../../assets/cardSymbols/wellbeing.svg';
import strategiesLogo from '../../../assets/cardSymbols/strategies.svg';
import planningLogo from '../../../assets/cardSymbols/planning.svg';
import topnavBgLine from '../../../assets/navbarLarge/topnav-bg-line.svg';
import {
  ROUTE_HOME,
  ROUTE_MY_MWORKS,
  ROUTE_MY_RESOURCES,
  ROUTE_MY_STRATEGIES,
  ROUTE_MY_PLANNING,
  ROUTE_MY_WELLBEING,
  ROUTE_MY_NETWORK
} from '../../../constants/constants';

const NavbarLarge = ({ handleLogout }) => {
  const dispatch = useDispatch();
  const role = useSelector(state => state.user.userRole);
  const user = useSelector(state => state.user.user);

  const handleResetPassword = () => {
    return dispatch(resetPassword(user));
  };

  return (
    <div className="navbar-large">
      <div className="left-container">
        <Link to={ROUTE_HOME} className="link-homepage">
          Till startsidan
        </Link>
      </div>
      <div className="middle-container">
        <div
          className="links-container"
          style={{ backgroundImage: `url(${topnavBgLine})` }}
        >
          <NavLink
            to={ROUTE_MY_MWORKS}
            className="link-mworks link-blah"
            activeClassName="active-mworks active-nav-link"
          >
            <div>
              <p className="link-title standalone-link-title">Mitt mWorks</p>
              <img src={mWorksLogo} className="logo standalone-logo" alt="" />
            </div>
          </NavLink>
          <NavLink
            to={ROUTE_MY_RESOURCES}
            className="link link-resources link-blah"
            activeClassName="active-myresources active-nav-link"
          >
            <div className="link-content-container">
              <div className="link-title">Mina resurser</div>
              <img src={resourcesLogo} className="logo" alt="" />
            </div>
          </NavLink>
          <NavLink
            to={ROUTE_MY_NETWORK}
            className="link link-network link-blah"
            activeClassName="active-mynetwork active-nav-link"
          >
            <div className="link-content-container">
              <div className="link-title">Mitt nätverk</div>
              <img src={networkLogo} className="logo" alt="" />
            </div>
          </NavLink>
          <NavLink
            to={ROUTE_MY_WELLBEING}
            className="link link-wellbeing link-blah"
            activeClassName="active-wellbeing active-nav-link"
          >
            <div className="link-content-container">
              <div className="link-title">Mitt mående</div>
              <img src={wellbeingLogo} className="logo" alt="" />
            </div>
          </NavLink>
          <NavLink
            to={ROUTE_MY_STRATEGIES}
            className="link link-strategies link-blah"
            activeClassName="active-strategies active-nav-link"
          >
            <div className="link-content-container">
              <div className="link-title">Mina strategier</div>
              <img src={strategiesLogo} className="logo" alt="" />
            </div>
          </NavLink>
          <NavLink
            to={ROUTE_MY_PLANNING}
            className="link link-planning link-blah"
            activeClassName="active-planning active-nav-link"
          >
            <div className="link-content-container">
              <div className="link-title">Min planering</div>
              <img src={planningLogo} className="logo" alt="" />
            </div>
          </NavLink>
        </div>
      </div>
      <div className="right-container">
        {/* THIS IS FOR DEVELOPMENT... */}
        {role === 'admin' && (
          <Link
            to="/admin"
            style={{
              textDecoration: 'none',
              color: 'red',
              marginRight: '20px'
            }}
          >
            Admin
          </Link>
        )}
        <p className="logout-link" onClick={handleResetPassword}>
          Återställ lösenord
        </p>
        <p className="logout-link" onClick={handleLogout}>
          Logga ut
        </p>
      </div>
    </div>
  );
};

export default NavbarLarge;
